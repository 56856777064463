// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useState } from "react";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Category({ homedata }) {
  const get_home_all_pro = homedata;

  const recentrecentProduct = get_home_all_pro?.categorylist
    ? get_home_all_pro?.categorylist
    : [];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.categorylist?.length == 1
        ? 1
        : get_home_all_pro?.categorylist?.length > 8
          ? 8
          : get_home_all_pro?.categorylist?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.categorylist?.length == 1
              ? 1
              : get_home_all_pro?.categorylist?.length > 4
                ? 4
                : get_home_all_pro?.categorylist?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.categorylist?.length == 1
              ? 1
              : get_home_all_pro?.categorylist?.length > 3
                ? 3
                : get_home_all_pro?.categorylist?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.categorylist?.length == 1
              ? 1
              : get_home_all_pro?.categorylist?.length > 3
                ? 3
                : get_home_all_pro?.categorylist?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div>
      <div className="categry lostfound  mb-5">
        <div className="ps-container ps-deal-of-day">
          <div className="ps-section__header">
            <div className="ps-block--countdown-deal">
              <div className="ps-block__left">
                <h3>
                  <b>CATEGORY</b>{" "}
                </h3>
              </div>
            </div>
            <Link to="/category">View all</Link>
          </div>
          <div className="ps-section__content catetext">
            {recentrecentProduct?.length > 0 && (
              <Slider {...settings}>
                {recentrecentProduct &&
                  recentrecentProduct?.map((data, i) => {
                    return (
                      <div className="recentlostfound" key={data._id}>
                        <div className="ps-product">
                          <div className="ps-product__thumbnail categorycom">
                            <Link to={"/category-products/" + data?.slug}>
                              {/* <img
                                src={URL.API_BASE_URL + data?.image}
                                className="rounded-circle"
                                alt={data?.name}
                                  loading="lazy"
                              /> */}
                                <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt={"slider" + i}
                            className={`rounded-circle blur-image ${isLoaded ? "hide" : ""}`}
                            style={{
                              filter: "blur(20px)",
                              transition: "opacity 0.3s ease-in-out",
                              opacity: isLoaded ? 0 : 1,
                            }}
                          />
                          <LazyLoadImage
                            alt={"slider" + i}
                            // width={imageWidth}
                            // height={imageHeight}
                            className="rounded-circle"
                            delayTime={300}
                            onLoad={() => setIsLoaded(true)}
                            style={{
                              // Hide until loaded
                              transition: "opacity 0.3s ease-in-out",
                              opacity: isLoaded ? 1 : 0,
                            }}
                            src={URL?.API_BASE_URL + data?.image} // use normal <img> attributes as props
                          />
                            </Link>
                          </div>
                          <div className="ps-product__container">
                            <div className="ps-product__content">
                              <Link
                                className="ps-product__title"
                                to={"/category-products/" + data?.slug}
                              >
                                {data?.name}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
