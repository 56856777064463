// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";

function Checkout() {
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const loading_checkOut = useSelector((state) =>
    state?.allapi?.loading_checkOut ? state?.allapi?.loading_checkOut : false
  );
  console.log(loading_checkOut);
  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(get_all_cart_itme);
  console.log(get_user_profile);
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalshipping = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.delivery_charges;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);
  // createOrder
  const dispatch = useDispatch();

  const router = useNavigate();

  const orderSumit = (e) => {
    // createOrder

    // if (PaymentMethod == "online") {
    //   displayRazorpay();
    // } else {

    const orderAddress = localStorage.getItem("order_Address");

    console.log(orderAddress);
    const obj = JSON.parse(orderAddress);
    console.log(obj);

    dispatch(
      allapiAction.createOrder(
        {
          user_name: get_user_profile?.fullName,
          user_email: get_user_profile?.email,
          user_mobile: get_user_profile?.phone,
          delivery_charges: "0",
          sub_total: totalSubtotalPrice,
          address_id: obj,
          payment_mode_name: "wallet",
          // products_ids: commentsData,
        },
        router
      )
    );
    return () => {};
    // }
  };
  // const shippingCharge = totalSubtotalPrice > 10000 ? 699 : 199;
  const shippingCharge = totalSubtotalshipping;

  const totalAmount = totalSubtotalPrice + shippingCharge;
  // const totalAmount =
  //   totalSubtotalPrice + shippingCharge + totalSubtotalPricegst;

  useEffect(() => {
    // getprofile
    dispatch(allapiAction.getprofile({}));
  }, []);

  return (
    <div>
      <div className="ps-checkout ps-section--shopping">
        <section class="bg-light my-5">
          <div class="container">
            <div class="row">
              {/* <!-- cart --> */}
              <div class="col-lg-9">
                <div class="card border shadow-0">
                  <div class="m-4">
                    <h4 class="card-title mb-4">Your shopping cart</h4>

                    {get_all_cart_itme &&
                      get_all_cart_itme?.map((data, i) => {
                        console.log(data);
                        return (
                          <div class="row gy-3 mb-4">
                            <div class="col-lg-8">
                              <div class="me-lg-5">
                                <div class="d-flex">
                                  <img
                                    src={URL?.API_BASE_URL + data?.featureImage}
                                    class="border rounded me-3"
                                    style={{ width: "96px", height: "96px" }}
                                  />
                                  <div class="">
                                    <a href="#" class="nav-link">
                                      {data?.title}
                                    </a>
                                    <p class="text-muted">
                                      {" "}
                                      {data?.size},{" "}
                                      {data?.productcolorId?.color}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                              <div class="">
                                {/* <select
                            style={{ width: "100px" }}
                            class="form-select me-4"
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select> */}
                              </div>
                              <div class="">
                                <text class="h6">${data?.subtotalprice}</text>{" "}
                                <br />
                                <small class="text-muted text-nowrap">
                                  {" "}
                                  ${data?.afterdiscountprice}/ per item{" "}
                                </small>
                              </div>
                            </div>
                            {/* <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                        <div class="float-md-end">
                          <a
                            href="#!"
                            class="btn btn-light border px-2 icon-hover-primary"
                          >
                            <i class="fas fa-heart fa-lg px-1 text-secondary"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-light border text-danger icon-hover-danger"
                          >
                            {" "}
                            Remove
                          </a>
                        </div>
                      </div> */}
                          </div>
                        );
                      })}
                    {/* {
                      get_all_cart_itme && get_all_cart_itme?.map((data) => {
                       return(
                        <div class="row gy-3 mb-4">
                      <div class="col-lg-5">
                        <div class="me-lg-5">
                          <div class="d-flex">
                            <img
                              src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/11.webp"
                              class="border rounded me-3"
                              style={{ width: "96px", height: "96px" }}
                            />
                            <div class="">
                              <a href="#" class="nav-link">
                                Winter jacket for men and lady
                              </a>
                              <p class="text-muted">Yellow, Jeans</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                        <div class="">
                          <select
                            style={{ width: "100px" }}
                            class="form-select me-4"
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                        </div>
                        <div class="">
                          <text class="h6">$1156.00</text> <br />
                          <small class="text-muted text-nowrap">
                            {" "}
                            $460.00 / per item{" "}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                        <div class="float-md-end">
                          <a
                            href="#!"
                            class="btn btn-light border px-2 icon-hover-primary"
                          >
                            <i class="fas fa-heart fa-lg px-1 text-secondary"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-light border text-danger icon-hover-danger"
                          >
                            {" "}
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                       ) 
                      })
                    } */}

                    {/* <div class="row gy-3 mb-4">
                      <div class="col-lg-5">
                        <div class="me-lg-5">
                          <div class="d-flex">
                            <img
                              src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/12.webp"
                              class="border rounded me-3"
                              style={{ width: "96px", height: "96px" }}
                            />
                            <div class="">
                              <a href="#" class="nav-link">
                                Mens T-shirt Cotton Base
                              </a>
                              <p class="text-muted">Blue, Medium</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                        <div class="">
                          <select
                            style={{ width: "100px" }}
                            class="form-select me-4"
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </select>
                        </div>
                        <div class="">
                          <text class="h6">$44.80</text> <br />
                          <small class="text-muted text-nowrap">
                            {" "}
                            $12.20 / per item{" "}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                        <div class="float-md-end">
                          <a
                            href="#!"
                            class="btn btn-light border px-2 icon-hover-primary"
                          >
                            <i class="fas fa-heart fa-lg px-1 text-secondary"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-light border text-danger icon-hover-danger"
                          >
                            {" "}
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="row gy-3">
                      <div class="col-lg-5">
                        <div class="me-lg-5">
                          <div class="d-flex">
                            <img
                              src="https://bootstrap-ecommerce.com/bootstrap5-ecommerce/images/items/13.webp"
                              class="border rounded me-3"
                              style={{ width: "96px", height: "96px" }}
                            />
                            <div class="">
                              <a href="#" class="nav-link">
                                Blazer Suit Dress Jacket for Men
                              </a>
                              <p class="text-muted">XL size, Jeans, Blue</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
                        <div class="">
                          
                        </div>
                        <div class="">
                          <text class="h6">$1156.00</text> <br />
                          <small class="text-muted text-nowrap">
                            {" "}
                            $460.00 / per item{" "}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
                        <div class="float-md-end">
                          <a
                            href="#!"
                            class="btn btn-light border px-2 icon-hover-primary"
                          >
                            <i class="fas fa-heart fa-lg px-1 text-secondary"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-light border text-danger icon-hover-danger"
                          >
                            {" "}
                            Remove
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div class="border-top pt-4 mx-4 mb-4">
                    {/* <p>
                      <i class="fas fa-truck text-muted fa-lg"></i> Free
                      Delivery within 1-2 weeks
                    </p>
                    <p class="text-muted">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip
                    </p> */}
                  </div>
                </div>
              </div>
              {/* <!-- cart -->
      <!-- summary --> */}
              <div class="col-lg-3">
                <div class="card mb-3 border shadow-0">
                  <div class="card-body">
                    <form>
                      <div class="form-group">
                        <label class="form-label">Have coupon?</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control border"
                            name=""
                            placeholder="Coupon code"
                          />
                          <button class="btn btn-light border">Apply</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="card shadow-0 border">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">Total price:</p>
                      <p class="mb-2">
                        ₹{totalSubtotalPrice - totalSubtotalPricegst}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">Shipping:</p>
                      <p class="mb-2 text-success">₹{shippingCharge}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">TAX:</p>
                      <p class="mb-2">₹{totalSubtotalPricegst}</p>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between">
                      <p class="mb-2">Total price:</p>
                      <p class="mb-2 fw-bold">₹{totalAmount} </p>
                    </div>

                    <div class="mt-3">
                      <a
                        href="#"
                        onClick={() => {
                          orderSumit();
                        }}
                        class="btn btn-success w-100 shadow-0 mb-2"
                      >
                        {" "}
                        Proceed to checkout
                      </a>
                      {/* <a href="#" class="btn btn-light w-100 border mt-2">
                        {" "}
                        Back to shop{" "}
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- summary --> */}
            </div>
          </div>
        </section>
        {loading_checkOut ? (
          <div className="notfound">
            <h2>loading...</h2>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="ps-section__header2">
              <h1>Check Out</h1>
            </div>

            <div className="ps-section__content">
              <form
                className="ps-form--checkout"
                action="https://nouthemes.net/html/martfury/do_action"
                method="post"
              >
                <div className="row">
                  <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12  ">
                    <div className="ps-form__billing-info">
                      <table className="table ps-block__products">
                        <tbody>
                          {get_all_cart_itme &&
                            get_all_cart_itme?.map((data, i) => {
                              return (
                                <tr key={i}>
                                  <td>
                                    <a href="#">
                                      {" "}
                                      {data?.title} × {data?.item}
                                    </a>
                                    <p>
                                      Size:<strong>{data?.size}</strong>
                                    </p>
                                    <p>
                                      Color:
                                      <strong>
                                        {data?.productcolorId?.color}
                                      </strong>
                                    </p>
                                  </td>
                                  <td>₹ {data?.subtotalprice}</td>
                                </tr>
                              );
                            })}

                          {/* <tr>
                          <td>
                            <a href="#">
                              {" "}
                              Apple Macbook Retina Display 12” × 1
                            </a>
                            <p>
                              Sold By:<strong>ROBERT’S STORE</strong>
                            </p>
                          </td>
                          <td>$625.50</td>
                        </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-4 col-md-12 col-sm-12  ">
                    <div className="ps-form__total">
                      <h3 className="ps-form__heading">Your Order</h3>
                      <div className="content">
                        <div className="ps-block--checkout-total">
                          <div className="ps-block__header">
                            <p>Product</p>
                            <p>Total : {totalSubtotalPrice}</p>
                          </div>
                          <div className="ps-block__content">
                            {/* <table className="table ps-block__products">
                            <tbody>
                              <tr>
                                <td>
                                  <a href="#">
                                    {" "}
                                    MVMTH Classical Leather Watch In Black ×1
                                  </a>
                                  <p>
                                    Sold By:<strong>YOUNG SHOP</strong>
                                  </p>
                                </td>
                                <td>$57.99</td>
                              </tr>
                              <tr>
                                <td>
                                  <a href="#">
                                    {" "}
                                    Apple Macbook Retina Display 12” × 1
                                  </a>
                                  <p>
                                    Sold By:<strong>ROBERT’S STORE</strong>
                                  </p>
                                </td>
                                <td>$625.50</td>
                              </tr>
                            </tbody>
                          </table> */}
                            {/* <h4 className="ps-block__title">
                            Gst <span>₹ {totalSubtotalPricegst}</span>
                          </h4> */}
                            <h4 className="ps-block__title">
                              Shipping <span>₹{shippingCharge} </span>
                            </h4>
                            {/* <div className="ps-block__shippings">
                            <figure>
                              <h4>YOUNG SHOP Shipping</h4>
                              <p>Free shipping</p>
                              <a href="#">
                                {" "}
                                MVMTH Classical Leather Watch In Black ×1
                              </a>
                            </figure>
                            <figure>
                              <h4>ROBERT’S STORE Shipping</h4>
                              <p>Free shipping</p>
                              <a href="#">
                                Apple Macbook Retina Display 12” ×1
                              </a>
                            </figure>
                          </div> */}
                            <h3>
                              Grant Total <span>₹{totalAmount} </span>
                            </h3>
                          </div>
                        </div>
                        <a
                          className="ps-btn ps-btn--fullwidth"
                          href="#"
                          onClick={() => {
                            orderSumit();
                          }}
                        >
                          Proceed to checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Checkout;
