import React from "react";

const MixedNodeElement = ({
  nodeData = {},
  triggerNodeToggle,
  foreignObjectProps = {},
}) => {
  return (
    <React.Fragment>
      <circle r={20}></circle>
      <foreignObject {...foreignObjectProps}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // border: "1px solid black",
            paddingBottom: "1rem",
            borderRadius: "5px",
            backgroundColor: "#ffffff", // ghostwhite
          }}
          onClick={triggerNodeToggle}
        >
          <img
            alt={nodeData.name}
            src={`${nodeData.img}`}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            style={{
              width: "36px",
              background: "#000000",
              borderRadius: "5px",
            }}
          />
          <ul style={{ listStyleType: "none", padding: 0 }}>
            {nodeData.attributes &&
              Object.keys(nodeData.attributes).map((labelKey, i) => (
                <li key={`${labelKey}-${i}`} style={{ color: "#000000" }}>
                  {/* {labelKey}:  */}
                  {nodeData.attributes[labelKey]}
                </li>
              ))}
          </ul>
          <h6 style={{ color: "#000000", padding: "5px" }}>{nodeData.name}</h6>

          {/* {nodeData.children?.length > 0 && (
            <button
              style={{
                textAlign: "center",
                //  marginTop: "20px" ,
                borderRadius: "50%",
                border: "1px solid white",
              }}
              onClick={triggerNodeToggle}
            >
              {nodeData.__rd3t.collapsed ? "+" : "-"}
            </button>
          )} */}
          {/* <g
            data-ctrl-ec-id="RIL594113"
            transform="matrix(1,0,0,1,650,285)"
            style={{ cursor: "pointer" }}
          >
            <circle
              cx="15"
              cy="15"
              r="15"
              class="boc-fill"
              stroke="#aeaeae"
              stroke-width="1"
            ></circle>
            <line
              x1="4"
              y1="15"
              x2="26"
              y2="15"
              stroke-width="1"
              stroke="#aeaeae"
            ></line>
          </g> */}
        </div>
      </foreignObject>
    </React.Fragment>
  );
};

export default MixedNodeElement;
