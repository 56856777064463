// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import HomeSlider from "../Home/HomeSlider";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";

function AllJobListComponent() {
  const navigate = useNavigate();
  const navigate2 = useLocation();
  const get_job_portal_all = useSelector((state) =>
    state?.allapi?.get_job_portal_all ? state?.allapi?.get_job_portal_all : {}
  );
  const [search, setsearch] = useState("");
  console.log(get_job_portal_all);
  console.log(navigate2);
  const [userselete, setuserselete] = useState(null);
  const [userseletecate, setuserseletecate] = useState(null);

  console.log(userselete);
  useEffect(() => {
    if (navigate2?.state) {
      setsearch(navigate2?.state?.searchkey);
      setuserselete(navigate2?.state?.location);
      setuserseletecate(navigate2?.state?.category);
      dispatch(
        allapiAction.getjoballpageList({
          page: 1,
          totaldoc: 12,
          key: navigate2?.state?.searchkey,
          categoryId: navigate2?.state?.category?._id,
          locationId: navigate2?.state?.location?.District,
        })
      );
    } else {
      dispatch(allapiAction.getjoballpageList({ page: 1, totaldoc: 12 }));
    }

    return () => {};
  }, [navigate2]);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(allapiAction.getjoballpageList({ page: 1, totaldoc: 12 }));
    dispatch(allapiAction.getsliderList({ page: "alljobpagination" }));
  }, []);

  const [tokenuser, settokenuser] = useState("");
  const [tokenvendor, settokenverndorr] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const tokenvn = localStorage.getItem("access_token_vendor");
    settokenuser(token);
    settokenverndorr(tokenvn);
  }, []);

  const items = get_job_portal_all?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_job_portal_all, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    // if (search) {
    //   dispatch(
    //     allapiAction.getjoballpageList({
    //       page: data,
    //       totaldoc: 12,
    //       key: search,
    //       locationId: userselete?.District,
    //     })
    //   );
    // } else {
    const obj = { page: data, totaldoc: 12 };
    if (search) {
      obj.key = search;
    }
    if (userselete) {
      obj.locationId = userselete?.District;
    }
 
    if (userseletecate) {
      obj.categoryId = userseletecate?._id;
    }
    dispatch(allapiAction.getjoballpageList(obj));
    // }

    // router("/products?search=" + search + "&page=" + data);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };

  const hendleTosearch = (e) => {
    e?.preventDefault();
    dispatch(
      allapiAction.getjoballpageList({
        page: 1,
        totaldoc: 12,
        key: search,
        locationId: userselete?.District,
        categoryId: userseletecate?._id,
      })
    );
  };
  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);

    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproductDis?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District + ", " + data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionscategory = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);

    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacategorysearch?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  return (
    <div>
      <div className="hometest">
        <HomeSlider />
      </div>

      <div className="alljoblist">
        <div class="container mt-5">
          {/* <!-- Search Bar --> */}
          <div class="card p-4 mb-4">
            <form class="form-row">
              <div class="col-md-4 mb-2">
                <input
                  class="form-control"
                  type="search"
                  placeholder="Job Title, Keywords or Company Name..."
                  aria-label="Search"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
              <div class="col-md-3 mb-2">
                {/* <input
                  class="form-control"
                  type="search"
                  placeholder="Location"
                  aria-label="Location"
                /> */}
                <AsyncPaginate
                  value={userselete}
                  className="liceninputv2"
                  // placeholder="location"
                  placeholder="Location"
                  loadOptions={loadOptionsPalyer}
                  onChange={setuserselete}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div class="col-md-3 mb-2">
                {/* <select class="form-control">
                  <option value="">Category</option>
                  <option value="1">Software Developer</option>
                  <option value="2">Java Developer</option>
                  <option value="3">Web Developer</option>
                </select> */}
                <AsyncPaginate
                  value={userseletecate}
                  className="liceninputv2"
                  // placeholder="location"
                  placeholder="Category"
                  loadOptions={loadOptionscategory}
                  onChange={setuserseletecate}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
              <div class="col-md-2 mb-2">
                <button
                  class="btn btn-success btn-block"
                  type="submit"
                  onClick={(e) => hendleTosearch(e)}
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          {/* 
    <!-- Job Cards --> */}
          <div class="row">
            <div class="col-md-12">
              <h4> Jobs & Vacancies</h4>
            </div>
            {get_job_portal_all?.docs?.length == 0 && (
              <div class="col-md-12 col-12 p-1 text-center">
                <div style={{ marginTop: "100px", marginBottom: "100px" }}>
                  <h2> Not Found</h2>
                </div>
              </div>
            )}

            {get_job_portal_all?.docs &&
              get_job_portal_all?.docs.map((data, i) => {
                return (
                  <div class="col-md-3 col-6 p-5">
                    <div class="job-card myshedow">
                      <span class="badge badge-success"> {data?.job_type}</span>
                      {/* <i class="fas fa-heart heart-icon"></i> */}
                      <div class="text-center">
                        <img
                          src={
                            URL?.API_BASE_URL + data?.vendor_id?.sellerProfile
                          }
                          alt="Icon"
                          class="img-fluid mb-3 card_image"
                        />
                      </div>
                      <p class="text-center my_font">
                        <b>{data?.job_title} </b>
                      </p>
                      <p class="text-center my_font">{data?.company_name}</p>
                      {/* {tokenvendor ? (
                        <></>
                      ) : (
                        <button
                          class="btn btn-outline-success apply-btn"
                          onClick={() => {
                            if (tokenuser) {
                              navigate("/job-apply/" + data?._id);
                            } else {
                              navigate("/login");
                            }
                          }}
                        >
                          Apply Now
                        </button>
                      )} */}

                      <button
                        type="button"
                        class="btn btn-outline-success apply-btn"
                        onClick={() => navigate("/job-details/" + data?.slug)}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                );
              })}

            {/* <div class="col-md-3 col-6 p-1">
              <div class="job-card myshedow">
                <span class="badge badge-success">Full Time</span>
                <i class="fas fa-heart heart-icon"></i>
                <div class="text-center">
                  <img
                    src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                    alt="Icon"
                    class="img-fluid mb-3 card_image"
                  />
                </div>
                <p class="text-center my_font">
                  <b>Product Redesign </b>
                </p>
                <p class="text-center my_font">2708 Scenic Way, IL 62373</p>
                <button class="btn btn-outline-success apply-btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6 p-1">
              <div class="job-card myshedow">
                <span class="badge badge-success">Full Time</span>
                <i class="fas fa-heart heart-icon"></i>
                <div class="text-center">
                  <img
                    src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                    alt="Icon"
                    class="img-fluid mb-3 card_image"
                  />
                </div>
                <p class="text-center my_font">
                  <b>Product Redesign </b>
                </p>
                <p class="text-center my_font">2708 Scenic Way, IL 62373</p>
                <button class="btn btn-outline-success apply-btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6 p-1">
              <div class="job-card myshedow">
                <span class="badge badge-success">Full Time</span>
                <i class="fas fa-heart heart-icon"></i>
                <div class="text-center">
                  <img
                    src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                    alt="Icon"
                    class="img-fluid mb-3 card_image"
                  />
                </div>
                <p class="text-center my_font">
                  <b>Product Redesign </b>
                </p>
                <p class="text-center my_font">2708 Scenic Way, IL 62373</p>
                <button class="btn btn-outline-success apply-btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6 p-1">
              <div class="job-card myshedow">
                <span class="badge badge-success">Full Time</span>
                <i class="fas fa-heart heart-icon"></i>
                <div class="text-center">
                  <img
                    src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                    alt="Icon"
                    class="img-fluid mb-3 card_image"
                  />
                </div>
                <p class="text-center my_font">
                  <b>Product Redesign </b>
                </p>
                <p class="text-center my_font">2708 Scenic Way, IL 62373</p>
                <button class="btn btn-outline-success apply-btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6 p-1">
              <div class="job-card myshedow">
                <span class="badge badge-success">Full Time</span>
                <i class="fas fa-heart heart-icon"></i>
                <div class="text-center">
                  <img
                    src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                    alt="Icon"
                    class="img-fluid mb-3 card_image"
                  />
                </div>
                <p class="text-center my_font">
                  <b>Product Redesign </b>
                </p>
                <p class="text-center my_font">2708 Scenic Way, IL 62373</p>
                <button class="btn btn-outline-success apply-btn">
                  Apply Now
                </button>
              </div>
            </div>
            <div class="col-md-3 col-6 p-1">
              <div class="job-card myshedow">
                <span class="badge badge-success">Full Time</span>
                <i class="fas fa-heart heart-icon"></i>
                <div class="text-center">
                  <img
                    src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                    alt="Icon"
                    class="img-fluid mb-3 card_image"
                  />
                </div>
                <p class="text-center my_font">
                  <b>Product Redesign </b>
                </p>
                <p class="text-center my_font">2708 Scenic Way, IL 62373</p>
                <button class="btn btn-outline-success apply-btn">
                  Apply Now
                </button>
              </div>
            </div> */}

            {/* <!-- Repeat for other job cards --> */}
          </div>

          {/* <!-- Pagination --> */}
          {/* <div class="row">
            <div class="col-md-12">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Previous
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div class="container-fluid   mb-30">
        <div class="row">
          <div class="col-12">
            <div class="row  m-2">
              {get_job_portal_all?.docs &&
                get_job_portal_all?.docs?.map((data, index) => {
                  console.log(data);
                  return (
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-3 ggg">
                            <div class="col-6 p-3">
                              <p class="bg-light text-center border ">
                                {data?.job_type}
                              </p>
                            </div>
                            <div class="col-6 p-3">
                              <button
                                type="button"
                                class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart"
                              ></button>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4>{data?.job_title}</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p>{data?.company_name}</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn btn-outline-success"
                                onClick={() => {
                                  navigate("/job-apply/" + data?._id);
                                }}
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div> */}
      {get_job_portal_all?.totalDocs > 12 && (
        <div className="container  mt-30 mb-30">
          <nav
            className="d-flex justify-content-between mb-30"
            aria-label="Page navigation "
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              className="paging_simple_numbersc pagination"
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </nav>
        </div>
      )}
    </div>
  );
}

export default AllJobListComponent;
