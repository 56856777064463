// import { allapiAction } from "@/Redux/common/action";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import HomeSlider from "../Home/HomeSlider";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";

function JobsCategoryWise() {
  const navigate = useNavigate();
  const get_job_portal_all = useSelector((state) =>
    state?.allapi?.get_job_portal_all ? state?.allapi?.get_job_portal_all : {}
  );

  console.log(get_job_portal_all);

  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      allapiAction.getjobcategoryWiseList({
        page: 1,
        totaldoc: 12,
        categoryId: id,
      })
    );
    dispatch(allapiAction.getsliderList({ page: "alljobpagination" }));
  }, []);

  const [tokenuser, settokenuser] = useState("");
  const [tokenvendor, settokenverndorr] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const tokenvn = localStorage.getItem("access_token_vendor");
    settokenuser(token);
    settokenverndorr(tokenvn);
  }, []);

  const items = get_job_portal_all?.totalDocs;
  const itemsPerPage = 12;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_job_portal_all, items]);
  const [search, setsearch] = useState("");

  const [userseletecate, setuserseletecate] = useState(null);

  const [userselete, setuserselete] = useState(null);
  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    // if (search) {
    //   dispatch(
    //     allapiAction.getjobcategoryWiseList({
    //       page: data,
    //       totaldoc: 12,
    //       key: search,
    //       categoryId: id,
    //       locationId: userselete?.District,
    //     })
    //   );
    // } else {

    const obj = { page: data, totaldoc: 12, categoryId: id };
    if (search) {
      obj.key = search;
    }
    if (userselete) {
      obj.locationId = userselete?.District;
    }

    // if (userseletecate) {
    //   obj.categoryId = userseletecate?._id;
    // }
    dispatch(allapiAction.getjobcategoryWiseList(obj));
    // dispatch(
    //   allapiAction.getjobcategoryWiseList({
    //     page: data,
    //     totaldoc: 12,
    //     categoryId: id,
    //     locationId: userselete?.District,
    //   })
    // );
    // }

    // router("/products?search=" + search + "&page=" + data);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);

    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproductDis?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District + ", " + data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };

  const hendleTosearch = (e) => {
    e?.preventDefault();
    dispatch(
      allapiAction.getjobcategoryWiseList({
        page: 1,
        totaldoc: 12,
        key: search,
        categoryId: id,
        locationId: userselete?.District,
      })
    );
  };

  return (
    <div>
      <div className="hometest">
        <HomeSlider />
      </div>

      <div class="neww">
        <div class="page-title mt-1">
          <div class="container-fluid">
            <div class="page-caption text-center">
              <h1>
                <br />
                Job In Grid
              </h1>
              <p>
                <a href="#" title="Home">
                  Home
                </a>{" "}
                <i class="ti-angle-double-right"></i> Job Layout One
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="all">
        <div class="container mt-5">
          {/* <!-- Job Filters --> */}
          <div class="row">
            <div class="col-lg-3 col-md-4 mb-4">
              <div class="card p-3 mb-4">
                <h5>Search Keywords</h5>
                <input
                  class="form-control mb-2"
                  type="search"
                  placeholder="Job Title, Keywords or Company Name..."
                  aria-label="Search"
                />
                {/* <input
                  class="form-control mb-2"
                  type="search"
                  placeholder="All Locations"
                  aria-label="Location"
                /> */}
                <AsyncPaginate
                  value={userselete}
                  className="liceninputv2"
                  // placeholder="location"
                  placeholder="Location"
                  loadOptions={loadOptionsPalyer}
                  onChange={setuserselete}
                  additional={{
                    page: 1,
                  }}
                />

                <button
                  class="btn btn-success btn-block mt-3"
                  type="submit"
                  onClick={(e) => hendleTosearch(e)}
                >
                  Search
                </button>
              </div>
              {/* <div class="card p-3 mb-4">
                <h5>Offered Salary</h5>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salary"
                    id="under10k"
                    value="under10k"
                  />
                  <label class="form-check-label" for="under10k">
                    Under $10,000
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salary"
                    id="10k-15k"
                    value="10k-15k"
                  />
                  <label class="form-check-label" for="10k-15k">
                    $10,000 - $15,000
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salary"
                    id="15k-20k"
                    value="15k-20k"
                  />
                  <label class="form-check-label" for="15k-20k">
                    $15,000 - $20,000
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salary"
                    id="20k-30k"
                    value="20k-30k"
                  />
                  <label class="form-check-label" for="20k-30k">
                    $20,000 - $30,000
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="salary"
                    id="30k-40k"
                    value="30k-40k"
                  />
                  <label class="form-check-label" for="30k-40k">
                    $30,000 - $40,000
                  </label>
                </div>
              </div> */}
            </div>
            <div class="col-lg-9 col-md-8">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <h4>{get_job_portal_all?.totalDocs} Jobs & Vacancies</h4>
                {/* <div>
                  <select
                    class="form-control d-inline-block"
                    style={{ width: "auto" }}
                  >
                    <option>Most Recent</option>
                    <option>Most Relevant</option>
                  </select>
                  <select
                    class="form-control d-inline-block"
                    style={{ width: "auto" }}
                  >
                    <option>10 Per Page</option>
                    <option>20 Per Page</option>
                  </select>
                </div> */}
              </div>
              {/* <!-- Job Cards --> */}

              {get_job_portal_all?.docs &&
                get_job_portal_all?.docs?.map((data, i) => {
                  return (
                    <div class="job-card">
                      <div class="row">
                        <div class="col-md-2 text-center">
                          <img
                            src={
                              URL?.API_BASE_URL + data?.vendor_id?.sellerProfile
                            }
                            alt={data?.job_title}
                            class="img-fluid"
                            style={{ width: "70px" }}
                          />
                        </div>
                        <div class="col-md-8">
                          <h5>{data?.company_name}</h5>
                          <p>{data?.job_title} </p>
                          {/* <p>
                            <strong>Job Id:</strong> G56726
                          </p> */}
                          <p>
                            <strong>Job Type:</strong> {data?.job_type}
                          </p>
                          <div class="skills">
                            {/* <span>HTML</span>
                            <span>CSS</span>
                            <span>Java</span>
                            <span>PHP</span> */}
                          </div>
                          <p>
                            <strong>Experience:</strong> {data?.job_experience}
                          </p>
                          <p>
                            <strong>Location:</strong> {data?.address},
                            {data?.city}
                          </p>
                        </div>
                        <div class="col-md-2 text-center">
                          {/* <p>
                            <strong>No.</strong> 2
                          </p> */}
                          {/* <button class="btn apply-btn mb-2">Apply Now</button> */}
                          <button
                            class="btn view-btn"
                            onClick={() =>
                              navigate("/job-details/" + data?.slug)
                            }
                          >
                            View Job
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* <div class="job-card">
                <div class="row">
                  <div class="col-md-2 text-center">
                    <img
                      src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                      alt="Company Logo"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-md-8">
                    <h5>Apple LTD</h5>
                    <p>Software Development</p>
                    <p>
                      <strong>Job Id:</strong> G56726
                    </p>
                    <p>
                      <strong>Job Type:</strong> Full Time
                    </p>
                    <div class="skills">
                      <span>HTML</span>
                      <span>CSS</span>
                      <span>Java</span>
                      <span>PHP</span>
                    </div>
                    <p>
                      <strong>Experience:</strong> 3 Years
                    </p>
                    <p>
                      <strong>Location:</strong> 2844 Counts Lane, KY 45241
                    </p>
                  </div>
                  <div class="col-md-2 text-center">
                    <p>
                      <strong>No.</strong> 2
                    </p>
                    <button class="btn apply-btn mb-2">Apply Now</button>
                    <button class="btn view-btn">View Job</button>
                  </div>
                </div>
              </div>
              <div class="job-card">
                <div class="row">
                  <div class="col-md-2 text-center">
                    <img
                      src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                      alt="Company Logo"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-md-8">
                    <h5>Google LTD</h5>
                    <p>Software Development</p>
                    <p>
                      <strong>Job Id:</strong> G56726
                    </p>
                    <p>
                      <strong>Job Type:</strong> Full Time
                    </p>
                    <div class="skills">
                      <span>HTML</span>
                      <span>CSS</span>
                      <span>Java</span>
                      <span>PHP</span>
                    </div>
                    <p>
                      <strong>Experience:</strong> 3 Years
                    </p>
                    <p>
                      <strong>Location:</strong> 2844 Counts Lane, KY 45241
                    </p>
                  </div>
                  <div class="col-md-2 text-center">
                    <p>
                      <strong>No.</strong> 2
                    </p>
                    <button class="btn apply-btn mb-2">Apply Now</button>
                    <button class="btn view-btn">View Job</button>
                  </div>
                </div>
              </div> */}
              {/* <!-- Additional Job Cards can be added here in the same format --> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container  mt-30 mb-30">
        <nav
          className="d-flex justify-content-between mb-30"
          aria-label="Page navigation "
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            className="paging_simple_numbersc pagination"
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
          />
        </nav>
      </div>
    </div>
  );
}

export default JobsCategoryWise;
