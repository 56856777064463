import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import Editor from "ckeditor5-custom-build/build/ckeditor";

function Cktest(props) {
  const { aboutPage, setaboutPage, uploadPlugin } = props;

  // console.log(aboutPage);
  // console.log(props);
  return (
    <div>
      <CKEditor
        editor={Editor}
        data={aboutPage}
        onReady={(editor) => {
          // console.log('SDfsf')
          // console.log(editor)
        }}
        onChange={(event, editor) => {
          // console.log(editor)
          const data = editor.getData();
          setaboutPage(data);
        }}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        onBlur={(event, editor) => {
          // console.log("SDfsf");
        }}
        onFocus={(event, editor) => {
          // console.log("SDfsf");
        }}
      />
    </div>
  );
}

export default Cktest;
