import { Col, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import HeadingBack from "../component/HeadingBack";
// import { allapiAction } from "../../Redux/common/action";

import Cktest from "../../cktetst/Cktest";
// import { URL } from "@/Redux/common/url";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
// import Api from "@/Redux/common/api";
import Select from "react-select";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/build/ckeditor";
// import dynamic from "next/dynamic";
// const Cktest = dynamic(() => import("../../cktetst/Cktest"), {
//   ssr: false,
// });
import ImageUploading from "react-images-uploading";
import { DeleteOutlined } from "@ant-design/icons";
// import { useRouter } from "next/router";
import HeadingBack from "../../HeadingBack";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
// import Image from "next/image";
// import Link from "next/link";
import { Image } from "react-bootstrap";
import { URL } from "../../../Redux/common/url";
import { Link, useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";
import Api from "../../../Redux/common/api";
function UpdateProductCom() {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [aboutPage, setaboutPage] = useState("");
  const [images, setImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [subList, setsubList] = useState([]);
  const [subList4, setsubList4] = useState([]);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [maincateList, setmaincateList] = useState([]);
  const [selectedOptionPr, setSelectedOptionPr] = useState(null);

  const [selectedOptionbrand, setSelectedOptionbrand] = useState(null);
  const [branList, setbrandList] = useState([]);
  const [productData, setproductData] = useState({});
  const { id } = useParams();
  const get_sub_sub_category_list = useSelector((state) =>
    state.allapi.get_sub_sub_category_list
      ? state.allapi.get_sub_sub_category_list
      : []
  );

  const get_main_all_category = useSelector((state) =>
    state?.allapi?.get_main_all_category
      ? state?.allapi?.get_main_all_category
      : []
  );
  const get_sub_sub_category = useSelector((state) =>
    state?.allapi?.get_sub_sub_all_category
      ? state?.allapi?.get_sub_sub_all_category
      : []
  );
  const get_product_details = useSelector((state) =>
    state.allapi.get_product_details ? state.allapi.get_product_details : {}
  );

  const grt_all_brand = useSelector((state) =>
    state.allapi.grt_all_brand ? state.allapi.grt_all_brand : []
  );
  useEffect(() => {
    if (get_product_details?.subcolor?.length > 0) {
      // setImagesfe([{data_url: URL.API_BASE_URL + e.image}])

      // const finalCate = get_product_details?.categoryDetails[0]

      const selectCate = subList.find(
        (edata) => edata?._id == get_product_details?.categoryId
      );

      if (get_product_details?.description !== undefined) {
        setaboutPage(get_product_details?.description);
      }

      setSelectedOption(selectCate);
      setproductData(get_product_details);

      setImages([
        { data_url: URL.API_BASE_URL + get_product_details.featureImage },
      ]);
      const finalData = get_product_details?.subcolor?.map((data) => {
        const dataImg = data?.images?.map((data2) => {
          const finalimg = { data_url: URL.API_BASE_URL + data2.img };
          return finalimg;
        });
        data.images = dataImg;
        return data;
      });

      setRows(finalData);
    }
  }, [get_product_details]);

  useEffect(() => {
    const dropList = get_main_all_category?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setmaincateList(dropList);
  }, [get_main_all_category]);
  useEffect(() => {
    console.log(maincateList);

    if (maincateList?.length > 0 && get_product_details?.categoryDetails) {
      const getfind = maincateList.find(
        (edata) =>
          edata?._id == get_product_details?.categoryDetails[0]?.parentId
      );

      console.log(get_product_details?.categoryDetails?.parentId);
      console.log(getfind);

      if (getfind !== undefined) {
        console.log(getfind);

        setSelectedOptionPr(getfind);

        const filterdata = get_sub_sub_category?.filter((data) => {
          return data?.parentId == getfind?._id;
        });

        const dropList = filterdata?.map((data, i) => {
          data.value = data?._id;
          data.label = data?.name;

          return data;
        });

        const getfindv = dropList.find(
          (edata) =>
            edata?._id == get_product_details?.categoryDetails[0]?.subparentId
        );

        setsubList4(dropList);
        setSelectedOption4(getfindv);
      }
    }
  }, [maincateList, get_product_details]);
  useEffect(() => {
    console.log(branList);

    if (branList?.length > 0 && get_product_details?.categoryDetails) {
      const getfind = branList.find(
        (edata) => edata?._id == get_product_details?.brandId
      );

      console.log(get_product_details?.brandId);
      console.log(branList);
      console.log(getfind);

      if (getfind !== undefined) {
        setSelectedOptionbrand(getfind);
      }
    }
  }, [branList, get_product_details]);

  useEffect(() => {
    const dropList = grt_all_brand?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setbrandList(dropList);
  }, [grt_all_brand]);

  // useEffect(() => {
  //   if (selectedOptionPr !== null) {

  //   }
  // }, [selectedOptionPr]);

  const hendletotest = (e) => {
    setSelectedOptionPr(e);
    const filterdata = get_sub_sub_category?.filter((data) => {
      return data?.parentId == e?._id;
    });

    const dropList = filterdata?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList4(dropList);
    setSelectedOption4(null);
    setSelectedOption(null);
  };

  //   const maxNumber = 1

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };

  //   console.log(rows);

  useEffect(() => {
    // dispatch(adminAction.getPermissionsList('dhsg'))
    // dispatch(adminAction.getProductList(1))
    dispatch(allapiAction.getsubsubcate({}));
    dispatch(allapiAction.getbrandAllListseller({}));
    dispatch(allapiAction.productDetails(id));
    // dispatch(adminAction.getSellerListforpro({}))
    // dispatch(adminAction.searchtags({}))
    // dispatch(adminAction.getRoleList('dhsg'))
    dispatch(allapiAction.getsubcateAllList({}));
    dispatch(allapiAction.getcateAllList({}));
    return () => {};
  }, [id]);

  useEffect(() => {
    const dropList = get_sub_sub_category_list?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    setsubList(dropList);
  }, [get_sub_sub_category_list]);

  const maxNumber = 3;

  //   const HendleImgFVer = (e, pictureDataURLs, index) => {
  //     console.log(e);
  //     console.log(pictureDataURLs);
  //     console.log(index);

  //     setRows((prevRows) => {
  //       const updatedRows = [...prevRows];
  //       console.log(updatedRows);
  //       updatedRows[index].image.push(e[e?.length - 1]);
  //       return updatedRows;
  //     });
  //   };

  console.log("page render");
  const HendleImgFVer = (e, pictureDataURLs, index) => {
    // Log to check if the function is getting the right parameters
    // console.log(e);
    // console.log(pictureDataURLs);
    // console.log(index);

    // Assuming that 'rows' is a state variable and 'setRows' is a state updater function
    setRows((prevRows) => {
      // Create a copy of the previous rows array to avoid mutating state directly
      const updatedRows = [...prevRows];
      console.log("111");
      // Assuming 'e' is an array of images, you want to push the last image to a specific row
      // You should check if 'updatedRows[index].image' is an array, and if not, initialize it as an array

      //   console.log(updatedRows[index].image);
      if (!Array.isArray(updatedRows[index].images)) {
        updatedRows[index].images = [];
      }

      // Push the last image from 'e' to the 'image' array in the specific row
      updatedRows[index].images.push(e[e.length - 1]);
      //   console.log(updatedRows);
      // Return the updated rows array to update the state
      console.log("222");

      return updatedRows;
    });
  };
  const onRemoveImage = (variantIndex, imageIndex) => {
    setRows((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[variantIndex].images.splice(imageIndex, 1);
      return updatedVariants;
    });
  };

  const addSizeProduct = (index) => {
    console.log("test-0");
    setRows((prevRows) => {
      console.log("test-1");
      const updatedRows = [...prevRows];
      updatedRows[index].productsize.push({
        size: "",
        sizetype: "",
        price: "",
        stock: "",
      });
      console.log("test-2");
      return updatedRows;
    });
  };

  const removeSizeProduct = (rowIndex, sizeIndex) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].productsize.splice(sizeIndex, 1);
      return updatedRows;
    });
  };
  const removeColorProduct = (index) => {
    // setRows((prevRows) => {
    //   const updatedRows = [...prevRows]
    //   updatedRows[rowIndex].sizeProduct.splice(sizeIndex, 1)
    //   return updatedRows
    // })

    setRows((prevRows) => prevRows.filter((row, i) => i !== index));
  };

  const handleSizeChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex].productsize[sizeIndex][field] = value;
      return updatedRows;
    });
  };
  const handleColorChange = (rowIndex, sizeIndex, field, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[rowIndex][field] = value;
      return updatedRows;
    });
  };
  const API_URl = URL.API_BASE_URL;
  const UPLOAD_ENDPOINT = "publicApi/productcon";

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("upload", file);
            fetch(`${API_URl}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res);
                resolve({ default: `${res.url}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  //   createProduct2

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setproductData({ ...productData, [name]: value });
  };

  const hendleTosubmit = async () => {
    const data = new FormData();

    if (images?.length == 0) {
      toast.error("please select feature Image");
    } else if (!productData?.title) {
      toast.error("please enter prodcat name");
    } else if (!selectedOption) {
      toast.error("please select category ");
    } /* else if (!selectedOption1) {
          toast.error('please select seller')
        } */ else {
      data.append("productId", productData?._id);
      data.append("title", productData?.title);
      data.append("price", productData?.price);
      data.append("seo_title", productData?.seo_title);
      data.append("sku", productData?.sku);
      data.append("bv", productData?.bv);
      data.append("bouns", productData?.bouns);
      data.append("seo_description", productData?.seo_description);
      data.append("categoryId", selectedOption?._id);
      data.append("category1Id", selectedOptionPr?._id);
      data.append("brandId", selectedOptionPr?._id);
      data.append("category2Id", selectedOption4?._id);
      data.append("gst", productData?.gst);
      data.append("delivery_charges", productData?.delivery_charges);
      data.append("afterdiscountprice", productData?.afterdiscountprice);
      // data.append("categoryId", selectedOption?._id);
      data.append("description", aboutPage);

      if (images[0]?.file) {
        // roledata?.role_name
        data.append("featureImage", images[0]?.file);
      }
      //   data.append("featureImage", images[0]?.file);

      const token = await localStorage.getItem("access_token_vendor");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await Api.post(`${URL.updateProduct}`, data, config);

      // rows
      for (const row of rows) {
        console.log(row);
        const data2 = new FormData();
        data2.append("productId", productData?._id);
        data2.append("color", row?.color);
        if (row?._id) {
          data2.append("_id", row?._id);
        }
        data2.append("gst", productData?.gst);
        data2.append("delivery_charges", productData?.delivery_charges);

        const psize = row?.productsize?.map((data) => {
          data.productcolorId = data.productcolorId
            ? data.productcolorId
            : row?._id;
          return data;
        });
        data2.append("sizeProduct", JSON.stringify(psize));

        row?.images?.forEach((file) => {
          console.log(file);
          //   console.log(file?.file)

          if (file?.file) {
            console.log("yes");
            data2.append("images", file?.file);
          } else {
            console.log("no");
            const relativeUrl = file?.data_url.replace(URL.API_BASE_URL, "");
            data2.append("oldimages", relativeUrl);
          }

          //   if (images[0]?.file) {
          //     // roledata?.role_name
          //     data.append("featureImage", images[0]?.file);
          //   }
        });

        const response2 = await Api.post(
          `${URL.updateProductvariations}`,
          data2,
          config
        );
      }

      navigate("/seller/product");
    }
  };

  return (
    <div className=" code-box">
      <div className="flex">
        <div>
          {/* <HeadingBack
            title={"Product"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          /> */}

          <div className="flex gap10">
            <div>
              <Tooltip title="Back">
                <Link
                  to={"/seller/product"}
                  //  onClick={() => navigate?.back()}
                >
                  {/* <LeftArrow
              className="scale-icon"
              style={{ verticalAlign: "middle" }}
            /> */}

                  <Image
                    src={SvgIcon?.LeftArrow?.src}
                    alt="Left Arrow"
                    width={20}
                    height={20}
                    style={{ verticalAlign: "middle" }}
                  />
                </Link>
              </Tooltip>
            </div>
            <div>
              <div className="opacity-8 fw-bold fs-16">Listing</div>
              {/* <div className="opacity-5 fw-600 fs-12">{subHeading}</div> */}
            </div>
          </div>
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5"></Col>
      </div>

      <div>
        <div className="container pdcon">
          <div className="row">
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Feature</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <ImageUploading
                  // multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                  // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button>
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Product Name</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a product name"
                  name="title"
                  value={productData?.title}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Product Sku</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a sku"
                  name="sku"
                  value={productData?.sku}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a price"
                  name="price"
                  value={productData?.price}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">after discount price</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a after discount price"
                  name="afterdiscountprice"
                  value={productData?.afterdiscountprice}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Gst</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a gst"
                  name="gst"
                  value={productData?.gst}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">bv</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a bv"
                  name="bv"
                  value={productData?.bv}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Bonus</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a bonus"
                  name="bouns"
                  value={productData?.bouns}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">delivery charges</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a delivery charges"
                  name="delivery_charges"
                  value={productData?.delivery_charges}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Brand</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOptionbrand}
                  onChange={setSelectedOptionbrand}
                  options={branList}
                  // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOptionPr}
                  onChange={(e) => {
                    hendletotest(e);
                  }}
                  options={maincateList}
                  // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Sub Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOption4}
                  onChange={setSelectedOption4}
                  options={subList4}
                  // className="mt-8"
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Select Sub Sub Category</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>

                <Select
                  value={selectedOption}
                  onChange={setSelectedOption}
                  options={subList}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">Description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                {/* <CKEditor
                  editor={Editor}
                  data={aboutPage}
                  onReady={(editor) => {
                    // console.log('SDfsf')
                    // console.log(editor)
                  }}
                  onChange={(event, editor) => {
                    // console.log(editor)
                    const data = editor.getData();
                    setaboutPage(data);
                  }}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  onBlur={(event, editor) => {
                    console.log("SDfsf");
                  }}
                  onFocus={(event, editor) => {
                    console.log("SDfsf");
                  }}
                /> */}
                <Cktest
                  aboutPage={aboutPage}
                  setaboutPage={setaboutPage}
                  uploadPlugin={uploadPlugin}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo title</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo title"
                  name="seo_title"
                  value={productData?.seo_title}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold form-label mb-2">
                  <span className="required">seo description</span>
                  <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="Permission names is required to be unique."
                  ></i>
                </label>
                <input
                  // className='form-control form-control-solid'
                  placeholder="Enter a seo description"
                  name="seo_description"
                  value={productData?.seo_description}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                  className="form-control bg-transparent"
                  // onChange={(e:any)=>{hendleChangerole(e)}}
                />
              </div>
            </div>
          </div>

          <h5>Variations</h5>
          {rows.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.productsize.map((sizeProduct, sizeIndex) => (
                <div>
                  {sizeIndex === 0 && (
                    <div className="row">
                      <div className="col-4">
                        <div className="fv-row mb-7">
                          <label className="fs-6 fw-semibold form-label mb-2">
                            <span className="required">Color</span>
                            <i
                              className="fas fa-exclamation-circle ms-2 fs-7"
                              data-bs-toggle="popover"
                              data-bs-trigger="hover"
                              data-bs-html="true"
                              data-bs-content="Permission names is required to be unique."
                            ></i>
                          </label>
                          <input
                            // className='form-control form-control-solid'
                            placeholder="Enter color"
                            name="seo_description"
                            value={row.color}
                            onChange={(e) =>
                              handleColorChange(
                                rowIndex,
                                sizeIndex,
                                "color",
                                e.target.value
                              )
                            }
                            className="form-control bg-transparent"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <ImageUploading
                          //   withIcon={true}
                          multiple
                          value={row?.images}
                          // onChange={onChange}
                          onChange={(e, defaultImages) => {
                            HendleImgFVer(e, defaultImages, rowIndex);
                          }}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["png", "jpeg", "jpg", "webp"]}
                          // acceptType={['jpg']}
                        >
                          {({ imageList, onImageUpload, onImageRemove }) => (
                            <div>
                              {imageList.map((image, imageIndex) => (
                                <div
                                  key={imageIndex}
                                  className={"proadDImgContainer"}
                                >
                                  <div className="image-item">
                                    <img
                                      width="100"
                                      src={image.data_url}
                                      alt={`Variant ${rowIndex + 1}, Image ${
                                        imageIndex + 1
                                      }`}
                                    />
                                  </div>

                                  <button
                                    className="proadDImgBtn btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
                                    onClick={() =>
                                      onRemoveImage(rowIndex, imageIndex)
                                    }
                                  >
                                    <i className="fas fa-trash-alt"></i>
                                    <DeleteOutlined />
                                    {/* Remove */}
                                  </button>
                                </div>
                              ))}
                              <button
                                className="btn btn-success mt-5"
                                onClick={onImageUpload}
                              >
                                Add Image
                              </button>
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  )}
                  {/* <h6>Size</h6> */}
                  <div className="row">
                    <div className="col-2">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">Size</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter Size"
                          name="size"
                          value={sizeProduct.size}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "size",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">Size Type</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <select
                          className="form-control bg-transparent"
                          name="sizetype"
                          value={sizeProduct.sizetype}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "sizetype",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Size Type</option>
                          <option value="PICs">PICs</option>
                          <option value="Units">Units</option>
                          <option value="KG">KG</option>
                          <option value="G">G</option>
                          <option value="liter">Liter</option>
                          <option value="ml">ML</option>
                          <option value="Meter">Meter</option>
                          <option value="Inch">Inch</option>
                          <option value="Cm">Cm</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-2">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">price</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          type="number"
                          placeholder="Enter price"
                          name="price"
                          value={sizeProduct.price}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "price",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      {" "}
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">after discount price</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter after discount price"
                          type="number"
                          name="afterdiscountprice"
                          value={sizeProduct.afterdiscountprice}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "afterdiscountprice",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="fv-row mb-7">
                        <label className="fs-6 fw-semibold form-label mb-2">
                          <span className="required">stock</span>
                          <i
                            className="fas fa-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="popover"
                            data-bs-trigger="hover"
                            data-bs-html="true"
                            data-bs-content="Permission names is required to be unique."
                          ></i>
                        </label>
                        <input
                          // className='form-control form-control-solid'
                          placeholder="Enter stock"
                          name="stock"
                          type="number"
                          value={sizeProduct.stock}
                          onChange={(e) =>
                            handleSizeChange(
                              rowIndex,
                              sizeIndex,
                              "stock",
                              e.target.value
                            )
                          }
                          className="form-control bg-transparent"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      {row.productsize.length > 1 && (
                        <button
                          className="btn btn-danger"
                          onClick={() => removeSizeProduct(rowIndex, sizeIndex)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-4">
                {" "}
                <button
                  className="btn btn-success"
                  onClick={() => addSizeProduct(rowIndex)}
                >
                  Add Size
                </button>
                <button
                  className="btn btn-danger ms-5"
                  onClick={() => removeColorProduct(rowIndex)}
                >
                  Remove Color
                </button>
              </div>

              <hr />
            </React.Fragment>
          ))}
          <table></table>

          <div className="row">
            <div className="col-8">
              {" "}
              <button
                className="btn btn-bg-success"
                onClick={() =>
                  setRows((prevRows) => [
                    ...prevRows,
                    {
                      color: "",
                      images: [],
                      productsize: [
                        { size: "", sizetype: "", price: "", stock: "" },
                      ],
                    },
                  ])
                }
              >
                Add Row
              </button>
            </div>

            <div className="col-4">
              {" "}
              <button
                className="btn btn-primary"
                onClick={() => hendleTosubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProductCom;
