import { Tooltip } from "antd";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React from "react";
import SvgIcon from "../const/SvgIcon";
// import { Link, useNavigate } from "react-router-dom";
// import SvgIcon from "../../const/SvgIcon";
import { ReactComponent as LeftArrow } from "../assets/svg/left-arrow.svg";
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
// import Image from "next/image";
const HeadingBack = ({ title, subHeading }) => {
  const navigate = useNavigate();

  return (
    <div className="flex gap10">
      <div>
        <Tooltip title="Back">
          <Link to={""} onClick={() => navigate(-1)}>
            {/* <LeftArrow
              className="scale-icon"
              style={{ verticalAlign: "middle" }}
            /> */}
            {/* <SvgIcon.LeftArrow
              className="scale-icon"
              style={{ verticalAlign: "middle" }}
            /> */}
            <Image
              src={SvgIcon?.LeftArrow}
              alt="Left Arrow"
              width={20}
              height={20}
              style={{ verticalAlign: "middle" }}
            />
          </Link>
        </Tooltip>
      </div>
      <div>
        <div className="opacity-8 fw-bold fs-16">{title}</div>
        <div className="opacity-5 fw-600 fs-12">{subHeading}</div>
      </div>
    </div>
  );
};

export default HeadingBack;
