
import logo from "./logo.svg";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AppRoutes from "./Routes/Routes";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.min.css";
import Header from "./Component/Layout/Header";
import Footer from "./Component/Layout/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import FooterNew from "./Component/Layout/FooterNew";
import "aos/dist/aos.css";
import AOS from "aos";
import WddingFooter from "./Component/Wedding/WddingFooter";
import WeddingHeader from "./Component/Wedding/WeddingHeader";
function App() {
  const router = useLocation();
  const { pathname } = router;
  const splitLocation = pathname.split("/");
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="fullresponsive">
      {splitLocation[1] == "seller" || splitLocation[1] == "accounts" ? (
        <></>
      ) : splitLocation[1] == "vendor-store" ? (
        <></>
      ) : splitLocation[1] == "wedding" ? (
        <WeddingHeader />
      ) : (
        <Header />
      )}
      {/* <Header /> */}
      <AppRoutes />
      <ToastContainer theme="colored" />

      {splitLocation[1] == "seller" || splitLocation[1] == "accounts" ? (
        <></>
      ) : splitLocation[1] == "vendor-store" ? (
        <></>
      ) : splitLocation[1] == "wedding" ? (
        <div className="weddingportal">
          <WddingFooter />
        </div>
      ) : (
        <FooterNew />
      )}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
