// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
// import HomeSlider from "../../../Component/Home/HomeSlider";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { URL } from "../../Redux/common/url";
import { Link, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import CoustomImg from "../CoustomImg/CoustomImg";
import HomeSlider from "../Home/HomeSlider";
// import { allapiAction } from "@/Redux/common/action";
// import CoustomImg from "../../../Component/CoustomImg/CoustomImg";

function EducationDetailsPage({}) {
  const [lang, setLang] = useState("en");
  const { id } = useParams();
  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );

  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const dispatch = useDispatch();

  useEffect(() => {
    hendletogetdata();
    dispatch(allapiAction.getsliderList({ page: "home" }));
    dispatch(allapiAction.getMarqueesList2({ page: "education" }));
  }, [id]);

  const langs = [
    { lang: "en", fullName: "English", shortName: "EN" },
    { lang: "hi", fullName: "Hindi", shortName: "HI" },
  ];

  const handleLangChange = (e) => {
    let langObj = e.target.value;
    langObj = JSON.parse(langObj);
    setLang(langObj.lang);
  };
  const [post, setpost] = useState({});
  const [reletedexam, setreletedexam] = useState([]);

  const hendletogetdata = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   slug: params.slug,
    // });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const bgl = await fetch(
      URL.API_BASE_URL + `${URL.geteducatetDetails}/${id}`,
      requestOptions
    );
    const bg2 = await fetch(
      URL.API_BASE_URL + `${URL.geteducatetDetailsList}/${id}`,
      requestOptions
    );
    const events = await bgl.json();
    const events2 = await bg2.json();
    const postData = events?.data;
    const postData2 = events2?.data;
    setpost(postData);
    setreletedexam(postData2);
    // if (!events) {
    //   return { notFound: true };
    // }

    // return {
    //   props: {
    //     post: postData,
    //     reletedexam: postData2,
    //   },
    // };
  };

  return (
    <div>
      <div className="hometest">
        <div className="marquee">
          <marquee behavior="scroll" direction="left" scrollamount="05">
            {news &&
              news?.map((data, i) => {
                return <>{data?.title}</>;
              })}
          </marquee>
        </div>
        <HomeSlider />
      </div>
      <div className="container-fluid">
        <select className="p-5 mb-5" onChange={(e) => handleLangChange(e)}>
          {langs.map((langObj) => (
            <option key={langObj.lang} value={JSON.stringify(langObj)}>
              {langObj.fullName}
            </option>
          ))}
        </select>
        <div className="row p-4 h-75">
          <div className="col-sm-2 ">
            <h4 className="link_topic">Important Links</h4>
            {reletedexam &&
              reletedexam?.map((data, i) => {
                return (
                  <p className="pen p-3" key={i}>
                    {data?.name}
                    <Link
                      to={"/education-details/" + data?.slug}
                      className="primary"
                    >
                      click
                    </Link>
                  </p>
                );
              })}
          </div>
          <div className="col-sm-8">
            <h3 className="bg-warning p-2">{post?.name}</h3>
            <h4 className=" p-2">
              Updated On : {moment(post?.createdAt).format(" DD MMM, YYYY ")}{" "}
            </h4>
            <CoustomImg
              url={URL.API_BASE_URL + post?.image}
              className="img-fluid"
              altkey={""}
              w1={100}
              w2={150}
              h1={100}
              h2={150}
            />
            {lang === "en" ? (
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: post?.description,
                }}
              ></div>
            ) : (
              <div
                className=""
                dangerouslySetInnerHTML={{
                  __html: post?.hidescription,
                }}
              ></div>
            )}
          </div>
          <div className="col-sm-2">
            <div className="border text-center">
              <img
                src="https://testwale.s3.amazonaws.com/media/paid-batch-banner/WhatsApp_Image_2023-02-13_at_17.49.13.jpeg"
                className="img-fluid"
              />
              <button type="button" className="btn btn-warning px-5">
                Buy Now
              </button>
              <br />
              <br />
            </div>
            <br />
            <div className="border text-center">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzOSzd6NniANJf9ZmWZjgFnYFKA5m003xhxg&usqp=CAU"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default EducationDetailsPage;
