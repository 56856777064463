import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";

import ReactToPrint from "react-to-print";

// import { useRouter } from "next/router";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import HeadingBack from "../HeadingBack";
import { URL } from "../../Redux/common/url";
import { useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
// import HeadingBack from "../../HeadingBack";

function MyIncomeComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = navigate;
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const componentRef = useRef();
  const { confirm } = Modal;

  const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const search = navigate?.query?.search == null ? "" : navigate?.query?.search;

  //   console.log(navigate);
  //   console.log(navigate?.query?.page);
  //   console.log(searchParams);
  //   console.log(page);
  //   console.log(searchData);

  const order_list = useSelector((state) =>
    state?.allapi?.get_seller_ticket_list
      ? state?.allapi?.get_seller_ticket_list
      : {}
  );

  const all_message_List = useSelector((state) =>
    state?.allapi?.all_message_List ? state?.allapi?.all_message_List : []
  );

  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice = useSelector((state) =>
    state?.allapi?.get_invoice ? state?.allapi?.get_invoice : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = order_list?.docs ? order_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getSellerTicketList(page));
    } else {
      dispatch(allapiAction.getSellerTicketList(page));
    }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.getusercertifitedelete({ _id: e, page: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
    // dispatch(allapiAction.getuserOrderDetails({ _id: route?.query?.id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const [roledata, setroledata] = useState({ subject: "", message: "" });

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setroledata({ ...roledata, [name]: value });
  };
  const hendleSubmitReturn = (e) => {
    if (!roledata?.subject) {
      toast.error("please enter subject");
    } else if (!roledata?.message) {
      toast.error("please enter message");
    } else {
      const data = new FormData();
      data.append("subject", roledata?.subject);
      data.append("message", roledata?.message);

      dispatch(
        allapiAction.createSellerTickets({
          subject: roledata?.subject,
          message: roledata?.message,
        })
      );
      // setShow(false);
      // setLoading(false);
      setstatusDataBoxReturn(false);
    }
    // dispatch(
    //   allapiAction.cancelOrderByAdmin({
    //     _id: statusUpTrId?._id,
    //     user_id: statusUpTrId?.user_id?._id,
    //     description: statusUpTrStatus,
    //     page: page,
    //   })
    // );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = parseInt(order_details?.delivery_charges);
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd =
    (order_details.sub_total * order_details?.gst) / 100;

  const totalPrice = order_details?.product_price; // Total price inclusive of GST
  const gstRate = order_details?.gst; // GST rate (5%)

  const basePrice = totalPrice / (1 + gstRate / 100);

  console.log("Base Price (Net Amount): $" + basePrice.toFixed(2));

  const basePriceTotal = basePrice * order_details?.itme_count;

  const perProgst = totalPrice - basePrice;
  console.log(perProgst?.toFixed(2));
  console.log(basePriceTotal?.toFixed(2));

  console.log(order_details);
  const totalgst = order_details?.sub_total - basePriceTotal;
  console.log(totalgst?.toFixed(2));

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount = order_details?.sub_total + shippingCharge;
  // const totalAmount =
  //   order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    // dispatch(
    //   allapiAction.addFoundByAdmin({
    //     id: userData?._id,
    //     amount: statusData?.amount,

    //     pageNumber: page,
    //   })
    // );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.getusercertifitedelete({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },

    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      className: "table_action_col",
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "status",
    //   className: "table_action_col",
    // },

    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.status}
            </span>
          </>
        );
      },
    },

    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?._id)}
              >
                Delete
              </button>
            </Tooltip> */}
            <Tooltip title="Conversation">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Conversation
              </button>
            </Tooltip>
            {/* <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/sale?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/accounts/lost-and-found?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };
  const [enterMessage, setenterMessage] = useState("");

  const createMessage = () => {
    // createMessage

    try {
      const data = new FormData();

      if (!enterMessage) {
        toast.error("please enter message");
      } else {
        dispatch(
          allapiAction.createMessage({
            ticket_id: productID?._id,
            message: enterMessage,
          })
        );

        setenterMessage("");
      }
    } catch (error) {}
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      createMessage();
    }
  };
  return (
    <div>
      <Modal
        title="Delete Certificate"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Conversation"
        centered
        open={statusDataBox}
        // onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*-begin::Card header */}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*-begin::Title */}
            <div className="card-title">
              {/*-begin::User */}
              <div className="d-flex justify-content-center flex-column me-3">
                <h4>{productID?.subject}</h4>
                {/* <a
                      href="#"
                      className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1"
                    >
                    
                    </a> */}
                {/*-begin::Info */}
                {/* <div className="mb-0 lh-1">
                      <span className="badge badge-success badge-circle w-10px h-10px me-1"></span>
                      <span className="fs-7 fw-semibold text-muted">
                        Active
                      </span>
                    </div> */}
                {/*-end::Info */}
              </div>
              {/*-end::User */}
            </div>
            {/*-end::Title */}
            {/*-begin::Card toolbar */}

            {/*-end::Card toolbar */}
          </div>
          {/*-end::Card header */}
          {/*-begin::Card body */}

          <div className="modal-content">
            {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
            <div className="modal-body minHeCate">
              <div className="row">
                {all_message_List &&
                  all_message_List?.map((data, i) => {
                    const userId = "auth?._id";

                    return userId !== data?.user_id?._id ? (
                      <div
                        className="col-md-12 col-sm-12 col-xs-12 mb-md-0 mb-sm-5"
                        key={i}
                      >
                        <h5 className="title-detail">
                          {data?.message}
                          {/* <a
                           
                                className="text-heading"
                              >
                                {"productData?.title"}
                              </a> */}
                        </h5>

                        <div className="font-xs ">
                          <ul>
                            <li className="mb-5">
                              Admin
                              {/* <span className="text-brand">
                                {"productData?.tegs"}
                              </span> */}
                            </li>
                            {/* <li className="mb-5">
                              Category:
                              <span className="text-brand">
                                {" "}
                                {"productData?.categoryName"}
                              </span>
                            </li>
                            <li className="mb-5">
                              Status:
                              <span className="text-brand">
                                {" "}
                                {"productData?.status"}
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-12 col-sm-12 col-xs-12 text-end">
                        <div className="detail-info pr-30 pl-30">
                          <h5 className="title-detail">{data?.message}</h5>

                          <div className="font-xs ">
                            <ul>
                              <li className="mb-5">
                                You
                                {data?.readm == "0" ? (
                                  <img
                                    src="/assets/imgs/1.jpg"
                                    alt=""
                                    style={{ width: "20px" }}
                                  />
                                ) : (
                                  <img
                                    src="/assets/imgs/2.jpg"
                                    alt=""
                                    style={{ width: "20px" }}
                                  />
                                )}
                                {/* <span className="text-brand">
                                {"productData?.tegs"}
                              </span> */}
                              </li>
                              {/* <li className="mb-5">
                              Category:
                              <span className="text-brand">
                                {" "}
                                {"productData?.categoryName"}
                              </span>
                            </li>
                            <li className="mb-5">
                              Status:
                              <span className="text-brand">
                                {" "}
                                {"productData?.status"}
                              </span>
                            </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/*-end::Card body */}
          {/*-begin::Card footer */}
          <div className="d-flex mt-3">
            {/*-begin::Input */}
            <input
              value={enterMessage}
              //   className="form-control  mb-3"
              className="form-control"
              rows={1}
              data-kt-element="input"
              placeholder="Type a message"
              onKeyDown={onEnterPress}
              onChange={(e) => {
                setenterMessage(e.target.value);
              }}
            />
            {/*-end::Input */}
            {/*-begin:Toolbar */}
            <div className="d-flex flex-stack">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  createMessage();
                }}
                data-kt-element="send"
              >
                Send
              </button>
              {/*-end::Send */}
            </div>
            {/*-end::Toolbar */}
          </div>
          {/*-end::Card footer */}
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            {/* <h6>Name</h6>
                    <p>{roleID?.name}</p> */}
            {/* <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div> */}
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            {/* {order_details?.orderproductsList &&
              order_details?.orderproductsList?.map((data, i) => {
                return ( */}
            <div>
              <div className="row">
                <div className="col-3">
                  <h6>sub order id</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sub_order_id}</p>
                </div>
                <div className="col-3">
                  <h6>name</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.product_names}</p>
                </div>
                <div className="col-3">
                  <h6>Color</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.colorId?.color}</p>
                </div>
                <div className="col-3">
                  <h6>size</h6>
                </div>
                <div className="col-3">
                  <p>{order_details?.sizeId?.size}</p>
                </div>

                <div className="col-3">
                  <h6>product price</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.product_price}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>item Count</h6>
                </div>
                <div className="col-3">
                  <p>x {order_details?.itme_count}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>sub total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {order_details?.sub_total}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p></p>
                </div>
                <div className="col-3">
                  <h6>Shipping</h6>
                </div>
                <div className="col-3">
                  <p>₹ {shippingCharge}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Gst</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalSubtotalPricegstdd}</p>
                </div>
                <div className="col-3">
                  <h6></h6>
                </div>
                <div className="col-3">
                  <p> </p>
                </div>
                <div className="col-3">
                  <h6>Grant total</h6>
                </div>
                <div className="col-3">
                  <p> ₹ {totalAmount}</p>
                </div>
                <div className="col-3">
                  <h6>feture image</h6>
                </div>
                <div className="col-3">
                  <div style={{ width: "100px" }}>
                    <img
                      src={
                        URL.API_BASE_URL + order_details?.colorId?.featureImage
                      }
                      alt=""
                      style={{ width: "100%" }}
                    />
                  </div>
                  {/* <p>  {data?.sub_total}</p> */}
                </div>
              </div>

              <hr />
            </div>
            {/* );
              })} */}

            {/* <h6>product Image</h6> */}
            {/* <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div> */}
          </div>
        </div>
      </Modal>
      <Modal
        title="Support"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Subject</Col>
            <Col span={24}>
              <input
                name="subject"
                className="form-control"
                placeholder="Enter Subject"
                type="text"
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </Col>
            <Col span={24}>Message</Col>
            <Col span={24}>
              <textarea
                name="message"
                className="form-control"
                placeholder="Enter Message"
                type="text"
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Support Ticket"}
            subHeading={`Total ${order_list?.totalDocs || 0} Support Ticket`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => {
                hendleToViewReturn();
              }}
            >
              Raise a Request
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: order_list?.totalDocs ? order_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default MyIncomeComponent;
