import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate, useParams } from "react-router-dom";

function JobApply() {
  const [resumedata, setresumedata] = useState({});
  const [userResumeAlready, setuserResumeAlready] = useState(false);
  const navigate = useNavigate();
  // console.log(resumedata);
  const dispatch = useDispatch();

  const { id } = useParams();
  const get_resume_user = useSelector((state) =>
    state?.allapi?.get_resume_user ? state?.allapi?.get_resume_user : {}
  );

  console.log(get_resume_user);
  useEffect(() => {
    if (get_resume_user?.name) {
      setuserResumeAlready(true);
      setresumedata(get_resume_user);
    }

    return () => {};
  }, [get_resume_user]);

  useEffect(() => {
    dispatch(allapiAction.getuserResume({}));

    return () => {};
  }, [id]);

  const hendleTochange = (e) => {
    if (userResumeAlready) {
      toast?.error("you already completed your resume");
    } else {
      setresumedata({ ...resumedata, [e.target.name]: e.target.value });
    }
  };

  const hendletosubmit = async (e) => {
    e?.preventDefault();

    if (userResumeAlready) {
      const responsejob = await dispatch(
        allapiAction.jobapplyNow({ job_id: id })
      );

      if (responsejob?.success) {
        navigate("/");
      }/*  else {
        toast?.error(responsejob?.message);
      } */
    } else {
      if (!resumedata?.name) {
        toast?.error("please enter full name");
        return;
      }
      if (!resumedata?.email) {
        toast?.error("please enter email");
        return;
      }
      if (!resumedata?.phone) {
        toast?.error("please enter phone number");
        return;
      }
      if (!resumedata?.dob) {
        toast?.error("please select Date of birth");
        return;
      }
      if (!resumedata?.maritalStatus) {
        toast?.error("please select marital status");
        return;
      }
      if (!resumedata?.address) {
        toast?.error("please enter address");
        return;
      }
      if (!resumedata?.Skills) {
        toast?.error("please enter skills");
        return;
      }
      if (!resumedata?.education) {
        toast?.error("please enter education");
        return;
      }
      if (!resumedata?.language) {
        toast?.error("please enter language");
        return;
      }
      console.log("test");

      const response = await dispatch(allapiAction.createResume(resumedata));

      // createResume

      if (response?.success) {
        // navigate("/");

        const responsejob = await dispatch(
          allapiAction.jobapplyNow({ job_id: id })
        );

        if (responsejob?.success) {
          navigate("/");
        }
      }
    }
  };

  return (
    <div className="job-application">
      <div class="container">
        <h1>Job Application Form</h1>
        <form action="#" method="post">
          <section>
            <h2>Contact Information</h2>
            <label for="name">Full Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={resumedata?.name}
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            />

            <label for="email">Email:</label>
            <input
              type="email"
              id="email"
              value={resumedata?.email}
              name="email"
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            />

            <label for="phone">Phone Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={resumedata?.phone}
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            />

            <label for="dob">DOB:</label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={resumedata?.dob}
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            />
            <label for="dob">Marital Status:</label>
            {/* <input
              type="date"
              id="dob"
              name="maritalStatus"
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            /> */}

            <select
              name="maritalStatus"
              className="jobSelect"
              value={resumedata?.maritalStatus}
              onChange={(e) => {
                hendleTochange(e);
              }}
            >
              <option name="">Select Marital Status</option>
              <option name="single">Single</option>
              <option name="married">Married</option>
            </select>
            <label for="phone">Citizen ship:</label>
            <input
              type="text"
              id="citizenship"
              name="citizenship"
              value={resumedata?.citizenship}
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            />
            <label for="address">Address:</label>
            <input
              type="text"
              id="address"
              name="address"
              value={resumedata?.address}
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            />
          </section>

          <section>
            <h2>Resume Summary or Objective</h2>
            <label for="summary">Summary:</label>
            <textarea
              id="summary"
              name="summary"
              rows="4"
              value={resumedata?.summary}
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>
          </section>

          <section>
            <h2>Work Experience</h2>
            <label for="experience">Experience:</label>
            <textarea
              id="experience"
              name="workExperience"
              value={resumedata?.workExperience}
              rows="6"
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>
          </section>

          <section>
            <h2>Skills</h2>
            <label for="skills">Skills:</label>
            <textarea
              id="skills"
              name="Skills"
              value={resumedata?.Skills}
              rows="4"
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>
          </section>

          <section>
            <h2>Education</h2>
            <label for="education">Education:</label>
            <textarea
              id="education"
              name="education"
              value={resumedata?.education}
              rows="4"
              required
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>
          </section>

          <section>
            <h2>Optional Sections</h2>
            <label for="hobbies">Hobbies & Interests:</label>
            <textarea
              id="hobbies"
              name="hobbies"
              value={resumedata?.hobbies}
              rows="2"
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>

            <label for="languages">Languages:</label>
            <textarea
              id="languages"
              name="language"
              value={resumedata?.language}
              rows="2"
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>

            {/* <label for="certifications">Certifications:</label>
            <textarea
              id="certifications"
              name="certifications"
              rows="2"
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea> */}

            <label for="training">Training:</label>
            <textarea
              id="training"
              name="training"
              value={resumedata?.training}
              rows="2"
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>

            <label for="projects">Projects:</label>
            <textarea
              id="projects"
              name="projects"
              value={resumedata?.projects}
              rows="2"
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea>
            {/* 
            <label for="activities">Extracurricular Activities:</label>
            <textarea
              id="activities"
              name="activities"
              rows="2"
              onChange={(e) => {
                hendleTochange(e);
              }}
            ></textarea> */}
          </section>

          <button
            type="submit"
            onClick={(e) => {
              hendletosubmit(e);
            }}
          >
            Submit Application
          </button>
        </form>
      </div>
    </div>
  );
}

export default JobApply;
