import React, { useEffect, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";

function LoaderComponent() {
  const [loader, setloader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 2000);
  }, []);

  return (
    <div>
      {" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <BounceLoader color="#fd9b57" />
      </div>
    </div>
  );
}

export default LoaderComponent;
