// import { URL } from "@/Redux/common/url";
import { Modal } from "antd";
// import { useRouter } from "next/router";
import React, { useState } from "react";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { URL } from "../../Redux/common/url";

function Video2({ homedata }) {
  // const route = useRouter();
  const get_home_all_pro = homedata
 
  const recentlisting = get_home_all_pro?.videoslist
    ? get_home_all_pro?.videoslist
    : [];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.videoslist?.length == 1
        ? 1
        : get_home_all_pro?.videoslist?.length > 6
          ? 6
          : get_home_all_pro?.videoslist?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.videoslist?.length == 1
              ? 1
              : get_home_all_pro?.videoslist?.length > 4
                ? 4
                : get_home_all_pro?.videoslist?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.videoslist?.length == 1
              ? 1
              : get_home_all_pro?.videoslist?.length > 3
                ? 3
                : get_home_all_pro?.videoslist?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.videoslist?.length == 1
              ? 1
              : get_home_all_pro?.videoslist?.length > 1
                ? 1
                : get_home_all_pro?.videoslist?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videodetal, setvideodetal] = useState({});

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleView = (e) => {
    setIsModalOpen(true);
    setvideodetal(e);
  };
  return (
    <div>
      <Modal
        title="Video"
        open={isModalOpen}
        onOk={handleCancel}
        onCancel={handleCancel}
        width={800}
      >
        <iframe
          width="260"
          height="280"
          src={videodetal?.link}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal>
      <div className="ps-product-list ps-clothings">
        <div className="ps-container">
          <div className="ps-section__header">
            <h3>Video</h3>
          </div>

          <div className="container-fluid mt-10">
            {recentlisting?.length > 0 && (
              <Slider {...settings}>
                {recentlisting &&
                  recentlisting?.map((data, i) => {
                    return (
                      <div className=" videoBox" key={i}>
                        <div className="card ">
                          <CoustomImg
                            url={
                              data?.thumbnail
                                ? URL.API_BASE_URL + data?.thumbnail
                                : ""
                            }
                            altkey={data?.title}
                            w1={165}
                            w2={215}
                            h1={80}
                            h2={130}
                          />

                          <div className="ps-product__container">
                            <div className="ps-product__content">
                              <TruncateText text={data?.name} maxLength={25} />
                            </div>
                            <div className="ps-product__content hover">
                              <TruncateText
                                text={data?.description}
                                maxLength={25}
                              />
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video2;
