import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Seo(props) {
  const { title, settitle } = props;
  const { titleSeo, settitleSeo } = props;
  const { seoKeyword, setseoKeyword } = props;
  const { seoconcal, setseoconcal } = props;
  const { seo_descriptions, setseo_descriptions } = props;

  // console.log(titleSeo);
  // console.log(seoKeyword);
  // console.log(seo_descriptions);

  return (
    <div className="application">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {title + " - Devi Mart"}</title>
          <link rel="canonical" href={seoconcal} />
          <meta name="description" content={seo_descriptions} />
          <meta name="keywords" content={seoKeyword} />
          <meta name="title" content={titleSeo} />
          <meta property="og:title" content={titleSeo} />
          <meta property="og:description" content={seo_descriptions} />
        </Helmet>
      </HelmetProvider>
    </div>
  );
}

export default Seo;
