import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useParams } from "react-router-dom";
import TruncateText from "../TruncateText/TruncateText";
import HomeSlider from "../Home/HomeSlider";
import CategorySlider from "../Home/CategorySlider";

function SubsubCategoryProductandCategory() {
  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_seller_product_list_public_by_cate
      ? state?.allapi?.get_seller_product_list_public_by_cate
      : {}
  );
  const [childcateee, setchildcateee] = useState("");
  console.log(get_category_Filter);
  const { id } = useParams();
  const dispatch = useDispatch();
  const henldetofilterbyCate = (e) => {
    // if (search) {
    //   dispatch(
    //     allapiAction.userSearchProduct({ page: pageno, id: search, cateId: e })
    //   );
    // }
  };

  useEffect(() => {
    dispatch(allapiAction.getsubcategryListbymsubCategory({ id: id }));
    dispatch(allapiAction.getsliderList({ page: "subsubCategoryPage" }));
    dispatch(
      allapiAction.getsellerProductListbysubcategoryNew({ page: 1, id: id })
    );

    return () => {};
  }, []);

  const [windowWidth, setWindowWidth] = useState(600);

  // Update window width when the window is resized
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      {" "}
      <div class="row p-7 ps-layout--shop ">
        <div class="col-md-3 mobile_hide">
          <div class="ps-layout__left">
            <aside class="widget widget_shop">
              <h4 class="widget-title">Categories</h4>
              <ul class="ps-list--categories">
                {get_category_Filter &&
                  get_category_Filter?.map((data, i) => {
                    return (
                      <li class="menu-item-has-children" key={i}>
                        <a href="#">{data?.name}</a>
                        <span
                          class={
                            childcateee == data?._id
                              ? "sub-toggle active "
                              : "sub-toggle"
                          }
                          onClick={() => {
                            setchildcateee(data?._id);
                          }}
                        >
                          <i class="fa fa-angle-down"></i>
                        </span>
                        <ul
                          class="sub-menu"
                          style={
                            childcateee == data?._id
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {data?.subcates &&
                            data?.subcates?.map((datachild, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      henldetofilterbyCate(datachild?._id);
                                    }}
                                  >
                                    {datachild?.name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </aside>
          </div>
        </div>
        <div class="col-md-9 col-12">
          <div id="other-data">
            <div class="row p-10">
              <div class="col-md-12 col-12 p-8">
                <div className="hometest">
                  <CategorySlider />
                </div>
              </div>
              <div class="col-md-12 col-12 shop_by_cat mb-10">
                <h3 class="text-center text-white p-4">
                  <b>Tob global brands</b>
                </h3>
              </div>

              {get_category_Filter &&
                get_category_Filter?.map((data, i) => {
                  return (
                    i < 6 && (
                      <div class="col-2 col-md-2 mb-4 mt-2" key={i}>
                        <Link
                          to={"/category-product/" + data?.cate_sulg}
                          className="all_categoryimagetest"
                        >
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt="Category 1"
                            class="all_category-image"
                          />
                        </Link>
                        <h6 class="text-center mt-5">
                          <b>{data?.name}</b>
                        </h6>
                      </div>
                    )
                  );
                })}
              <div class="col-md-12 col-12 p-8"></div>

              {get_product_list?.docs &&
                get_product_list?.docs?.map((data, i) => {
                  return (
                    i < 8 && (
                      <div class="col-md-3 col-4 p-8" key={i}>
                        <div class="card subcategcard_back">
                          <Link to={"/product/" + data?.pro_sulg}>
                            <img
                              src={URL?.API_BASE_URL + data?.featureImage}
                              class="sub_cat_product_image"
                            />
                          </Link>
                          <h6 class="text-center bg-white">
                            {" "}
                            <TruncateText
                              text={data?.title}
                              maxLength={windowWidth > 600 ? 25 : 15}
                            />
                          </h6>
                        </div>
                      </div>
                    )
                  );
                })}

              <div class="col-md-12 col-12 p-8"></div>
              {get_category_Filter &&
                get_category_Filter?.map((data, i) => {
                  return (
                    i > 5 &&
                    i < 12 && (
                      <div class="col-2 col-md-2 mb-4 mt-2" key={i}>
                        <Link
                          to={"/category-product/" + data?.cate_sulg}
                          className="all_categoryimagetest"
                        >
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt="Category 1"
                            class="all_category-image"
                          />
                        </Link>
                        <h6 class="text-center mt-5">
                          <b>{data?.name}</b>
                        </h6>
                      </div>
                    )
                  );
                })}
              <div class="col-md-12 col-12 p-8"></div>
              {get_product_list?.docs &&
                get_product_list?.docs?.map((data, i) => {
                  return (
                    i > 7 && (
                      <div class="col-md-3 col-4 p-8">
                        <div class="card subcategcard_back">
                          <Link to={"/product/" + data?.pro_sulg}>
                            <img
                              src={URL?.API_BASE_URL + data?.featureImage}
                              class="sub_cat_product_image"
                            />
                          </Link>
                          <h6 class="text-center bg-white">
                            {" "}
                            <TruncateText
                              text={data?.title}
                              maxLength={windowWidth > 600 ? 25 : 15}
                            />
                          </h6>
                        </div>
                      </div>
                    )
                  );
                })}

              {/* <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://freepngimg.com/thumb/chanel/67498-fashion-leather-bag-black-handbag-chanel.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.freeiconspng.com/uploads/video-camera-png-3.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://img-prd-pim.poorvika.com/product/oppo-reno-10-pro-5g-Glossy-purple-256gb-12gb-ram-front-and-back-view.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>
              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngkey.com/png/full/228-2288633_imac-desktop-png-apple-desktop-computer-png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngmart.com/files/10/Rolls-Royce-PNG-Transparent-Image.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngkey.com/png/full/874-8744318_perfume-png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngarts.com/files/3/Rolls-Royce-PNG-Transparent-Image.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>
              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://png.monster/wp-content/uploads/2023/09/PNG.monsteriphone-15-plus-pro-pro-max-green%20png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://freepngimg.com/thumb/chanel/67498-fashion-leather-bag-black-handbag-chanel.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.freeiconspng.com/uploads/video-camera-png-3.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://img-prd-pim.poorvika.com/product/oppo-reno-10-pro-5g-Glossy-purple-256gb-12gb-ram-front-and-back-view.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>
              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngkey.com/png/full/228-2288633_imac-desktop-png-apple-desktop-computer-png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngmart.com/files/10/Rolls-Royce-PNG-Transparent-Image.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngkey.com/png/full/874-8744318_perfume-png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngarts.com/files/3/Rolls-Royce-PNG-Transparent-Image.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>
              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://png.monster/wp-content/uploads/2023/09/PNG.monsteriphone-15-plus-pro-pro-max-green%20png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://freepngimg.com/thumb/chanel/67498-fashion-leather-bag-black-handbag-chanel.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.freeiconspng.com/uploads/video-camera-png-3.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://img-prd-pim.poorvika.com/product/oppo-reno-10-pro-5g-Glossy-purple-256gb-12gb-ram-front-and-back-view.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>
              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngkey.com/png/full/228-2288633_imac-desktop-png-apple-desktop-computer-png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngmart.com/files/10/Rolls-Royce-PNG-Transparent-Image.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngkey.com/png/full/874-8744318_perfume-png.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div>

              <div class="col-md-3 col-4 p-8">
                <div class="card subcategcard_back">
                  <a href="/mySubCategoryProduct">
                    <img
                      src="https://www.pngarts.com/files/3/Rolls-Royce-PNG-Transparent-Image.png"
                      class="sub_cat_product_image"
                    />
                  </a>
                  <h6 class="text-center bg-white">Up to 50% off</h6>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubsubCategoryProductandCategory;
