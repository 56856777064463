// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Layout } from "antd";
const { useBreakpoint } = Grid;
function OurWork2() {
  const screens = useBreakpoint();
  const [sliderhide, setsliderhide] = useState(false);
  const navigate = useNavigate();
  console.log(screens);
  console.log(sliderhide);

  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const [valuesetUserDeatils, setUserDeatils] = useState({});

  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));

      const userDetails = localStorage.getItem("access_user");

      setUserDeatils(JSON?.parse(userDetails));
    } else {
      setValue("");
      setValuevendor("");
      setUserDeatils({});
    }
  }, []);

  useEffect(() => {
    setsliderhide(!screens.sm);
    return () => {};
  }, [screens]);

  const dispatch = useDispatch();
  const get_our_work_list = useSelector((state) =>
    state?.allapi?.get_our_work_list ? state?.allapi?.get_our_work_list : {}
  );

  const ourWorklist = get_our_work_list?.sliders
    ? get_our_work_list?.sliders
    : [];

  useEffect(() => {
    dispatch(allapiAction.getourwork({ page: "home" }));
  }, []);

  const get_home_all_pro = [
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
    {
      url: "/all-lost-and-found",
      img: "/img/lost.webp",
      title: "Lost & Found",
    },
    { url: "/job-portal", img: "/img/job.webp", title: "Job Portal" },
    {
      url: "/all-message",
      img: "/img/message.webp",
      title: "Lost & Found",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 4
          ? 6
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container-fluid">
      <div className="mt-1 ">
        {/* <button type="button" class="btn btn-dark1 ">
          Lost And Found
        </button>
        <button type="button" class="btn btn-dark1 ml-3">
          Search Lost And Found
        </button> */}
        {/* to={value || valuevendor ? "/add-lost-found" : "/login"} */}
        {/* <button
          type="button"
          onClick={() =>
            navigate(value || valuevendor ? "/add-lost-found" : "/login")
          }
          class="btn btn-dark1 ml-3"
        >
          Add Lost And Found
        </button>
        <button
          type="button"
          onClick={() => navigate("/all-seller")}
          class="btn btn-dark1 ml-3"
        >
          Vendors
        </button>
        <button type="button" class="btn btn-dark1 ml-3">
          Top 10
        </button> */}
        {/* <button type="button" class="btn btn-dark1 ml-3">
          Top 100
        </button> */}
        {/* <button type="button" class="btn btn-dark1 ml-3">
          Property Market
        </button> */}
      </div>

      {/* <div class="Input2__wrapper Input2__container">
        <div
          class="Input2__box Input2__base"
          style={{
            height: "40px",
            borderBottomWidth: "initial",
            borderStyle: "none",
          }}
        >
          <input
            type="search"
            autocomplete="off"
            autocorrect="off"
            id="search-text-input"
            placeholder=""
            class="Input2__inputBox Input2__hidePlaceholder"
            data-test="control-input-field"
            aria-label=" Search for Products, Brands, Categories and more..."
            value=""
          />
        </div>
      </div> */}
      <div className="text-center mt-5">
        <div className="ps-block__left mb-5">
          {/* <h3>
            <b>OUR WORK</b>
          </h3> */}
        </div>

        {sliderhide ? (
          <div className="row lostBy">
            <div className="col-4">
              <div class="">
                <div className="our_work_card">
                  <Link
                    className="ps-block__overlay wkim"
                    to={"/all-lost-and-found"}
                  >
                    <img src={"/img/lost.webp"} alt={"Lost & Found"} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class=" ">
                <div className="our_work_card">
                  <Link className="ps-block__overlay wkim" to={"/job-portal"}>
                    <img src={"/img/job.webp"} alt={"Job Portal"} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class=" ">
                <div className="our_work_card">
                  <Link className="ps-block__overlay wkim" to={"/all-message"}>
                    <img src={"/img/message.webp"} alt={"Lost & Found"} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {get_home_all_pro?.length > 0 && (
              <Slider {...settings}>
                {get_home_all_pro?.map((data, i) => {
                  return (
                    <div class="p-5" key={i}>
                      <div className="our_work_card">
                        <Link className="ps-block__overlay wkim" to={data?.url}>
                          <img src={data?.img} alt={data?.title} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        )}
        {/* <div className="row">
          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-4 col-4 mb-2">
            <div className="our_work_card">
              <Link className="ps-block__overlay" to="/all-lost-and-found">
                <img src={"/img/lost.webp"} alt={"data?.title"} />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-4 col-4 mb-2">
            <div className="our_work_card">
              <Link className="ps-block__overlay" to="/job-portal">
                <img src={"/img/jobportal.jpeg"} alt={"data?.title"} />
              </Link>
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-3 col-sm-4 col-4 mb-2">
            <div className="our_work_card">
              <Link className="ps-block__overlay" to="/all-message">
                <img src={"/img/message.webp"} alt={"data?.title"} />
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default OurWork2;
