import React, { useEffect } from "react";
import { allapiAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";

function Weddingprofile() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const details = useSelector((state) =>
    state?.allapi?.get_marriage_details
      ? state?.allapi?.get_marriage_details
      : {}
  );
  console.log(details);

  let dob = new Date(details?.dob);
  let today = new Date();
  let age = Math.floor((today - dob) / (365.25 * 24 * 60 * 60 * 1000));

  useEffect(() => {
    if (id) {
      dispatch(allapiAction?.Marriagedetaials({ slug: id }));
    }
    return () => {};
  }, [id]);
  return (
    <div class="wedding_porfiledetail">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <h3>Conubia Nostra</h3>
            <small>H1234567 | Sed Dignissim Lacinia Nunc</small>
            <br />
            <br />
            <div class="card mb-30">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img
                    class="img-fluid"
                    src={URL?.API_BASE_URL + details?.image}
                    alt="..."
                  />
                  {/* <!-- small crausal are here --> */}

                  <p class="text-muted text-center pt-2">
                    Last Login : 1 hour ago
                  </p>
                </div>
                <div class="col-md-6">
                  <div class="card-body">
                    <p class="card-text p-0 m-0 on2">
                      <span class="text-muted">Age:</span> {age} Yrs,{" "}
                      {details?.height}
                    </p>
                    <p class="card-text p-0 m-0 mt-2 on2">
                      <span class="text-muted">Religion:</span>{" "}
                      {details?.religion}
                    </p>
                    <p class="card-text p-0 m-0 mt-2 on2">
                      <span class="text-muted">Caste:</span> {details?.caste}
                    </p>
                    <p class="card-text p-0 m-0 mt-2 on2">
                      <span class="text-muted">Location:</span>
                      {details?.city},{details?.district}
                    </p>
                    <p class="card-text p-0 m-0 mt-2 on2">
                      <span class="text-muted">Eduction:</span>{" "}
                      {details?.education}
                    </p>
                    <p class="card-text p-0 m-0 mt-2 on2">
                      <span class="text-muted">Profession:</span>{" "}
                      {details?.occupation}
                    </p>
                  </div>
                </div>
                <div class="col-md-2 p-0 pt-2">
                  <center>
                    <img
                      class="img-fluid"
                      width="40"
                      src="https://png.pngtree.com/png-clipart/20230805/original/pngtree-smartphone-flat-orange-color-rounded-vector-icon-mobile-sms-vector-vector-picture-image_9721252.png"
                      alt=""
                    />
                    <img
                      class="img-fluid ml-2"
                      width="40"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt81TtjHJVxszAhKak624eEOgAoMmvXtAKew&s"
                      alt=""
                    />
                  </center>
                </div>
              </div>
            </div>

            <div class="row bg-white m-0 pb-30">
              <div class="col-md-12">
                <h3 class="text-success font-weight-bold p-2 on-2">
                  Personal Information
                </h3>
                <hr />
                <i class="fa fa-pinterest font-large text-success con"></i>
                &nbsp;&nbsp;<h4 class="d-inline on-3">About my daughter</h4>
                <p class="mt-3 on-4">
                  My daughter is a Manager with a Master's degree currently
                  working in Private sector in Gurgaon. We come from a Middle
                  class, Nuclear family background with Traditional values.
                </p>
                <br />
                <i class="fa fa-user font-large text-success con"></i>
                &nbsp;&nbsp;<h4 class="d-inline on-3">Basic Details</h4>
                {/* <!-- table is here --> */}
                <div class="table-responsive mt-30">
                  <table class="table">
                    <tbody>
                      <tr class="m-10 p-10">
                        <td>Name</td>
                        <td>{details?.firstName + " " + details?.lastName}</td>
                        <td>Body Type</td>
                        <td>
                          {details?.builtStructure
                            ? details?.builtStructure
                            : "--"}
                        </td>
                      </tr>
                      <tr class="m-10 p-10">
                        <td>Age</td>
                        <td>{age} Yrs</td>
                        <td>Complexion</td>
                        <td>
                          {details?.complexion ? details?.complexion : "--"}
                        </td>
                      </tr>
                      <tr class="m-10 p-10">
                        <td>Height</td>
                        <td>{details?.height ? details?.height : "--"} </td>
                        <td>Physical Status</td>
                        <td>Fair</td>
                      </tr>
                      <tr class="m-10 p-0">
                        <td>Weight</td>
                        <td>
                          {details?.bodyWeight
                            ? details?.bodyWeight + "kg"
                            : "--"}
                        </td>
                        <td>Eating Habits</td>
                        <td>
                          {details?.yourEatingHabits
                            ? details?.yourEatingHabits
                            : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Mother Tongue</td>
                        <td>{details?.language ? details?.language : "--"}</td>
                        <td>Drinking Habits</td>
                        <td>
                          {details?.takeHardDrinks
                            ? details?.takeHardDrinks
                            : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Marital Status</td>
                        <td>
                          {details?.maritalStatus
                            ? details?.maritalStatus
                            : "--"}
                        </td>
                        <td>Smoking Habits</td>
                        <td>{details?.smoke ? details?.smoke : "--"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <i class="fa fa-mobile font-large text-success con"></i>
                &nbsp;&nbsp;<h4 class="d-inline on-3">Contact Details</h4>
                {/* <!-- table is here --> */}
                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Contact Number</td>
                        <td>: 7734984798</td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Parent Contact</td>
                        <td>: Available</td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Chat Status</td>
                        <td>: Online</td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Send Mail</td>
                        <td>: Online</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <!-- 3rd table  --> */}
                <i class="fa fa-book font-large text-success con"></i>
                &nbsp;&nbsp;<h4 class="d-inline on-3">Religion Information</h4>
                {/* <!-- table is here --> */}
                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Religion</td>
                        <td>
                          : {details?.religion ? details?.religion : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Caste / Sub Caste</td>
                        <td>
                          : {details?.caste ? details?.caste : "--"} /{" "}
                          {details?.subCaste ? details?.subCaste : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Gothra</td>
                        <td>: {details?.gothra ? details?.gothra : "--"}</td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Star / Raasi</td>
                        <td>
                          : {details?.nakshatra ? details?.nakshatra : "--"} /{" "}
                          {details?.Raasi ? details?.Raasi : "--"}
                        </td>
                      </tr>
                      {/* <tr class="m-0 p-0">
                        <td>Dosh</td>
                        <td>: Not Specified</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                {/* 
<!-- 4th table is here --> */}
                <i class="fa fa-map font-large text-success con"></i>
                &nbsp;&nbsp;
                <h4 class="d-inline on-3">
                  {" "}
                  {details?.gander == "male" ? "Groom's" : "Bride's"} Location
                </h4>
                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Country</td>
                        <td>: {details?.country ? details?.country : "--"}</td>
                        <td>: Citizenship</td>
                        <td>: {details?.country ? details?.country : "--"}</td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>State</td>
                        <td>: {details?.state ? details?.state : "--"}</td>
                        <td>: City</td>
                        <td>: {details?.city ? details?.city : "--"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <!-- 5th table is here --> */}
                <i class="fa fa-user font-large text-success con"></i>
                &nbsp;&nbsp;
                <h4 class="d-inline on-3">Professional Information</h4>
                {/* <!-- table is here --> */}
                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Education</td>
                        <td>
                          : {details?.education ? details?.education : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Education in Detail</td>
                        <td>
                          :
                          {details?.educationalArea
                            ? details?.educationalArea
                            : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Employed in</td>
                        <td>
                          :{" "}
                          {details?.occupationType
                            ? details?.occupationType
                            : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Occupation</td>
                        <td>
                          : {details?.occupation ? details?.occupation : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Occupation in Detail</td>
                        <td>
                          :{" "}
                          {details?.occupationCategory
                            ? details?.occupationCategory
                            : "--"}
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Annual Income</td>
                        <td>
                          : Rs.{" "}
                          {details?.annualIncome ? details?.annualIncome : "--"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* 
<!-- 6th table is here --> */}
                <i class="fa fa-users font-large text-success con"></i>
                &nbsp;&nbsp;<h4 class="d-inline on-3">Family Details</h4>
                {/* <!-- table is here --> */}
                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>Family Type</td>
                        <td>Not Specified | Request</td>
                      </tr>
                      <tr>
                        <td>Family Status</td>
                        <td>Not Specified | Request</td>
                      </tr>
                      <tr>
                        <td>Fatder's Status</td>
                        <td>Not Specified | Request</td>
                      </tr>
                      <tr>
                        <td>Family Location</td>
                        <td>Not Specified | Request</td>
                      </tr>
                      <tr>
                        <td>About our family</td>
                        <td>Not Specified | Request</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <center class="border rounded-pill p-2 bg-warning">
                    <h5 class="on-5">
                      To view the member's Family Details, add yours
                    </h5>
                    <a class="on-5" href="">
                      Click here to add your Family Details
                    </a>
                  </center>
                </div>
              </div>
            </div>

            {/* <!-- 3rd row is here --> */}

            <div class="row bg-white mt-30 m-0">
              <div class="col-md-12">
                <h3 class="text-success font-weight-bold p-2 on-2">
                  Her Partner Preferences
                </h3>
                <hr />
                <div class="row">
                  <div class="col-md-2">
                    <center>
                      <img
                        class="img-fluid rounded-pill"
                        width="75"
                        src="https://w0.peakpx.com/wallpaper/587/312/HD-wallpaper-indian-girl-beautiful-eyes-hair-insta-lips-model-smile-thumbnail.jpg"
                        alt=""
                      />
                    </center>
                  </div>
                  <div class="col-md-1 p-0 text-center">
                    <span style={{ fontSize: "40px", color: "gray" }}>
                      .....
                    </span>
                  </div>

                  <div class="col-md-6 text-center">
                    <br />
                    <small class="d-inline-block mt-1">
                      {" "}
                      Your profile matches 14 / 20 of Nancy's preferences
                    </small>
                  </div>
                  <div class="col-md-1 p-0 text-center">
                    <span style={{ fontSize: "40px", color: "gray" }}>
                      .....
                    </span>
                  </div>
                  <div class="col-md-2">
                    <center>
                      <img
                        class="img-fluid rounded-pill"
                        width="75"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvVA2OROSeJvwdsN9uVhXctxcjUbSJPCAS2A&s"
                        alt=""
                      />
                    </center>
                  </div>
                </div>
                <br />
                <h4 class="d-inline">Basic Preferences</h4>

                {/* <!-- table is here --> */}

                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Groom's Age</td>
                        <td>: 27 - 32 Yrs</td>
                        <td></td>
                        <td class="dot-1">
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Height</td>
                        <td>: 5 Ft 2 In - 6 Ft 2 In / 157 Cms - 188 Cms</td>
                        <td></td>
                        <td class="dot-1">
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Marital Status</td>
                        <td>: Never married</td>
                        <td></td>
                        <td class="dot-1">
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Mother Tongue</td>
                        <td>
                          : Awadhi, Bhojpuri, Brij, Bihari, Chatisgarhi...
                        </td>
                        <td></td>
                        <td class="dot-1">
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Physical Status</td>
                        <td>: Normal</td>
                        <td></td>
                        <td class="dot-1">
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Eating Habits</td>
                        <td>: Doesn't matter</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Smoking Habits</td>
                        <td>: Prefer someone who never smokes</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Drinking Habits</td>
                        <td>: Prefer someone who never drinks</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <h4 class="d-inline">Religious Preferences</h4>

                {/* <!-- table is here --> */}

                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Religion</td>
                        <td>: Hindu</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Caste</td>
                        <td>: Nai / Nayi Brahmin, Nhavi, Bajantri, Nabit</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Gothram</td>
                        <td>: All (Except my gothra)</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Star</td>
                        <td>: Any Star</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Manglik</td>
                        <td>: No</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br />

                {/* <!-- 4th table is here --> */}

                <h4 class="d-inline">Professional</h4>

                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Education</td>
                        <td>: Any Bachelors in Engineering / Computers...</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Occupation</td>
                        <td>: Any Occupation</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Annual Income</td>
                        <td>: Any Annual Income</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4 class="d-inline">Location Preferences</h4>

                <div class="table-responsive mt-3">
                  <table class="table">
                    <tbody>
                      <tr class="m-0 p-0">
                        <td>Country</td>
                        <td>: Mauris </td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                      <tr class="m-0 p-0">
                        <td>Residing State</td>
                        <td>: Any Residing State</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Residing City</td>
                        <td>: Any Residing City</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>

                      <tr class="m-0 p-0">
                        <td>Citizenship</td>
                        <td>: Any Citizenship</td>
                        <td></td>
                        <td>
                          .....
                          <i class="fa fa-check-circle text-success con"></i>
                          .....
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 mt-2">
            <br />
            <br />
            <br />
            <div class="row bg-white mt-2 pt-4 border rounded">
              <div class="col-md-12">
                <h5 class="view">View Similar Profiles</h5>
                <br />
                {/* <!-- card is here --> */}
                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://w0.peakpx.com/wallpaper/324/967/HD-wallpaper-beauty-girl-in-saree.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- 2nd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i3.wp.com/w0.peakpx.com/wallpaper/745/297/HD-wallpaper-indian-saree.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
<!-- 3rd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://e0.pxfuel.com/wallpapers/555/420/desktop-wallpaper-savita-bhabhi.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
<!-- 4th --> */}

                <div class="card mb-3">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i.pinimg.com/736x/ed/be/55/edbe55217c43ae56d8d86cd0937c9c92.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 p-0 pl-3">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- paste here --> */}
              </div>
            </div>

            {/* <!-- 2nd cards row --> */}

            <div class="row bg-white mt-2 pt-4 border rounded">
              <div class="col-md-12">
                <h5 class="view">View Similar Profiles</h5>
                <br />
                {/* <!-- card is here --> */}
                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://qph.cf2.quoracdn.net/main-qimg-a513ce31018ffb57bc50a91ca4c65881"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- 2nd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i.pinimg.com/564x/97/3b/43/973b430336f756c20d214c03831ecfd7.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- 3rd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://w0.peakpx.com/wallpaper/587/885/HD-wallpaper-nipaa-pani-bong-rocks-saree-lover.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- 4th --> */}

                <div class="card mb-3">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://w0.peakpx.com/wallpaper/681/375/HD-wallpaper-nipaa-pani-bong-rocks-saree-lover.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 p-0 pl-3">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- paste here --> */}
              </div>
            </div>

            {/* <!-- 3rd card's row --> */}

            <div class="row bg-white mt-2 pt-4 border rounded">
              <div class="col-md-12">
                <h5>View Similar Profiles</h5>
                <br />
                {/* <!-- card is here --> */}
                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i.pinimg.com/736x/15/f5/42/15f542e5245fd910b9779ca513dab271.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- 2nd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://www.bhmpics.com/downloads/santali-girl-Wallpapers/45.fb_img_16115404582545030.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- 3rd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i.pinimg.com/564x/ac/55/47/ac5547f0bd276cbbad2fb5aa6cf8cf34.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- 4th --> */}

                <div class="card mb-3">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i.pinimg.com/736x/2d/0d/1c/2d0d1c7759e322d959dd595fa8dda931.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 p-0 pl-3">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- paste here --> */}
              </div>
            </div>

            {/* <!-- 4th cards row --> */}

            <div class="row bg-white mt-2 pt-4 border rounded">
              <div class="col-md-12">
                <h5>View Similar Profiles</h5>
                <br />
                {/* <!-- card is here --> */}
                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://www.bhmpics.com/downloads/santali-girl-Wallpapers/45.fb_img_16115404582545030.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- 2nd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://i.pinimg.com/564x/97/3b/43/973b430336f756c20d214c03831ecfd7.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 p-0 pl-3">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
<!-- 3rd --> */}

                <div class="card mb-1">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://e0.pxfuel.com/wallpapers/555/420/desktop-wallpaper-savita-bhabhi.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 p-0 pl-3">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- 4th --> */}

                <div class="card mb-3">
                  <div class="row no-gutters">
                    <div class="col-md-3">
                      <img
                        class="img-fluid text-center"
                        src="https://w0.peakpx.com/wallpaper/324/967/HD-wallpaper-beauty-girl-in-saree.jpg"
                        alt="..."
                      />
                    </div>
                    <div class="col-md-9 pl-3 p-0">
                      <div class="card-body p-0">
                        <p class="text-primary d-inline">
                          <small>H1234567</small>
                        </p>
                        <br />
                        <small class="card-text count">
                          30 Yrs, 5 Ft 3 In,
                        </small>
                        <br />
                        <small class="card-text count">
                          Hindu, Brahmin - ...,
                        </small>
                        <br />
                        <small class="card-text count">Sirmaur</small>
                        <br />
                        <p class="text-primary count">
                          <small>View Full Profile</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- paste here --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weddingprofile;
