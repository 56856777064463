import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";
// import { useRouter } from "next/router";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DeleteToken, DeleteToken2 } from "../../utils";

const TopBar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    DeleteToken2();
    navigate("/login");
  };
  return (
    <div className="flex justify-content-between align-items-center">
      <div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
      </div>
      <div className="mr-20">
        <span className="logout-btn" onClick={() => handleLogout()}>
          Logout
          <LogoutOutlined />
        </span>
      </div>
    </div>
  );
};

export default TopBar;
