import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
// import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
// import SvgIcon from "../const/SvgIcon";
// import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
// import { URL } from "../../Redux/common/url";
import HeadingBack from "../../HeadingBack";
// import { useRouter } from "next/router";

// import { URL } from "@/Redux/common/url";
// import { allapiAction } from "@/Redux/common/action";
// import Image from "next/image";
// import { allapiAction } from "@/Redux/common/action";
import SvgIcon from "../../const/SvgIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { allapiAction } from "../../../Redux/common/action";

function JobScreenComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  // const pagev = navigate?.query?.page;
  // const searchData = navigate?.query?.search;

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_user_apply_list_job
      ? state?.allapi?.get_user_apply_list_job
      : {}
  );
  const get_expire_date = useSelector((state) =>
    state?.allapi?.get_expire_date ? state?.allapi?.get_expire_date : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    // if (search) {
    //   dispatch(allapiAction.getJobsapplyedListVendor({ page: page, key: search }));
    // } else {
    dispatch(allapiAction.getJobsapplyedListVendor(page));
    //   dispatch(allapiAction.getJobsapplyedListVendor(page));
    // }

    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
      },
      onCancel() {},
    });
  };

  const hendleDelete = (e) => {
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.createPurchase({
        packageId: userData?._id,
        packageName: userData?.name,
        amount: userData?.amount,
        transationId: "free",

        // pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: userId,
    //     status: userId,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    // dispatch(
    //   allapiAction.user_inactive({
    //     _id: id,
    //     status: status,
    //     // active: 0,
    //     pageNumber: page,
    //   })
    // );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    // dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Job title",
      dataIndex: "job_title",
      key: "job_title",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.job_id?.job_title}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },

    {
      title: "Job Type",
      dataIndex: "job_type",
      key: "job_type",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.job_id?.job_type}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Experience requerd",
      dataIndex: "job_type",
      key: "job_type",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.job_id?.job_experience}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "company_name",
      key: "company_name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.resumeId?.name}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.job_id?.company_name}

              {/* <p> {item?.user_id?.referId}</p> */}
            </span>
          </>
        );
      },
    },

    {
      title: "Applied Date",
      dataIndex: "createdAt",
      key: "createdAt",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Invoice">
                <sapn class="btn btn-outline-success square-btn btn-sm mr-1">
                  Invoice
                </sapn>
              </Tooltip> */}

            <Tooltip title="Details">
              <span
                className="cursor-pointer btn btn-outline-primary mr-5"
                onClick={() => hendleToViewM(item)}
              >
                Details
              </span>
            </Tooltip>

            {/* <Tooltip title="View">
                <span
                  className="cursor-pointer btn btn-outline-success"
                  // onClick={() => hendleToViewM(item)}
                  onClick={() => hendleToViewProduct(item)}
                >
                  <img
                    src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                    class="svg"
                    alt=""
                  />
                </span>
              </Tooltip> */}
            {/* <Tooltip title="View">
                <sapn
                  class="btn btn-outline--primary square-btn btn-sm mr-1"
                  onClick={() => hendleToViewProduct(item)}
                >
                  <img
                    src="https://ganeshji.online/public/assets/back-end/img/eye.svg"
                    class="svg"
                    alt=""
                  />
                </sapn>
              </Tooltip> */}
            {/* <Tooltip title="Return Order"></Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/seller/product?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    navigate("/seller/product?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Details"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div class="bg-hero-zig-zag jobb">
          <main class="font-jost hyphens-manual">
            <div class="max-w-3xl p-3 mx-auto my-auto bg-gray-100 border-4 border-gray-700 print:border-0 page print:max-w-letter print:max-h-letter print:mx-0 print:my-o lg:h-letter md:max-w-letter md:h-letter xsm:p-8 sm:p-9 md:p-16 lg:mt-6 rounded-2xl print:bg-white">
              <header class="inline-flex items-baseline justify-between w-full mb-3 align-top border-b-8">
                <div class="block">
                  <h1 class="mb-0 text-5xl font-bold text-gray-750">
                    {userData?.resumeId?.name}
                  </h1>

                  {/* <h2 class="m-0 ml-2 text-2xl font-semibold text-gray-700 leading-snugish">
                    Front End Developer
                  </h2> */}

                  <h3 class="m-0 mt-2 ml-2 mb-2 font-semibold text-md text-gray-550 leading-snugish">
                    {userData?.resumeId?.address}
                  </h3>
                </div>
              </header>

              <div class="col-gap-16 md:col-count-2 print:col-count-2 md:h-letter-col-full print:h-letter-col-full col-fill-balance">
                <section class="pb-4 mt-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <section class="pb-2 mb-2 border-b-2 break-inside-avoid">
                      <ul class="list-inside pr-7">
                        <li class="mt-1 leading-normal transition duration-100 ease-in text-gray-550 text-md hover:text-gray-700">
                          <a
                            href={"mailto:" + userData?.resumeId?.email}
                            class="group"
                          >
                            <span class="mr-8 text-xl font-semibold text-gray-700 leading-snugish">
                              Email:
                            </span>
                            {userData?.resumeId?.email}
                            <span class="inline-block font-normal transition duration-100 ease-in text-gray-550 print:text-black group-hover:text-gray-700">
                              ↗
                            </span>
                          </a>
                        </li>
                        <li class="mt-1 leading-normal transition duration-100 ease-in text-gray-550 text-md hover:text-gray-700">
                          <a href={"tel:" + userData?.resumeId?.phone}>
                            <span class="mr-5 text-xl font-semibold text-gray-700 leading-snugish">
                              Phone:
                            </span>
                            {userData?.resumeId?.phone}
                          </a>
                        </li>
                        <li class="mt-1 leading-normal transition duration-100 ease-in text-gray-550 text-md hover:text-gray-700">
                          <span class="mr-5 text-xl font-semibold text-gray-700 leading-snugish">
                            Dob:
                          </span>
                          {userData?.resumeId?.dob}
                        </li>
                      </ul>
                    </section>
                  </div>
                </section>

                <section class="pb-2 pb-4 mt-4 border-b-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      SUMMARY
                    </h2>

                    <section class="mb-2 break-inside-avoid">
                      <p class="mt-2 leading-normal text-gray-700 text-md">
                        {userData?.resumeId?.summary
                          ? userData?.resumeId?.summary
                          : "--"}
                      </p>
                    </section>
                  </div>
                </section>

                <section class="pb-2 pb-4 mt-4 border-b-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      EXPERIENCE
                    </h2>

                    <section class="mb-2 break-inside-avoid">
                      <p>
                        {" "}
                        {userData?.resumeId?.workExperience
                          ? userData?.resumeId?.workExperience
                          : "--"}
                      </p>
                      {/* <header>
                        <h3 class="text-xl font-semibold text-gray-650 leading-snugish">
                          Front End Developer ( 2 Years )
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          April 2021 – April 2023 | ABC Technologies
                        </p>
                      </header>
                      <ul class="mt-2.1 text-md text-gray-700 leading-normal">
                        <li>
                          <span class="transform -translate-y-px select-none text-gray-550">
                            ›
                          </span>
                          Create Responsive Site With W3C Standards.
                        </li>

                        <li>
                          <span class="transform -translate-y-px select-none text-gray-550">
                            ›
                          </span>
                          Crafted literate, Comprehensive Source Code.
                        </li>
                        <li>
                          <span class="transform -translate-y-px select-none text-gray-550">
                            ›
                          </span>
                          Provided commentary to improve maintainability
                        </li>
                        <li>
                          <span class="transform -translate-y-px select-none text-gray-550">
                            ›
                          </span>
                          Add Site Base Animation Using Keyframe and SVG
                          Animation
                        </li>
                      </ul> */}
                    </section>
                  </div>
                </section>

                <section class="pb-2 pb-4 mt-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      Languages
                    </h2>

                    <section class="pb-4 mt-4 mb-4 border-b-2 break-inside-avoid">
                      {userData?.resumeId?.language
                        ? userData?.resumeId?.language
                        : "--"}
                      {/* <header>
                        <h3 class="flex-grow text-xl font-semibold text-gray-700 leading-snugish">
                          University Of India
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          2017 – 2020 | Master Of Computer Application
                        </p>
                      </header>
                      <p class="mt-1 leading-normal text-md text-gray-650">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          Major:
                        </span>
                        Computer Science
                      </p>
                      <p class="leading-normal text-gray-700 text-md">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          CGPA:
                        </span>
                        5.9
                      </p> */}
                    </section>
                    {/* 
                    <section class="pb-4 mt-4 mb-4 border-b-2 break-inside-avoid">
                      <header>
                        <h3 class="flex-grow text-xl font-semibold text-gray-700 leading-snugish">
                          College For BCA
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          2014 – 2017 | Bechlor of Computer Applications
                        </p>
                      </header>
                      <p class="mt-1 leading-normal text-md text-gray-650">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          Major:
                        </span>
                        Computer Science
                      </p>
                      <p class="mt-1 leading-normal text-md text-gray-650"></p>
                      <p class="leading-normal text-gray-700 text-md">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          CGPA:
                        </span>
                        6.09
                      </p>
                    </section> */}
                  </div>
                </section>

                <section class="pb-2 pb-4 mt-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      EDUCATION
                    </h2>

                    <section class="pb-4 mt-4 mb-4 border-b-2 break-inside-avoid">
                      {userData?.resumeId?.education
                        ? userData?.resumeId?.education
                        : "--"}
                      {/* <header>
                        <h3 class="flex-grow text-xl font-semibold text-gray-700 leading-snugish">
                          University Of India
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          2017 – 2020 | Master Of Computer Application
                        </p>
                      </header>
                      <p class="mt-1 leading-normal text-md text-gray-650">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          Major:
                        </span>
                        Computer Science
                      </p>
                      <p class="leading-normal text-gray-700 text-md">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          CGPA:
                        </span>
                        5.9
                      </p> */}
                    </section>
                    {/* 
                    <section class="pb-4 mt-4 mb-4 border-b-2 break-inside-avoid">
                      <header>
                        <h3 class="flex-grow text-xl font-semibold text-gray-700 leading-snugish">
                          College For BCA
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          2014 – 2017 | Bechlor of Computer Applications
                        </p>
                      </header>
                      <p class="mt-1 leading-normal text-md text-gray-650">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          Major:
                        </span>
                        Computer Science
                      </p>
                      <p class="mt-1 leading-normal text-md text-gray-650"></p>
                      <p class="leading-normal text-gray-700 text-md">
                        <span class="font-semibold text-gray-700 text-md leading-snugish">
                          CGPA:
                        </span>
                        6.09
                      </p>
                    </section> */}
                  </div>
                </section>

                <section class="pb-2 pb-4 mt-4 border-b-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      PROJECTS
                    </h2>

                    <section class="pb-4 mb-4 border-b-2 break-inside-avoid">
                      {/* <header>
                        <h3 class="text-xl font-semibold text-gray-700 leading-snugish">
                          <a href="#!" class="group">
                            Shipper (Transportation App)
                          </a>
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          2017 | Android, Java, HTML, CSS, PHP
                        </p>
                      </header> */}
                      <p class="mt-2.1 text-md text-gray-700 leading-normal">
                        {userData?.resumeId?.projects
                          ? userData?.resumeId?.projects
                          : "--"}
                      </p>
                    </section>
                  </div>
                </section>
                <section class="pb-2 pb-4 mt-4 border-b-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      Training
                    </h2>

                    <section class="pb-4 mb-4 border-b-2 break-inside-avoid">
                      {/* <header>
                        <h3 class="text-xl font-semibold text-gray-700 leading-snugish">
                          <a href="#!" class="group">
                            Shipper (Transportation App)
                          </a>
                        </h3>
                        <p class="leading-normal text-md text-gray-550">
                          2017 | Android, Java, HTML, CSS, PHP
                        </p>
                      </header> */}
                      <p class="mt-2.1 text-md text-gray-700 leading-normal">
                        {userData?.resumeId?.training
                          ? userData?.resumeId?.training
                          : "--"}
                      </p>
                    </section>
                  </div>
                </section>

                <section class="pb-4 mt-4 first:mt-0">
                  <div class="break-inside-avoid">
                    <h2 class="mb-2 text-xl font-bold tracking-widest text-gray-700 print:font-normal">
                      SKILLS
                    </h2>

                    <section class="mb-2 break-inside-avoid">
                      <p>
                        {" "}
                        {userData?.resumeId?.Skills
                          ? userData?.resumeId?.Skills
                          : "--"}
                      </p>

                      {/* <header>
                        <h3 class="text-xl font-semibold text-gray-700 leading-snugish">
                          Front-End Developer
                        </h3>
                      </header> */}

                      {/* <div class="my-1 last:pb-1">
                        <ul class="flex flex-wrap text-sm2 leading-relaxed -mr-1.6 -mb-1 mt-2.1">
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            HTML5
                          </li>
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            CSS3
                          </li>
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            SCSS
                          </li>

                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            Tailwind.css
                          </li>
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            Javascript
                          </li>
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            PHP
                          </li>
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            JQuery
                          </li>
                          <li class="text-md px-2.5 py-0.5 mr-1.6 mb-1 text-gray-200 leading-relaxed print:bg-white print:border-inset bg-gray-250 mb-3">
                            BOOTSTRAP
                          </li>
                        </ul>
                      </div> */}
                    </section>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Applied Jobs"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Applied Jobs`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span
              className="jop_btn"
              onClick={() => navigate("/seller/product/add-product")}
            >
              Add
          
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />
      </div>
    </div>
  );
}

export default JobScreenComponent;
