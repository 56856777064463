let year = new Date()?.getFullYear();
export const SHIFT_TIME_CONST = 19;
export const NO_PERMISSION_MESSAGE =
  "You don't have permission to access this.Please contact your administrator.";
export const MONTH_DATA = [
  { label: "Jan", value: 1, year: year },
  { label: "Feb", value: 2, year: year },
  { label: "Mar", value: 3, year: year },
  { label: "Apr", value: 4, year: year },
  { label: "May", value: 5, year: year },
  { label: "Jun", value: 6, year: year },
  { label: "Jul", value: 7, year: year },
  { label: "Aug", value: 8, year: year },
  { label: "Sep", value: 9, year: year },
  { label: "Oct", value: 10, year: year },
  { label: "Nov", value: 11, year: year },
  { label: "Dec", value: 12, year: year },
];
export const OPEN_KEY_DATA = [
  { openKey: [], selected: ["dashboard"], path: "seller" },
  { openKey: [], selected: ["dashboard"], path: "accounts" },
  { openKey: [], selected: ["order"], path: "accounts/order" },
  { openKey: [], selected: ["support-ticket"], path: "accounts/support-ticket" },
  { openKey: [], selected: ["setting"], path: "seller/setting" },
  { openKey: ["sale-all"], selected: ["sale"], path: "seller/sale" },
  { openKey: ["sale-all"], selected: ["pending"], path: "seller/sale/pending" },
  {
    openKey: ["sale-all"],
    selected: ["delivered"],
    path: "seller/sale/delivered",
  },
  {
    openKey: ["sale-all"],
    selected: ["returned"],
    path: "seller/sale/returned",
  },
  {
    openKey: ["sale-all"],
    selected: ["canceled"],
    path: "seller/sale/canceled",
  },
  {
    openKey: ["sale-all"],
    selected: ["confirmed"],
    path: "seller/sale/confirmed",
  },
  {
    openKey: ["sale-all"],
    selected: ["processing"],
    path: "seller/sale/processing",
  },
  {
    openKey: ["sale-all"],
    selected: ["out-for-delivery"],
    path: "seller/sale/out-for-delivery",
  },
  { openKey: [], selected: ["listing"], path: "seller/listing" },
  { openKey: [], selected: ["my-income"], path: "seller/my-income" },
  { openKey: [], selected: ["my-income"], path: "accounts/my-income" },
  { openKey: [], selected: ["product"], path: "seller/product" },
  { openKey: [], selected: ["withdrawal"], path: "seller/withdrawal" },
  { openKey: [], selected: ["package"], path: "seller/package" },
  { openKey: [], selected: ["lost-and-found"], path: "accounts/lost-and-found" },
  { openKey: [], selected: ["certificates"], path: "accounts/certificates" },
  { openKey: [], selected: ["bankinformation"], path: "seller/bankinformation" },
  { openKey: [], selected: ["direct-team"], path: "accounts/direct-team" },
  { openKey: [], selected: ["total-team"], path: "accounts/total-team" },
];
