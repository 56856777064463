// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";

function ShoppingAddress() {
  const router = useNavigate();

  const dispatch = useDispatch();
  const [addresData, setaddresData] = useState({});
  const addresshendle = (e) => {
    const { name, value } = e.target;
    setaddresData({ ...addresData, [name]: value });
  };

  const createAddressHendle = (e) => {
    // createAddress

    console.log(addresData);

    if (!addresData?.name) {
      toast.error("name required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (!addresData?.email) {
      toast.error("email required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (!addresData?.phone) {
      toast.error("phone required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (addresData?.phone?.length > 10) {
      toast.error("number invalid", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (!addresData?.city) {
      toast.error("city required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (!addresData?.state) {
      toast.error("state required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (!addresData?.pincode) {
      toast.error("pincode required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else if (!addresData?.address) {
      toast.error("Address required", {
        position: "top-right",
        autoClose: 300,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
        // theme: "light",
      });
    } else {
      dispatch(allapiAction.createAddress(addresData, router));
      return () => {};
    }
  };
  const get_address = useSelector((state) =>
    state?.allapi?.get_address ? state?.allapi?.get_address : []
  );

  console.log(get_address);

  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const loading_checkOut = useSelector((state) =>
    state?.allapi?.loading_checkOut ? state?.allapi?.loading_checkOut : false
  );

  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_profile ? state?.allapi?.get_profile : {}
  );
  console.log(get_all_cart_itme);
  console.log(get_user_profile);
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalshipping = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.delivery_charges;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);

  const shippingCharge = totalSubtotalshipping;

  const totalAmount =
    totalSubtotalPrice + shippingCharge + totalSubtotalPricegst;

  useEffect(() => {
    // getUserAddress

    dispatch(allapiAction.getUserAddress({}));
    return () => {};
  }, []);

  const selectAddress = (e) => {
    localStorage.setItem("order_Address", JSON.stringify(e));
    router("/checkout");
  };

  const [showinputaddress, setshowinputaddress] = useState(false);
  return (
    <div>
      <div className="ps-checkout ps-section--shopping">
        <div className="container-fluid">
          <div className="ps-section__header2">
            <h1>Shopping Address</h1>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <div className="row mb-50">
                {get_address &&
                  get_address?.map((data, i) => {
                    return (
                      <div class="col-lg-6 mt-3">
                        <div class="saved-add active">
                          <div class="address active ml-4">
                            <strong>Name: </strong>
                            {data?.name}
                            <br />
                            <br />
                            <address>
                              <span>
                                <strong>Address: </strong> {data?.address},
                                {data?.city}
                                {data?.state}
                              </span>
                              {/* <span> DIVYA STU, </span>Jaipur, Rajasthan, 302013 IN */}
                              <br />
                              <br />
                            </address>
                            <strong>Phone: </strong>
                            {data?.phone}
                            <br />
                            <br />
                            <strong>Email: </strong>
                            {data?.email}
                          </div>
                          <br />
                          {/* <label class="check-del ml-4">
                        <input type="checkbox" name="" value="" />
                        <span></span>Check this box to deliver on above address
                      </label> */}

                          <div class="saved-btn ml-1 d-flex justify-content-around mb-3">
                            {/* <button class="save-del">DELETE</button> */}
                            <button
                              class="save-edit"
                              onClick={() => {
                                selectAddress(data?._id);
                              }}
                            >
                              Select address
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {!showinputaddress && (
                  <div class="col-lg-6 mt-3">
                    <div
                      class="ds add-add"
                      onClick={() => {
                        setshowinputaddress(true);
                      }}
                    >
                      <div class="add-new-lbl mt-5 ">
                        CLICK TO ADD A SHIPPING ADDRESS
                      </div>
                      <img
                        src="  https://asort.com/resources/checkout/add-1.0.png"
                        class="add-add-img"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="ps-section__content">
                <form
                  className="ps-form--checkout"
                  action="https://nouthemes.net/html/martfury/do_action"
                  method="post"
                >
                  <div className="row">
                    {showinputaddress && (
                      <div className="col-xl-7 col-lg-8 col-md-12 col-sm-12  ">
                        <div className="ps-form__billing-info">
                          <h3 className="ps-form__heading">Billing Details</h3>
                          <div className="form-group">
                            <label>
                              Name<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              Email Address<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                name="email"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              Phone<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                                name="phone"
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                                        <label>Email Address<sup>*</sup>
                                        </label>
                                        <div className="form-group__content">
                                            <input className="form-control" type="email"   />
                                        </div>
                                    </div> */}
                          <div className="form-group">
                            <label>
                              City<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              State<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                name="state"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              Pincode<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                name="pincode"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group">
                            <label>
                              Address<sup>*</sup>
                            </label>
                            <div className="form-group__content">
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                onChange={(e) => {
                                  addresshendle(e);
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className="form-group">
                                        <div className="ps-checkbox">
                                            <input className="form-control" type="checkbox" id="create-account" />
                                            <label for="create-account">Create an account?</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="ps-checkbox">
                                            <input className="form-control" type="checkbox" id="cb01" />
                                            <label for="cb01">Ship to a different address?</label>
                                        </div>
                                    </div>
                                    <h3 className="mt-40"> Addition information</h3>
                                    <div className="form-group">
                                        <label>Order Notes</label>
                                        <div className="form-group__content">
                                            <textarea className="form-control" rows="7" placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                                        </div>
                                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12  ">
                <div className="ps-form__total">
                  <h3 className="ps-form__heading">Your Order</h3>
                  <div className="content">
                    <div className="ps-block--checkout-total">
                      <div className="ps-block__header">
                        <p>Product</p>
                        <p>Total</p>
                      </div>
                      <div className="ps-block__content">
                        <table className="table ps-block__products">
                          <tbody>
                            {get_all_cart_itme &&
                              get_all_cart_itme?.map((data, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <a href="#">
                                        {" "}
                                        {data?.title} × {data?.item}
                                      </a>
                                      {/* <p>
                                          Sold By:<strong>YOUNG SHOP</strong>
                                        </p> */}
                                    </td>
                                    <td>₹ {data?.subtotalprice}</td>
                                  </tr>
                                );
                              })}

                            {/* <tr>
                                <td>
                                  <a href="#">
                                    {" "}
                                    Apple Macbook Retina Display 12” × 1
                                  </a>
                                  <p>
                                    Sold By:<strong>ROBERT’S STORE</strong>
                                  </p>
                                </td>
                                <td>$625.50</td>
                              </tr> */}
                          </tbody>
                        </table>
                        {/* <h4 className="ps-block__title">
                            Subtotal <span>$863.49</span>
                          </h4>
                          <div className="ps-block__shippings">
                            <figure>
                              <h4>YOUNG SHOP Shipping</h4>
                              <p>Free shipping</p>
                              <a href="#">
                                {" "}
                                MVMTH Classical Leather Watch In Black ×1
                              </a>
                            </figure>
                            <figure>
                              <h4>ROBERT’S STORE Shipping</h4>
                              <p>Free shipping</p>
                              <a href="#">
                                Apple Macbook Retina Display 12” ×1
                              </a>
                            </figure>
                          </div>
                          <h3>
                            Total <span>$683.49</span>
                          </h3> */}
                      </div>
                    </div>
                    <a
                      className="ps-btn ps-btn--fullwidth"
                      href="#"
                      onClick={() => {
                        createAddressHendle();
                      }}
                    >
                      Proceed to checkout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingAddress;
