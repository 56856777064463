// import { allapiAction } from "@/Redux/common/action";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import OurWork from "../OurWork/OurWork";
import { Link } from "react-router-dom";
// import OurWork from "../../Component/OurWork/OurWork";

function EducationPage() {
  const dispatch = useDispatch();

  const [cate, setcate] = useState("all");
  const [exams, setexams] = useState([]);

  const get_All_edu_Category = useSelector((state) =>
    state?.allapi?.get_All_edu_Category
      ? state?.allapi?.get_All_edu_Category
      : {}
  );
  const get_All_edu_List = useSelector((state) =>
    state?.allapi?.get_All_edu_List ? state?.allapi?.get_All_edu_List : []
  );

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );
  console.log(get_marques_List);
  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  console.log(cate);
  console.log(exams);

  useEffect(() => {
    if (cate == "all") {
      setexams(get_All_edu_List);
    } else {
      const filterdata =
        get_All_edu_List &&
        get_All_edu_List?.filter((data) => {
          return cate == data?.categoryId;
        });
      console.log(filterdata);
      setexams(filterdata);
    }
  }, [get_All_edu_List, cate]);

  useEffect(() => {
    dispatch(allapiAction.geteducatetttcate());
    dispatch(allapiAction.geteducatListpub());
    dispatch(allapiAction.getMarqueesList2({ page: "education" }));
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, []);

  return (
    <div className="onlineexam">
      <div className="marquee">
        <marquee behavior="scroll" direction="left" scrollamount="05">
          {news &&
            news?.map((data, i) => {
              return <>{data?.title}</>;
            })}
        </marquee>
      </div>

      <h2
        className="Achievers font font-weight-bolder mt-5 text-center"
        style={{ fontSize: "xx-large" }}
      >
        ALL EXAMS
      </h2>
      <section className="author-archive">
        <div className="container-fluid">
          <input type="radio" id="All" name="categories" value="All" checked />
          <input type="radio" id="SSC" name="categories" value="SSC" />
          <input type="radio" id="BANK" name="categories" value="BANK" />
          <input type="radio" id="RAILWAY" name="categories" value="RAILWAY" />
          <input type="radio" id="POLICE" name="categories" value="POLICE" />
          <input type="radio" id="TEACH" name="categories" value="TEACH" />
          <input type="radio" id="DEFENCE" name="categories" value="DEFENCE" />
          <input type="radio" id="ESIC" name="categories" value="ESIC" />
          <input type="radio" id="STATE" name="categories" value="STATE" />
          <input
            type="radio"
            id="STATEPSC"
            name="categories"
            value="STATEPSC"
          />
          <input type="radio" id="UPSC" name="categories" value="UPSC" />
          <input type="radio" id="OTHER" name="categories" value="OTHER" />

          <ol className="filters edu" id="style-1">
            <li>
              <label
                className={cate == "all" ? "lbsele" : ""}
                for="All"
                onClick={() => {
                  setcate("all");
                }}
              >
                All EXAMS
              </label>
            </li>
            {get_All_edu_Category &&
              get_All_edu_Category?.map((data, i) => {
                return (
                  <li key={i}>
                    <label
                      className={cate == data?._id ? "lbsele" : ""}
                      for={data?._id}
                      onClick={() => {
                        setcate(data?._id);
                      }}
                    >
                      {data?.name}
                    </label>
                  </li>
                );
              })}
          </ol>
          <ol className="posts">
            {exams &&
              exams?.map((data, i) => {
                return (
                  <li className="post" key={i}>
                    <figure className="mt-4">
                      <Link to={"/education-details/" + data?.slug}>
                        {data?.keyword}
                      </Link>
                    </figure>
                  </li>
                );
              })}
          </ol>
        </div>
      </section>
      <OurWork />
    </div>
  );
}

export default EducationPage;
