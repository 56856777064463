// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";

function SellerHeader({}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const router = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);

  const tokenhave = value || valuevendor ? true : false;

  const vendorDetails = useSelector((state) =>
    state.allapi.get_seller_details ? state.allapi.get_seller_details : {}
  );

  console.log(vendorDetails);
  const all_categories_List = useSelector((state) =>
    state?.allapi?.all_categories_List ? state?.allapi?.all_categories_List : []
  );
  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_user_profile ? state?.allapi?.get_user_profile : {}
  );
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);

  if (typeof window !== "undefined" && Object.keys(window).length > 0) {
    const blogids = localStorage.getItem("cart_product");

    var arr = JSON.parse(blogids && blogids);
    var arrss = tokenhave ? get_all_cart_itme && get_all_cart_itme : arr;
  }

  useEffect(() => {
    dispatch(allapiAction.getCategory({}));
    dispatch(allapiAction.getsellerprofile({ id: id }));
    return () => {};
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (tokenhave) {
        // getcartitme

        dispatch(
          allapiAction.getcartitme({
            data: arrss,
            // userid: get_user_profile?.id,
          })
        );
      } else {
        dispatch(
          allapiAction.getcartProduct({
            data: arrss,
            // userid: get_user_profile?.id,
          })
        );
      }
    }, 500);

    return () => {};
  }, [tokenhave]);

  useEffect(() => {
    if (tokenhave) {
      if (arr?.length > 0) {
        arr.forEach((file) => {
          dispatch(allapiAction.afterLoginAddCart(file));

          var lists = arr.filter((x) => {
            return x.product_id != file?.product_id;
          });

          if (lists?.length == 0) {
            localStorage.removeItem("cart_product");
          } else {
            localStorage.setItem("cart_product", JSON.stringify(lists));
          }

          return () => {};
        });
      }
    }
  }, [get_user_profile]);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const hendlesearchProduct = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      router(`/products?search=${e?.target?.value}`);
    }
  };
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };
  return (
    <div>
      <header
        className={
          isSticky ? "header header--1 header--sticky" : "header header--1"
        }
        data-sticky="true"
      >
        <div className="header__top">
          <div className="ps-container">
            <div className="header__left">
              <div className="menu--product-categories px-3">
                <div className="menu__toggle">
                  <i className="icon-menu"></i>
                  <span> Shop by Department</span>
                </div>
                <div className="menu__content">
                  <ul className="menu--dropdown">
                    {/* <li>
                      <a href="#.html">Hot Promotions</a>
                    </li> */}

                    {all_categories_List &&
                      all_categories_List?.map((data, i) => {
                        return (
                          <li
                            key={i}
                            className={
                              data?.subcates?.length > 0
                                ? "menu-item-has-children has-mega-menu"
                                : ""
                            }
                          >
                            <a href="#">{data?.name}</a>
                            <span className="sub-toggle"></span>
                            {data?.subcates?.length > 0 && (
                              <div className="mega-menu">
                                {data?.subcates &&
                                  data?.subcates?.map((datasub, index) => {
                                    return (
                                      <div
                                        className="mega-menu__column"
                                        key={index}
                                      >
                                        <h4>
                                          {datasub?.name}
                                          <span className="sub-toggle"></span>
                                        </h4>
                                        <ul className="mega-menu__list">
                                          {datasub?.subjectss &&
                                            datasub?.subjectss?.map(
                                              (datasubsub, indexss) => {
                                                return (
                                                  <li key={indexss}>
                                                    <a href="#.html">
                                                      {datasubsub?.name}
                                                    </a>
                                                  </li>
                                                );
                                              }
                                            )}
                                        </ul>
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <Link className="ps-logo" to="/">
                <span className="gradient-color-text">BestBharat</span>
              </Link>
            </div>
            <div className="header__center text-center">
              <h2 className="sllertitleh">
                {vendorDetails?.displayName
                  ? vendorDetails?.displayName
                  : vendorDetails?.fullName}
                {/* Creato */}
              </h2>
            </div>
            <div className="header__right">
              <div className="header__actions">
                <div className="ps-cart--mini">
                  <a className="header__extra" href="#">
                    <i className="icon-bag2"></i>
                    <span>
                      <i>
                        {get_all_cart_itme?.length > 0
                          ? get_all_cart_itme?.length
                          : 0}
                      </i>
                    </span>
                  </a>
                  <div className="ps-cart__content">
                    <div className="ps-cart__items">
                      {get_all_cart_itme &&
                        get_all_cart_itme?.map((data, i) => {
                          return (
                            <div className="ps-product--cart-mobile" key={i}>
                              <div className="ps-product__thumbnail">
                                <Link to={"/product/" + data?.pro_sulg}>
                                  <img
                                    src={URL?.API_BASE_URL + data?.featureImage}
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="ps-product__content">
                                <Link to={"/product/" + data?.pro_sulg}>
                                  {data?.title}
                                </Link>
                                <p></p>
                                <small>
                                  {data?.item} x ₹{data?.afterdiscountprice}
                                </small>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className="ps-cart__footer">
                      <h3>
                        Sub Total:
                        <strong>
                          ₹{totalSubtotalPrice > 0 ? totalSubtotalPrice : 0}
                        </strong>
                      </h3>
                      <figure>
                        <Link className="ps-btn" to="/cart">
                          View Cart
                        </Link>
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="ps-block--user-header">
                  <div className="ps-block__right">
                    {/* <a href={"tel:" + vendorDetails?.phone} class=" text-dark">
                      <i class="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                        >
                          <path d="M21 16.42V19.9561C21 20.4811 20.5941 20.9167 20.0705 20.9537C19.6331 20.9846 19.2763 21 19 21C10.1634 21 3 13.8366 3 5C3 4.72371 3.01545 4.36687 3.04635 3.9295C3.08337 3.40588 3.51894 3 4.04386 3H7.5801C7.83678 3 8.05176 3.19442 8.07753 3.4498C8.10067 3.67907 8.12218 3.86314 8.14207 4.00202C8.34435 5.41472 8.75753 6.75936 9.3487 8.00303C9.44359 8.20265 9.38171 8.44159 9.20185 8.57006L7.04355 10.1118C8.35752 13.1811 10.8189 15.6425 13.8882 16.9565L15.4271 14.8019C15.5572 14.6199 15.799 14.5573 16.001 14.6532C17.2446 15.2439 18.5891 15.6566 20.0016 15.8584C20.1396 15.8782 20.3225 15.8995 20.5502 15.9225C20.8056 15.9483 21 16.1633 21 16.42Z"></path>
                        </svg>
                      </i>{" "}
                      {vendorDetails?.phone}
                    </a>
                    <a
                      href={"mailto:" + vendorDetails?.email}
                      class="text-dark"
                    >
                      <i class="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="18"
                          height="18"
                        >
                          <path d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"></path>
                        </svg>
                      </i>{" "}
                      {vendorDetails?.email}
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navigation">
          <div className="ps-container">
            <div className="navigation__left">
              <div
                style={{
                  width: "50px",
                  marginLeft: "10px",
                  height: "45px",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <img
                  src={URL?.API_BASE_URL + vendorDetails?.sellershopLogo}
                  className="h-100 "
                />
              </div>
            </div>
            <div className="navigation__right">
              <ul className="menu">
                <li className="menu-item-has-children">
                  <Link to={"/vendor-store/" + vendorDetails?._id}>Home</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="#">About</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="#">Shop</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="#">News / Blogs</Link>
                </li>

                <li className="menu-item-has-children">
                  <Link to={"/vendor-store/product/" + vendorDetails?._id}>
                    Product
                  </Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to={"/vendor-store/listing/" + vendorDetails?._id}>
                    Listing
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <header
        className={
          isSticky
            ? "header header--mobile header--sticky"
            : "header header--mobile"
        }
        data-sticky="true"
      >
        <div className="header__top">
          <div className="header__left">
            <p>Welcome to Best Bharat Online Shopping Store !</p>
          </div>
          <div className="header__right"></div>
        </div>
        <div className="navigation--mobile">
          <div className="navigation__left">
            <Link
              className="ps-logo"
              to={"/vendor-store/" + vendorDetails?._id}
            >
              <img
                src={URL?.API_BASE_URL + vendorDetails?.sellershopLogo}
                className=""
                width={50}
              />
            </Link>
          </div>
          <div className="navigation__right">
            <div className="header__actions">
              <div
                className="ps-cart--mini"
                style={{ paddingTop: "15px", marginRight: "77px" }}
              >
                <h4 style={{ color: "blue" }}>
                  {" "}
                  <b>
                    {" "}
                    {vendorDetails?.displayName
                      ? vendorDetails?.displayName
                      : vendorDetails?.fullName}
                  </b>
                </h4>
                <div className="sidebar">
                  {!sidebarOpen ? (
                    <div
                      className="sidebar_icon"
                      onMouseEnter={handleSidebarOpen}
                      onClick={handleSidebarOpen}
                    >
                      <i className="fas fa-bars"></i>
                    </div>
                  ) : (
                    <>
                      <div
                        className="sidebar_icon s2"
                        onMouseLeave={handleSidebarClose}
                        onClick={handleSidebarClose}
                      >
                        <i className="fas fa-times"></i>
                      </div>
                      <div className="sidebar_items">
                        <div className="sidebar_item">
                          <Link
                            style={{
                              paddingTop: "5px",
                              marginRight: "5px",
                              color: "#d67656",
                            }}
                            to={"/vendor-store/" + vendorDetails?._id}
                          >
                          <b>

                            Home
                          </b>
                          </Link>
                        </div>
                        <div className="sidebar_item">
                          <Link
                            style={{
                              paddingTop: "5px",
                              marginRight: "5px",
                              color: "#d67656",
                            }}
                            to="#"
                          >
                          <b>

                            About
                          </b>

                            About
                          </Link>
                        </div>
                        <div className="sidebar_item">
                          <Link
                            style={{
                              paddingTop: "5px",
                              marginRight: "5px",
                              color: "#d67656",
                            }}
                            to="#"
                          >
                          <b>

                            Shop
                          </b>
                          </Link>
                        </div>
                        <div className="sidebar_item">
                          <Link
                            style={{
                              paddingTop: "5px",
                              marginRight: "5px",
                              color: "#d67656",
                            }}
                            to="#"
                          >
                          <b>

                            News / Blogs
                          </b>
                          </Link>
                        </div>
                        <div className="sidebar_item">
                          <Link
                            style={{
                              paddingTop: "15px",
                              marginRight: "5px",
                              color: "#d67656",
                            }}
                            to={"/vendor-store/product/" + vendorDetails?._id}
                          >
                          <b>

                            Product
                          </b>
                          </Link>
                        </div>
                        <div className="sidebar_item">
                          <Link
                            style={{
                              paddingTop: "15px",
                              marginRight: "5px",
                              color: "#d67656",
                            }}
                            to={"/vendor-store/listing/" + vendorDetails?._id}
                          >
                          <b>

                            Listing
                          </b>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* <br/> */}
                {/* <Link
                  style={{
                    paddingTop: "5px",
                    marginRight: "5px",
                    color: "#000000",
                  }}
                  to={"/vendor-store/" + vendorDetails?._id}
                >
                  Home
                </Link>
                <Link
                  style={{
                    paddingTop: "5px",
                    marginRight: "5px",
                    color: "#000000",
                  }}
                  to="#"
                >
                  About
                </Link> */}
                {/* <Link style={{paddingTop:"5px", marginRight:"5px"}} to="#">Shop</Link>
                <Link style={{paddingTop:"5px", marginRight:"5px"}} to="#">News / Blogs</Link> */}
                {/* <Link
                  style={{
                    paddingTop: "15px",
                    marginRight: "5px",
                    color: "#000000",
                  }}
                  to={"/vendor-store/product/" + vendorDetails?._id}
                >
                  Product
                </Link>
                <Link
                  style={{
                    paddingTop: "15px",
                    marginRight: "5px",
                    color: "#000000",
                  }}
                  to={"/vendor-store/listing/" + vendorDetails?._id}
                >
                  Listing
                </Link> */}

                {/* <a className="header__extra" href="#">
                  <i className="icon-bag2"></i>
                  <span>
                    <i>
                      {" "}
                      {get_all_cart_itme?.length > 0
                        ? get_all_cart_itme?.length
                        : 0}
                    </i>
                  </span>
                </a>
                <div className="ps-cart__content">
                  <div className="ps-cart__items">
                    {get_all_cart_itme &&
                      get_all_cart_itme?.map((data, i) => {
                        return (
                          <div className="ps-product--cart-mobile" key={i}>
                            <div className="ps-product__thumbnail">
                              <Link to={"/product/" + data?.pro_sulg}>
                                <img
                                  src={URL?.API_BASE_URL + data?.featureImage}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="ps-product__content">
                              <Link to={"/product/" + data?.pro_sulg}>
                                {data?.title}
                              </Link>
                              <p></p>
                              <small>
                                {data?.item} x ₹{data?.afterdiscountprice}
                              </small>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div className="ps-cart__footer">
                    <h3>
                      Sub Total: ₹
                      {totalSubtotalPrice > 0 ? totalSubtotalPrice : 0}
                    </h3>
                    <figure>
                      <Link className="ps-btn" to="/cart">
                        View Cart
                      </Link>
                    </figure>
                  </div>
                </div> */}
              </div>
              <div className="ps-block--user-header">
                <div className="ps-block__left">
                  <div>
                    {/* <div>
                      <a
                        href={"tel:" + vendorDetails?.phone}
                        class=" text-dark"
                      >
                        <i class="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path d="M21 16.42V19.9561C21 20.4811 20.5941 20.9167 20.0705 20.9537C19.6331 20.9846 19.2763 21 19 21C10.1634 21 3 13.8366 3 5C3 4.72371 3.01545 4.36687 3.04635 3.9295C3.08337 3.40588 3.51894 3 4.04386 3H7.5801C7.83678 3 8.05176 3.19442 8.07753 3.4498C8.10067 3.67907 8.12218 3.86314 8.14207 4.00202C8.34435 5.41472 8.75753 6.75936 9.3487 8.00303C9.44359 8.20265 9.38171 8.44159 9.20185 8.57006L7.04355 10.1118C8.35752 13.1811 10.8189 15.6425 13.8882 16.9565L15.4271 14.8019C15.5572 14.6199 15.799 14.5573 16.001 14.6532C17.2446 15.2439 18.5891 15.6566 20.0016 15.8584C20.1396 15.8782 20.3225 15.8995 20.5502 15.9225C20.8056 15.9483 21 16.1633 21 16.42Z"></path>
                          </svg>
                        </i>{" "}
                        {vendorDetails?.phone}
                      </a>
                    </div>
                    <div>
                      <a
                        href={"mailto:" + vendorDetails?.email}
                        class="text-dark"
                      >
                        <i class="me-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="18"
                            height="18"
                          >
                            <path d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"></path>
                          </svg>
                        </i>{" "}
                        {vendorDetails?.email}
                      </a>
                    </div> */}
                  </div>

                  {/* {(value || valuevendor) && (
                    <Link to="/accounts" style={{ width: "25px" }}>
                      <img
                        src="/img/user.png"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </Link>
                  )}
                  {valuevendor && (
                    <Link
                      to="/login"
                      style={{ width: "25px", paddingLift: "5px" }}
                    >
                      <img
                        src="/img/dashboard.png"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </Link>
                  )}
                  {!valuevendor && !value && (
                    <Link to="/login" style={{ width: "25px" }}>
                      <img
                        src="/img/user.png"
                        alt=""
                        style={{ width: "100%" }}
                      />
                    </Link>
                  )} */}
                </div>
                {/* <div className="ps-block__right">
                  <Link to="/login">Login</Link>
                  <Link to="/register">Register</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="ps-search--mobile">
          {/* <form className="ps-form--search-mobile" method="get">
            <div className="form-group--nest">
              <input
                className="form-control"
                type="text"
                placeholder="Search product"
                onChange={(e) => {
                  hendlesearchProduct(e);
                }}
                onKeyDown={(e) => {
                  hendlesearchProduct(e);
                }}
              />
              <button>
                <i className="icon-magnifier"></i>
              </button>
            </div>
          </form> */}
        </div>
      </header>
    </div>
  );
}

export default SellerHeader;
