// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
// import CoustomImg from "../../../Component/CoustomImg/CoustomImg";
// import TruncateText from "../../../Component/TruncateText/TruncateText";

function CategorySubSubPro() {
  const dispatch = useDispatch();
  const router = useNavigate();
  //   const { search, page, id } = router.query;
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");
  const search = searchParams.get("search");
  const get_product_list = useSelector((state) =>
    state?.allapi?.get_seller_product_list_public_by_cate
      ? state?.allapi?.get_seller_product_list_public_by_cate
      : {}
  );

  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  console.log(get_category_Filter);

  const pageno = page ? page : 1;

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];
  const listdataListing = get_product_list?.listingList?.docs
    ? get_product_list?.listingList?.docs
    : [];
  // get_product_list

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (value) => {
    // Check if the item is already selected
    if (selectedItems.includes(value)) {
      // If selected, remove it from the array
      setSelectedItems(selectedItems.filter((item) => item !== value));
    } else {
      // If not selected, add it to the array
      setSelectedItems([value]);
    }
  };

  useEffect(() => {
    // searchProduct

    if (search) {
      dispatch(
        allapiAction.getsellerProductListPublicebycategory({
          page: pageno,
          id: id,
          orderby: selectedItems,
        })
      );
    } else {
      dispatch(
        allapiAction.getsellerProductListPublicebycategory({
          page: pageno,
          id: id,
          orderby: selectedItems,
        })
      );
    }
    dispatch(allapiAction.getfiltercategory({}));
    return () => {};
  }, [router, selectedItems]);

  const henldetofilterbyCate = (e) => {
    if (search) {
      dispatch(
        allapiAction.userSearchProduct({ page: pageno, id: search, cateId: e })
      );
    }
  };
  const [childcateee, setchildcateee] = useState("");
  const hendletoviewchlidcate = (e) => {};

  const items = get_product_list?.totalDocs;
  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_product_list, items]);

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);

    router("/category-product/" + id + "?page=" + data);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="ps-deal-of-day shewBox">
          <div className="ps-container">
            <div class="ps-layout--shop mt-30">
              <div class="ps-layout__left">
                <aside class="widget widget_shop">
                  {/* <h4 class="widget-title">Categories</h4> */}
                  <div class="col-12 mobile_hide">
                    <details open class="sort">
                      <input
                        class="box2"
                        type="checkbox"
                        id="vehicle1"
                        // name="vehicle1"
                        value="Low to High"
                        checked={selectedItems.includes("Low to High")}
                        onChange={() => handleCheckboxChange("Low to High")}
                      />
                      <label for="vehicle1">
                        <span class="box3"> Price : Low to High</span>
                      </label>
                      <br />

                      <input
                        class="box2"
                        type="checkbox"
                        id="High to Low"
                        name="High to Low"
                        value="High to Low"
                        checked={selectedItems.includes("High to Low")}
                        onChange={() => handleCheckboxChange("High to Low")}
                      />
                      <label for="High to Low">
                        <span class="box3"> Price : High to Low</span>
                      </label>

                      <summary style={{ fontSize: "14px" }}>Sort</summary>
                    </details>

                    <br />
                  </div>
                </aside>
              </div>
              {/* ps-layout__right */}
              <div class="layout__right ">
                <div class="ps-block--shop-features">
                  <div class="ps-block__header">
                    <h3>Product</h3>
                    <div class="ps-block__navigation">
                      <a class="ps-carousel__prev" href="#recommended1">
                        <i class="icon-chevron-left"></i>
                      </a>
                      <a class="ps-carousel__next" href="#recommended1">
                        <i class="icon-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                  {listdata?.length == 0 && (
                    <div className="notfound">
                      <h2>Not Found</h2>
                    </div>
                  )}
                  <div className="row ps-section__content mt-10">
                    {" "}
                    {listdata &&
                      listdata?.map((data, i) => {
                        return (
                          <div className="col-6 col-sm-3" key={i}>
                            <div className="recentlostfound">
                              <div className="ps-product">
                                <div className="ps-product__thumbnail  ps-product__thumbnailms">
                                  <Link to={"/product/" + data?.pro_sulg}>
                                    <CoustomImg
                                      url={
                                        URL.API_BASE_URL + data?.featureImage
                                      }
                                      altkey={data?.title}
                                      w1={165}
                                      w2={300}
                                      h1={150}
                                      h2={250}
                                    />
                                  </Link>
                                </div>
                                <div className="ps-product__container">
                                  <div className="ps-product__content">
                                    <Link
                                      className="ps-product__title"
                                      to={"/product/" + data?.pro_sulg}
                                    >
                                      <TruncateText
                                        text={data?.title}
                                        maxLength={23}
                                      />
                                      {/* {data?.title} */}
                                    </Link>

                                    <p className="ps-product__price sale">
                                      ₹{data?.afterdiscountprice}{" "}
                                      <del>₹{data?.price} </del>
                                    </p>
                                    <Link
                                      className="ps-product__vendor"
                                      to={
                                        "/vendor-store/" + data?.sellerId?._id
                                      }
                                    >
                                      {data?.sellerId?.displayName}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-20">
          <nav
            className="d-flex justify-content-between"
            aria-label="Page navigation"
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              className="paging_simple_numbersc pagination"
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </nav>
        </div>
      </div>
    </div>
  );
}

export default CategorySubSubPro;
