import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Tooltip } from "antd";
import Box3 from "./Box3";
// import HeadingBack from "../../HeadingBack";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../HeadingBack";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";

function SettingCom() {
  const [show, setShow] = useState(false);
  const [showPro, setShowPro] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showOtpBoxsata, setShowOtpBoxdata] = useState("");
  const dispatch = useDispatch();
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );
  const get_vendor_profiles = useSelector((state) =>
    state.allapi.get_vendor_profiles ? state.allapi.get_vendor_profiles : {}
  );

  console.log(get_vendor_profiles);

  const [userBuss, setuserBuss] = useState({});
  const [images, setImages] = useState([]);
  const maxNumber = 1;

  useEffect(() => {
    if (get_vendor_profiles) {
      // URL.API_BASE_URL + get_vendor_profiles?.sellerProfile
      setImages([
        { data_url: URL.API_BASE_URL + get_vendor_profiles?.sellerProfile },
      ]);
      setuserBuss(get_vendor_profiles);
    }
  }, [get_vendor_profiles]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };

  useEffect(() => {
    setStartDate(new Date(get_setting?.withdrawalstarttime));
    setStartDate2(new Date(get_setting?.withdrawalendtime));
    // setuserBuss(get_setting);
  }, []);

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };
  useEffect(() => {
    // dispatch(adminAction.getInvoceDetails({}))
    dispatch(allapiAction.getprofilevendor({}));

    return () => {};
  }, []);

  const submitProduct = () => {
    try {
      if (!userBuss?.fullName) {
        toast.error("please enter full name");
      } else if (!userBuss?.displayName) {
        toast.error("please enter business name");
      } /* else if (!userBuss?.withdrwalcommission) {
        toast.error("please enter withdrwal commission");
      } else if (!userBuss?.maxmumwithdrwal) {
        toast.error("please enter maxmum withdrwal");
      } else if (!userBuss?.minimumwithdrwal) {
        toast.error("please enter minimum withdrwal");
      } else if (!userBuss?.minimumrecharge) {
        toast.error("please enter minimum recharge");
      } else if (!userBuss?.maxmumrecharge) {
        toast.error("please enter maxmum recharge");
      } */ else {
        // setLoading(true);
        // gstNo
        // companyName
        // email
        // address

        // data.append('gstNo', userBuss?.gstNo)
        // data.append('companyName', userBuss?.companyName)
        // data.append('email', userBuss?.email)
        // data.append('address', userBuss?.address)
        // data.append('fb', userBuss?.fb)
        // data.append('insta', userBuss?.insta)
        // data.append('twitter', userBuss?.twitter)
        // data.append('youtube', userBuss?.youtube)
        // data.append('number', userBuss?.number)
        // data.append('pinterst', userBuss?.pinterst)

        // data.append('invoiceLogo', images[0]?.file)

        // userBuss.withdrawalstarttime = startDate;
        // userBuss.withdrawalendtime = startDate2;

        // console.log(startDate);
        // console.log(startDate2);

        dispatch(allapiAction.updateBussProfileByvendor(userBuss));
        // setShow(false);
        // setShowOtpBox(true)
        setShowOtpBox(false);
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };
  //   updateBussLogovendor
  //   const submitChangeVerify2 = () => {
  //     if (!showOtpBoxsata) {
  //       toast.error('please enter otp')
  //     } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
  //       toast.error('otp invalid')
  //     } else {
  //       dispatch(
  //         allapiAction.verifyWhatsapp({
  //           whatsappNumber: userBuss?.whatsappNumber,
  //           otp: showOtpBoxsata,
  //         })
  //       )
  //       setShowOtpBox(false)
  //       setShow(false)
  //     }
  //   }

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Shop"}
            // subHeading={`Total ${get_transaction_list?.totalDocs || 0} Transaction`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Update">
            <span className="jop_btn" onClick={handleShow}>
              Update
            </span>
          </Tooltip>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  name="fullName"
                  className="form-control"
                  placeholder="Enter full Name"
                  type="text"
                  value={userBuss?.fullName}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Business Name</label>
                <input
                  name="displayName"
                  className="form-control"
                  placeholder="Enter business Name"
                  type="text"
                  value={userBuss?.displayName}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              {/* <div className="mb-3">
                <label className="form-label">game commision</label>
                <input
                  name="gamecommision"
                  className="form-control"
                  placeholder="Enter game commision"
                  type="number"
                  value={userBuss?.gamecommision}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrwal commission</label>
                <input
                  name="withdrwalcommission"
                  className="form-control"
                  placeholder="Enter withdrwal commission"
                  type="number"
                  value={userBuss?.withdrwalcommission}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">minimum withdrawal</label>
                <input
                  name="minimumwithdrwal"
                  className="form-control"
                  placeholder="Enter  minimum withdrawal"
                  type="number"
                  value={userBuss?.minimumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum withdrawal</label>
                <input
                  name="maxmumwithdrwal"
                  className="form-control"
                  placeholder="Enter  maxmum withdrawal"
                  type="number"
                  value={userBuss?.maxmumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Minimum recharge</label>
                <input
                  name="minimumrecharge"
                  className="form-control"
                  placeholder="Enter minimum recharge"
                  type="number"
                  value={userBuss?.minimumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum recharge</label>
                <input
                  name="maxmumrecharge"
                  className="form-control"
                  placeholder="Enter maxmum recharge"
                  type="number"
                  value={userBuss?.maxmumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Vpa</label>
                <input
                  name="vpa"
                  className="form-control"
                  placeholder="Enter vpa"
                  type="text"
                  value={userBuss?.vpa}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">name</label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={userBuss?.name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">merchant Code</label>
                <input
                  name="merchantCode"
                  className="form-control"
                  placeholder="Enter  merchant Code"
                  type="text"
                  value={userBuss?.merchantCode}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div> */}
            </div>
            {/* ) : ( */}
            {/* <div className='card-body'>
                

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    value={showOtpBoxsata}
                    onChange={(e) => {
                      setShowOtpBoxdata(e.target.value)
                    }}
                  />
                </div>
              </div> */}
            {/* )} */}
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <div className="mt-30">
        <Box3 />
      </div>
    </div>
  );
}

export default SettingCom;
