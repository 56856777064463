import React, { useEffect, useState } from "react";
// import { allapiAction } from "@/Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { allapiAction } from "../../Redux/common/action";
import { Link, useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
import HomeSlider from "../Home/HomeSlider";
import { AsyncPaginate } from "react-select-async-paginate";
// import Link from "next/link";
function JobPortalClintNew() {
  // const get_home_all_pro = useSelector((state) =>
  //     state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  // );
  const navigate = useNavigate();
  const get_job_portal_all = useSelector((state) =>
    state?.allapi?.get_job_portal_all ? state?.allapi?.get_job_portal_all : {}
  );
  const get_job_category_portal_all = useSelector((state) =>
    state?.allapi?.get_job_category_portal_all
      ? state?.allapi?.get_job_category_portal_all
      : {}
  );

  console.log(get_job_category_portal_all);

  const [tokenuser, settokenuser] = useState("");
  const [tokenvendor, settokenverndorr] = useState("");

  const [userselete, setuserselete] = useState(null);
  const [userseletecate, setuserseletecate] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const tokenvn = localStorage.getItem("access_token_vendor");
    settokenuser(token);
    settokenverndorr(tokenvn);
    dispatch(allapiAction.getsliderList({ page: "home" }));
    dispatch(allapiAction.getjoballpageList({ page: 1, totaldoc: 8 }));
    dispatch(allapiAction.getjobCategoryallpageList({ page: 1, totaldoc: 50 }));
  }, []);

  const totalItems = get_job_category_portal_all?.docs;

  const get_home_all_pro = [
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
  ];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 4
          ? 4
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 2
                ? 2
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settingsr = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 4
          ? 4
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 2
                ? 2
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const loadOptionsPalyer = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareaforproductDis?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.District + ", " + data?.State;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const loadOptionscategory = async (search, loadedOptions, pages) => {
    console.log(loadedOptions);
    console.log(pages);

    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/searchareacategorysearch?key=${search}&page=${pages?.page}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.name;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: pages?.page + 1,
      },
    };
  };
  const [searchkey, setsearchkey] = useState("");
  const hensletosearch = (e) => {
    e.preventDefault();
    // if (searchtype == "listing") {
    navigate(`/all-job`, {
      state: {
        searchkey: searchkey,
        location: userselete,
        category: userseletecate,
      },
    });
    // } else if (searchtype == "lostandfound") {
    //   router(`/products?search=${searchdata}`);
    // } else {
    //   router(`/products?search=${searchdata}`);
    // }
  };

  var settingsL = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 4
          ? 4
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 2
                ? 2
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div class="">
      <div class="neww bgnew">
        <div class="container-fluid">
          <div class="row mt-4">
            <div class="col-md-4 col-4 mb-2">
              <button class="btn btn-outline-success my_button" type="button">
                Main button
              </button>
            </div>
            <div class="col-md-4 col-4 mb-2">
              <button class="btn btn-outline-primary my_button" type="button">
                Main button
              </button>
            </div>
            <div class="col-md-4 col-4 mb-2">
              <button class="btn btn-outline-danger my_button" type="button">
                Main button
              </button>
            </div>
          </div>

          <div className="hometest">
            <HomeSlider />
          </div>

          {/* <!-- serch --> */}
          <div class="container">
            <div>
              <div class="search-box  ">
                <div class="col-6 col-md-3 p-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search Keywords..."
                    onChange={(e) => {
                      setsearchkey(e.target.value);
                    }}
                  />
                </div>
                <div class="col-6 col-md-3 p-0">
                  <AsyncPaginate
                    value={userseletecate}
                    className="liceninputv2"
                    // placeholder="location"
                    placeholder="Category"
                    loadOptions={loadOptionscategory}
                    onChange={setuserseletecate}
                    additional={{
                      page: 1,
                    }}
                  />
                  {/* <select class="form-control">
                    <option selected>Category</option>
                    <option value="1">Category 1</option>
                    <option value="2">Category 2</option>
                    <option value="3">Category 3</option>
                  </select> */}
                </div>
                <div class="col-12 col-md-3 p-0 jmt-5">
                  {/* <select class="form-control">
                  <option selected>Location</option>
                  <option value="1">Location 1</option>
                  <option value="2">Location 2</option>
                  <option value="3">Location 3</option>
                </select> */}
                  <AsyncPaginate
                    value={userselete}
                    className="liceninputv2"
                    // placeholder="location"
                    placeholder="Location"
                    loadOptions={loadOptionsPalyer}
                    onChange={setuserselete}
                    additional={{
                      page: 1,
                    }}
                  />
                </div>

                <div class="col-12 col-md-3 p-0 jmt-5">
                  <button
                    class="btn btn-success w-100"
                    onClick={(e) => hensletosearch(e)}
                  >
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- serch end --> */}

          {/* <div class="page-title mt-1">
            <div class="container-fluid">
              <div class="page-caption text-center ">
                <h1>
                  <br />
                  Job In Grid
                </h1>
                <p>
                  <a href="#" title="Home">
                    Home
                  </a>{" "}
                  <i class="ti-angle-double-right"></i> Job Layout One
                </p>
              </div>
            </div>
          </div> */}

          <div class="row  jbhead">
            <div class="col-12 text-center">
              <h1>Job Categories</h1>
              {/* <p>
                Lorem Ipsum is simply dummy text printing and type setting
                industry Lorem Ipsum been industry standard dummy text ever
                since when unknown printer took a galley.
              </p> */}
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {totalItems?.length > 0 && (
                <Slider {...settings}>
                  {totalItems?.map((data, i) => {
                    return (
                      <div
                        className="row"
                        onClick={() => {
                          navigate("/category-job/" + data?.slug);
                        }}
                      >
                        <div className="col-12">
                          <div className="row m-2 hhtt">
                            <div className="col-3"></div>
                            <div className="col-6 text-center">
                              {/* <div
                            className="mt-3 grr fa fa-address-card"
                            style={{ fontSize: "50px" }}
                          ></div> */}

                              <div class="utf_category_icon">
                                {" "}
                                <img
                                  src={URL?.API_BASE_URL + data?.image}
                                  alt=""
                                />{" "}
                              </div>
                            </div>
                            <div className="col-3"></div>
                            <div className="col-12 mt-2 text-center">
                              <h4 className="htg">{data?.name}</h4>
                            </div>
                            <div className="col-12 p-0 text-center text-muted">
                              <p className="bg-light text-center border">
                                {data?.count ? data?.count : 0} Jobs
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
              {/* <div className="row">
                <div className="col-md-3 col-sm-6 col-6"></div>

                <div className="col-md-3 col-sm-6 col-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="row m-2 hhtt">
                        <div className="col-3"></div>
                        <div className="col-6 text-center">
                          <div
                            className="mt-3 grr fa fa-address-card"
                            style={{ fontSize: "50px" }}
                          ></div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-12 mt-2 text-center">
                          <h4 className="htg">Accounting & Consulting</h4>
                        </div>
                        <div className="col-12 p-0 text-center text-muted">
                          <p className="bg-light text-center border">
                            300 Jobs
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="row m-2 hhtt">
                        <div className="col-3"></div>
                        <div className="col-6 text-center">
                          <div
                            className="mt-3 grr fa fa-address-card"
                            style={{ fontSize: "50px" }}
                          ></div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-12 mt-2 text-center">
                          <h4 className="htg">Accounting & Consulting</h4>
                        </div>
                        <div className="col-12 p-0 text-center text-muted">
                          <p className="bg-light text-center border">
                            300 Jobs
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-6 col-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="row m-2 hhtt">
                        <div className="col-3"></div>
                        <div className="col-6 text-center">
                          <div
                            className="mt-3 grr fa fa-address-card"
                            style={{ fontSize: "50px" }}
                          ></div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-12 mt-2 text-center">
                          <h4 className="htg">Accounting & Consulting</h4>
                        </div>
                        <div className="col-12 p-0 text-center text-muted">
                          <p className="bg-light text-center border">
                            300 Jobs
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-center mt-30">
              <h1>RECENT JOBS</h1>
            </div>
          </div>
          {/* 
          <div className="container mb-15">
            <div class="row ltjob">
              <div class="col-md-9 col-sm-6 col-6">
                <h3 class="jobb">Latest Jobs</h3>
              </div>
              <div class="col-md-3 col-sm-6 col-6 view-btn">
                <Link
                  to="#"
                  class="btn theme-btn btn-m width-170 btn-success view-btn"
                >
                  View All
                </Link>
              </div>
            </div>
          </div> */}
          <div class="row">
            <div class="col-12">
              {/* <Slider {...settingsr}>
                {get_job_portal_all?.docs &&
                  get_job_portal_all?.docs?.map((data, i) => {
                    return (
                      <div class="">
                        <div class="m-5">
                          <div class="row m-1 ggg">
                            <div class="col-md-6 col-12 p-3">
                              <p class="bg-light text-center border htt ">
                                {data?.job_type}
                              </p>
                            </div>
                            <div class="col-md-6 col-12 p-3">
                               
                            </div>
                            <div class="col-3"></div>
                            <div class="col-md-6 col-6 text-center">
                              <div class=" hhh p-1 gyy jobImg">
                                <img
                                  src={
                                    URL?.API_BASE_URL +
                                    data?.vendor_id?.sellerProfile
                                  }
                                  class="img-fluid jbimg rounded-circle"
                                  // width="110"
                                  // height={"110"}
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center ">
                              <h4 class="htg">{data?.job_title}</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">{data?.company_name}</p>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">{data?.district}</p>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">
                                {data?.salary_from}-{data?.salary_to}
                              </p>
                            </div>
                            <div class="col-12 text-center">
                              

                              <button
                                type="button"
                                class="btn but btn-outline-success"
                                onClick={() =>
                                  navigate("/job-details/" + data?.slug)
                                }
                              >
                                View Details
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider> */}
              <Slider {...settingsL}>
                {get_job_portal_all?.docs &&
                  get_job_portal_all?.docs?.map((data, i) => {
                    return (
                      <div class="joblistcard">
                        <div class="m-5">
                          <div class="card py-30">
                            <p class="bg-light text-center border htt ">
                              {data?.job_type}
                            </p>
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="d-flex align-items-center">
                                <div class="me-4">
                                  <img
                                    src={
                                      URL?.API_BASE_URL +
                                      data?.vendor_id?.sellerProfile
                                    }
                                    class="card-img"
                                    alt="..."
                                  />
                                </div>
                                <div class="">
                                  <div class="card-body p-0">
                                    <Link
                                      to={"/job-details/" + data?.slug}
                                      class="text-secondary primary-link-hover"
                                      title="software developers"
                                    >
                                      <h5 class="card-title fs-18 mb-0 d-inline-block">
                                        {data?.job_title}
                                      </h5>
                                    </Link>
                                    {/* <p>{data?.company_name}</p> */}
                                  </div>
                                </div>
                              </div>
                              <div class="icon position-relative pe-0">
                                <div class="col-md-1 col-sm-1 col-8 bookmark-icon justify-content-end position-relative pe-0 float-end d-flex text-gray">
                                  <svg
                                    class="svg-inline--fa fa-bookmark"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="far"
                                    data-icon="bookmark"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M336 0h-288C21.49 0 0 21.49 0 48v431.9c0 24.7 26.79 40.08 48.12 27.64L192 423.6l143.9 83.93C357.2 519.1 384 504.6 384 479.9V48C384 21.49 362.5 0 336 0zM336 452L192 368l-144 84V54C48 50.63 50.63 48 53.1 48h276C333.4 48 336 50.63 336 54V452z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div class="card-desc d-flex flex-column justify-content-between h-100 mt-4">
                              <div class="desc">
                                <div class="d-flex mb-1">
                                  <div class="me-3 w-20">
                                    <img
                                      src="https://infyjobs.infyom.com/img_template/briefcase.svg"
                                      class="w-100"
                                    />
                                  </div>
                                  <p class="fs-14 text-gray mb-0">
                                    {data?.company_name}
                                  </p>
                                </div>
                                <div class="d-flex mb-2">
                                  <div class="me-3 w-20">
                                    <img
                                      src=" https://infyjobs.infyom.com/img_template/location.svg "
                                      class="w-100"
                                    />
                                  </div>

                                  <p class="fs-14 text-gray mb-0">
                                    {data?.city}, {data?.district}
                                  </p>
                                </div>
                              </div>
                              <div class="desc d-flex mt-5">
                                <button
                                  type="button"
                                  class="btn but btn-outline-success"
                                  onClick={() =>
                                    navigate("/job-details/" + data?.slug)
                                  }
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              {/* <div class="row  m">
                <div class="col-md-3 col-sm-6 col-6 ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt ">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center ">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-6 ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt ">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 col-6  ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt ">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-6  ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div class="row align-items-center mt-30">
            <div class="col-9  text-center">
              <h1>LATEST JOBS</h1>
            </div>
            <div class="col-3 text-md-right mt-4 mt-md-0">
              <button
                class="btn btn-primary btn-animated"
                onClick={() => {
                  navigate("/all-job");
                }}
              >
                View All
              </button>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              {/* <Slider {...settingsL}>
                {get_job_portal_all?.docs &&
                  get_job_portal_all?.docs?.map((data, i) => {
                    return (
                      <div class="">
                        <div class="m-5">
                          <div class="row m-1 ggg">
                            <div class="col-md-6 col-12 p-3">
                              <p class="bg-light text-center border htt ">
                                {data?.job_type}
                              </p>
                            </div>
                            <div class="col-md-6 col-12 p-3">
                            
                            </div>
                            <div class="col-3"></div>
                            <div class="col-md-6 col-6 text-center">
                              <div class=" hhh p-1 gyy jobImg">
                                <img
                                  src={
                                    URL?.API_BASE_URL +
                                    data?.vendor_id?.sellerProfile
                                  }
                                  class="img-fluid jbimg rounded-circle"
                                  // width="110"
                                  // height={"110"}
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center ">
                              <h4 class="htg">{data?.job_title}</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">{data?.company_name}</p>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">{data?.district}</p>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p class="htgg">
                                {data?.salary_from}-{data?.salary_to}
                              </p>
                            </div>
                            <div class="col-12 text-center">
                               
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                                onClick={() =>
                                  navigate("/job-details/" + data?.slug)
                                }
                              >
                                View Details
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider> */}
              <Slider {...settingsL}>
                {get_job_portal_all?.docs &&
                  get_job_portal_all?.docs?.map((data, i) => {
                    return (
                      <div class="joblistcard">
                        <div class="m-5">
                          <div class="card py-30">
                            <p class="bg-light text-center border htt ">
                              {data?.job_type}
                            </p>
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="d-flex align-items-center">
                                <div class="me-4">
                                  <img
                                    src={
                                      URL?.API_BASE_URL +
                                      data?.vendor_id?.sellerProfile
                                    }
                                    class="card-img"
                                    alt="..."
                                  />
                                </div>
                                <div class="">
                                  <div class="card-body p-0">
                                    <Link
                                      to={"/job-details/" + data?.slug}
                                      class="text-secondary primary-link-hover"
                                      title="software developers"
                                    >
                                      <h5 class="card-title fs-18 mb-0 d-inline-block">
                                        {data?.job_title}
                                      </h5>
                                    </Link>
                                    {/* <p>{data?.company_name}</p> */}
                                  </div>
                                </div>
                              </div>
                              <div class="icon position-relative pe-0">
                                <div class="col-md-1 col-sm-1 col-8 bookmark-icon justify-content-end position-relative pe-0 float-end d-flex text-gray">
                                  <svg
                                    class="svg-inline--fa fa-bookmark"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="far"
                                    data-icon="bookmark"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M336 0h-288C21.49 0 0 21.49 0 48v431.9c0 24.7 26.79 40.08 48.12 27.64L192 423.6l143.9 83.93C357.2 519.1 384 504.6 384 479.9V48C384 21.49 362.5 0 336 0zM336 452L192 368l-144 84V54C48 50.63 50.63 48 53.1 48h276C333.4 48 336 50.63 336 54V452z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                            <div class="card-desc d-flex flex-column justify-content-between h-100 mt-4">
                              <div class="desc">
                                <div class="d-flex mb-1">
                                  <div class="me-3 w-20">
                                    <img
                                      src="https://infyjobs.infyom.com/img_template/briefcase.svg"
                                      class="w-100"
                                    />
                                  </div>
                                  <p class="fs-14 text-gray mb-0">
                                    {data?.company_name}
                                  </p>
                                </div>
                                <div class="d-flex mb-2">
                                  <div class="me-3 w-20">
                                    <img
                                      src=" https://infyjobs.infyom.com/img_template/location.svg "
                                      class="w-100"
                                    />
                                  </div>

                                  <p class="fs-14 text-gray mb-0">
                                    {data?.city}, {data?.district}
                                  </p>
                                </div>
                              </div>
                              <div class="desc d-flex mt-5">
                                <button
                                  type="button"
                                  class="btn but btn-outline-success"
                                  onClick={() =>
                                    navigate("/job-details/" + data?.slug)
                                  }
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
              {/* <div class="row  m-">
                <div class="col-md-3 col-sm-6 col-6 ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt ">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center ">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-6 ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt ">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-6  ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt ">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-6  ">
                  <div class="row">
                    <div class="col-12">
                      <div class="row m-1 ggg">
                        <div class="col-6 p-3">
                          <p class="bg-light text-center border htt">
                            FULL TIME
                          </p>
                        </div>
                        <div class="col-6 p-3">
                          <button
                            type="button"
                            class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart hed"
                          ></button>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-6 text-center">
                          <div class=" hhh p-1 gyy">
                            <img
                              src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                              class="img-fluid  rounded-circle"
                              width="110"
                            />
                          </div>
                        </div>
                        <div class="col-3"></div>
                        <div class="col-12 mt-2 text-center">
                          <h4 class="htg">Product Redesign</h4>
                        </div>
                        <div class="col-12 p-0 text-center text-muted">
                          <p class="htgg">2708 Scenic Way, IL 62373</p>
                        </div>
                        <div class="col-12 text-center">
                          <button
                            type="button"
                            class="btn but btn-outline-success"
                          >
                            APPLY NOW
                          </button>
                        </div>
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <table class="job-table">
            <thead>
              <tr>
                <th>Job Name</th>
                <th>Company</th>
                <th>Location</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {get_job_portal_all?.docs &&
                get_job_portal_all?.docs?.map((data, i) => {
                  return (
                    <tr>
                      <td>{data?.job_title}</td>
                      <td>{data?.company_name}</td>
                      <td>{data?.city}</td>
                      {/* view button lgana hai  */}

                      <td>
                        <button
                          onClick={() => navigate("/job-details/" + data?.slug)}
                          class="view-btn"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  );
                })}

              {/* <tr>
                <td>Backend Engineer</td>
                <td>XYZ Solutions</td>
                <td>San Francisco</td>
                <td>
                  <button class="view-btn">View All</button>
                </td>
              </tr>
              <tr>
                <td>UI/UX Designer</td>
                <td>PQR Designs</td>
                <td>London</td>
                <td>
                  <button class="view-btn">View All</button>
                </td>
              </tr>
              <tr>
                <td>Data Scientist</td>
                <td>123 Analytics</td>
                <td>Berlin</td>
                <td>
                  <button class="view-btn">View All</button>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default JobPortalClintNew;
