import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { useNavigate } from "react-router-dom";

function Weddinglisting() {
  // MerriageList
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const get_marriage_list = useSelector((state) =>
    state?.allapi?.get_marriage_list ? state?.allapi?.get_marriage_list : {}
  );
  console.log(get_marriage_list);

  const docslist = get_marriage_list?.docs ? get_marriage_list?.docs : [];
  useEffect(() => {
    dispatch(allapiAction?.MerriageList({ page: 1 }));
    return () => {};
  }, []);

  return (
    <div class="weddinglisting">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 hhh text-center">
            <h1 class="mt-5 pt-5">Active Members</h1>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div class="container mt-4">
        <div class="row">
          <div class="col-md-3">
            <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <p class="mt-2 m-0 p-0">Age From</p>
              <input
                type="number"
                class="form-control"
                placeholder="Age From"
              />
              <p class="mt-2 m-0 p-0">To</p>
              <input type="number" class="form-control" placeholder="Age To" />
              <p class="mt-2 m-0 p-0">Member ID</p>
              <input type="text" class="form-control" placeholder="Member ID" />
              <p class="mt-2 p-0 m-0">Gender</p>
              <select class="form-control">
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              <p class="mt-2 p-0 m-0">Marital Status</p>
              <select class="form-control">
                <option value="">Select Marital Status</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
              </select>
              <p class="mt-2 p-0 m-0">Select Religion</p>
              <select class="form-control">
                <option value="">Select Religion</option>
                <option value="religion1">Religion1</option>
                <option value="religion2">Religion2</option>
              </select>
              <p class="mt-2 p-0 m-0">Select Caste</p>
              <select class="form-control">
                <option value="">Select Caste</option>
                <option value="caste1">Caste1</option>
                <option value="caste2">Caste2</option>
              </select>
              <p class="mt-2 p-0 m-0">Mother Tongue</p>
              <select class="form-control">
                <option value="">Mother Tongue</option>
                <option value="tongue1">Tongue1</option>
                <option value="tongue2">Tongue2</option>
              </select>
              <p class="mt-2 p-0 m-0">Select Country</p>
              <select class="form-control">
                <option value="">Select Country</option>
                <option value="country1">Country1</option>
                <option value="country2">Country2</option>
              </select>
              <p class="mt-2 p-0 m-0">Select City</p>
              <select class="form-control">
                <option value="">Select City</option>
                <option value="city1">City1</option>
                <option value="city2">City2</option>
              </select>
              <p class="mt-2 m-0 p-0">Max Height (In Feet)</p>
              <input
                type="number"
                class="form-control"
                placeholder="Max Height"
              />
              <p class="mt-2 m-0 p-0">Min Height (In Feet)</p>
              <input
                type="number"
                class="form-control"
                placeholder="Min Height"
              />

              <button
                type="button"
                class="btn btn-info btn-lg btn-block mt-3 btn-custom"
              >
                Search
              </button>
            </div>
          </div>

          <div class="col-md-9">
            {docslist &&
              docslist?.map((data, i) => {
                let dob = new Date(data?.dob);
                let today = new Date();
                let age = Math.floor(
                  (today - dob) / (365.25 * 24 * 60 * 60 * 1000)
                );
                return (
                  <div class="border shadow-lg p-3 mb-5 bg-white rounded">
                    <div class="row">
                      <div class="col-sm-3 text-center">
                        <img
                          src={URL?.API_BASE_URL + data?.image}
                          class="profile-img img-fluid"
                        />
                      </div>

                      <div class="col-sm-9">
                        <div class="row">
                          <div class="col-md-7">
                            <h5>{data?.firstName + " " + data?.lastName}</h5>
                            {/* <p>
                              Member ID:{" "}
                              <span style={{ color: "red" }}>AM16635935</span>
                            </p> */}
                            <p class="text-muted m-0 p-0 mt-2">
                              Age: {age} Years
                            </p>
                            <p class="text-muted m-0 p-0">
                              Religion: {data?.religion}
                            </p>
                            <p class="text-muted m-0 p-0">
                              Location: {data?.city}
                            </p>
                          </div>

                          <div class="col-md-5 text-right">
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm btn-custom"
                              disabled
                            >
                              Premium
                            </button>
                            <br />
                            <br />
                            <p class="text-muted m-0 p-0">
                              Height: {data?.height} Feet
                            </p>
                            <p class="text-muted m-0 p-0">
                              Caste: {data?.caste}
                            </p>
                            <p class="text-muted m-0 p-0">
                              Marital Status:{data?.maritalStatus}
                            </p>
                          </div>
                        </div>

                        <div class="row mt-4">
                          <div class="col-12">
                            <p>
                              <span
                                onClick={() => {
                                  navigate("/wedding/profile/" + data?.slug);
                                }}
                              >
                                Profile
                              </span>{" "}
                              | Make Interest | Shortlist | Ignore | Report |
                              Message
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-sm-3 text-center">
                  <img
                    src="https://cdn4.sharechat.com/weddinggirlphoto_2bf4fdca_1590761190317_cmprsd_40.jpg?tenant=sc&referrer=tag-service&f=rsd_40.jpg"
                    class="profile-img img-fluid"
                  />
                </div>

                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-md-7">
                      <h5>Amelia Emma</h5>
                      <p>
                        Member ID:{" "}
                        <span style={{ color: "red" }}>AM16635935</span>
                      </p>
                      <p class="text-muted m-0 p-0 mt-2">Age: 27 Years</p>
                      <p class="text-muted m-0 p-0">Religion: Muslim</p>
                      <p class="text-muted m-0 p-0">Location: Swaziland</p>
                    </div>

                    <div class="col-md-5 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-custom"
                        disabled
                      >
                        Premium
                      </button>
                      <br />
                      <br />
                      <p class="text-muted m-0 p-0">Height: 6 Feet</p>
                      <p class="text-muted m-0 p-0">Caste: Sunni</p>
                      <p class="text-muted m-0 p-0">
                        Marital Status: Never Married
                      </p>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12">
                      <p>
                        Profile | Make Interest | Shortlist | Ignore | Report |
                        Message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-sm-3 text-center">
                  <img
                    src="https://cdn4.sharechat.com/weddinggirlphoto_2bf4fdca_1590761190317_cmprsd_40.jpg?tenant=sc&referrer=tag-service&f=rsd_40.jpg"
                    class="profile-img img-fluid"
                  />
                </div>

                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-md-7">
                      <h5>Amelia Emma</h5>
                      <p>
                        Member ID:{" "}
                        <span style={{ color: "red" }}>AM16635935</span>
                      </p>
                      <p class="text-muted m-0 p-0 mt-2">Age: 27 Years</p>
                      <p class="text-muted m-0 p-0">Religion: Muslim</p>
                      <p class="text-muted m-0 p-0">Location: Swaziland</p>
                    </div>

                    <div class="col-md-5 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-custom"
                        disabled
                      >
                        Premium
                      </button>
                      <br />
                      <br />
                      <p class="text-muted m-0 p-0">Height: 6 Feet</p>
                      <p class="text-muted m-0 p-0">Caste: Sunni</p>
                      <p class="text-muted m-0 p-0">
                        Marital Status: Never Married
                      </p>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12">
                      <p>
                        Profile | Make Interest | Shortlist | Ignore | Report |
                        Message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border shadow-lg p-3 mb-5 bg-white rounded">
              <div class="row">
                <div class="col-sm-3 text-center">
                  <img
                    src="https://cdn4.sharechat.com/weddinggirlphoto_2bf4fdca_1590761190317_cmprsd_40.jpg?tenant=sc&referrer=tag-service&f=rsd_40.jpg"
                    class="profile-img img-fluid"
                  />
                </div>

                <div class="col-sm-9">
                  <div class="row">
                    <div class="col-md-7">
                      <h5>Amelia Emma</h5>
                      <p>
                        Member ID:{" "}
                        <span style={{ color: "red" }}>AM16635935</span>
                      </p>
                      <p class="text-muted m-0 p-0 mt-2">Age: 27 Years</p>
                      <p class="text-muted m-0 p-0">Religion: Muslim</p>
                      <p class="text-muted m-0 p-0">Location: Swaziland</p>
                    </div>

                    <div class="col-md-5 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-custom"
                        disabled
                      >
                        Premium
                      </button>
                      <br />
                      <br />
                      <p class="text-muted m-0 p-0">Height: 6 Feet</p>
                      <p class="text-muted m-0 p-0">Caste: Sunni</p>
                      <p class="text-muted m-0 p-0">
                        Marital Status: Never Married
                      </p>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-12">
                      <p>
                        Profile | Make Interest | Shortlist | Ignore | Report |
                        Message
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Weddinglisting;
