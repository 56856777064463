// import { URL } from "@/Redux/common/url";
import React from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { URL } from "../../Redux/common/url";

function CategorySlider({ homedata }) {
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...ad].reverse();

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div className="ps-home-banner ps-home-banner--1">
        <div className="ps-container">
          <div className="ps-section__left">
            {slider && (
              <Slider {...settings}>
                {slider &&
                  slider?.map((data, i) => {
                    return (
                      <div className="ps-banner bg--cover cateslider" key={i}>
                        <a className="ps-banner__overlay" href="#">
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt={"slider" + i}
                              loading="lazy"
                          />
                        </a>
                      </div>
                    );
                  })}
              </Slider>
            )}
          </div>
          {/* <div className="ps-section__right row">
            <div className="col-sm-12 col-6 adsboxcus">
              {ad && (
                <Slider {...settings}>
                  {ad &&
                    ad?.map((data, i) => {
                      return (
                        <div key={i}>
                          <a className="ps-collection" href="#">
                            <img src={URL?.API_BASE_URL + data?.image} alt="" />
                          </a>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </div>
            <div className="col-sm-12 col-6 adsboxcus">
              {ads2 && (
                <Slider {...settings}>
                  {ads2 &&
                    ads2?.map((data, i) => {
                      return (
                        <div key={i}>
                          <a className="ps-collection" href="#">
                            <img src={URL?.API_BASE_URL + data?.image} alt="" />
                          </a>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default CategorySlider;
