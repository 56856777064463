import React, { useEffect, useState } from "react";
import { allapiAction } from "../../Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";

function JobViewDetails2() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  // getjobDetailst
  const details = useSelector((state) =>
    state?.allapi?.grt_jobs_details ? state?.allapi?.grt_jobs_details : {}
  );
  const grt_jobs_details_releted = useSelector((state) =>
    state?.allapi?.grt_jobs_details_releted
      ? state?.allapi?.grt_jobs_details_releted
      : []
  );
  console.log(details);
  console.log(grt_jobs_details_releted);

  const [tokenuser, settokenuser] = useState("");
  const [tokenvendor, settokenverndorr] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const tokenvn = localStorage.getItem("access_token_vendor");
    settokenuser(token);
    settokenverndorr(tokenvn);
  }, []);
  useEffect(() => {
    dispatch(allapiAction.getjobDetailst({ slug: id }));
    dispatch(allapiAction.getjobreleted({ slug: id }));
  }, []);
  return (
    <div>
      <div class="monitoe bgnew">
        <div class="container-fluid mt-5">
          <div class="row">
            <div class="col-1"></div>

            <div class="col-md-6">
              <div className="lost_fount_form">
                <div class="row m-1">
                  <div class="col-12 border">
                    {details?.hideSalary == "0" && (
                      <div class="row mt-2">
                        <div class="col-12 ml-1 ">
                          <i class="fa fa-rupee">
                            &nbsp;&nbsp;₹{details?.salary_from} - ₹
                            {details?.salary_to} monthly*
                          </i>
                        </div>
                      </div>
                    )}

                    <div class="row mt-2">
                      <div class="col-6">
                        <div class="row">
                          <div class="col-sm-2">
                            <button class="btn btn-primary m-1">
                              <i
                                class="fa fa-hospital-o"
                                style={{ fontSize: "30px" }}
                              ></i>
                            </button>
                          </div>
                          <div class="col-sm-10">
                            <p>
                              <b>{details?.job_title}</b>
                              <br />
                              {details?.company_name}
                            </p>
                          </div>

                          <div class="col-12 ml-1 ">
                            <img
                              src="/img/jobicon/location.png"
                              alt=""
                              width={20}
                            />
                            &nbsp;&nbsp;{details?.city}
                          </div>
                        </div>

                        <p type="button" class=" ">
                          <img
                            src="/img/jobicon/office-building.png"
                            alt=""
                            width={20}
                          />
                          &nbsp;&nbsp;{details?.workinglocationtype}
                        </p>
                       
                      </div>
                      <div class="col-6">
                        <div class="row mt-2 mb-25">
                          <div class="col-sm-9 mt-1">
                            <button
                              type="button"
                              onClick={() => {
                                if (tokenuser) {
                                  navigate("/job-apply/" + id);
                                } else {
                                  navigate("/login");
                                }
                              }}
                              class="btn btn-success btn-lg btn-block fa fa-"
                            >
                              Apply Now
                            </button>
                          </div>
                          {/* <div class="col-sm-3 mt-1">
                            <button
                              type="button"
                              class="btn btn-secondary btn-lg btn-block fa fa-share"
                            >
                              &nbsp;share
                            </button>
                          </div> */}
                        </div>
                        <p type="button" class=" ">
                          <img
                            src="/img/jobicon/letter-f.png"
                            alt=""
                            width={20}
                          />
                          &nbsp;&nbsp;{details?.job_shift}
                        </p>
                        <p type="button" class=" ">
                          <img
                            src="/img/jobicon/portfolio.png"
                            alt=""
                            width={20}
                          />
                          &nbsp;&nbsp;{details?.job_experience}
                        </p>
                        <p type="button" class=" ">
                          <img src="/img/jobicon/eng.png" alt="" width={20} />
                          &nbsp;&nbsp;{details?.language}
                        </p>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </div>

              <div className="lost_fount_form p-2 mt-35 mb-35">
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>Job role</b>
                </h4>
                <div className="container">
                  <div className="row  ">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Work location</b>
                        </div>
                        <div className="col-6 jbboder">
                          {details?.workinglocationtype}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Employment type</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_type}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Role / Category</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_category}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Shift</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_shift}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>Job requirements</b>
                </h4>

                <div className="container">
                  <div className="row  ">
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Experience</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.job_experience}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Skills</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.anyskills}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Gender</b>
                        </div>
                        <div className="col-6 jbboder"> {details?.gender}</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row">
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Education</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.degree_level}
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Language</b>
                        </div>
                        <div className="col-6 jbboder">
                          {" "}
                          {details?.language}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>About Company</b>
                </h4>
                <div className="container">
                  <div className="row  ">
                    <div className="col-12 col-md-12">
                      <div className="row">
                        <div className="col-3 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Name</b>
                        </div>
                        <div className="col-9 jbboder">
                          {" "}
                          {details?.company_name}
                        </div>
                        <div className="col-3 jbboder">
                          {" "}
                          &nbsp;&nbsp;<b>Address</b>
                        </div>
                        <div className="col-9 jbboder">
                          {" "}
                          {details?.address}, {details?.city}, {details?.state}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h4>
                  {" "}
                  &nbsp;&nbsp;<b>Job Description</b>
                </h4>
                <div className="container">
                  <div className="row  ">
                    <div
                      class="col-12 jbboder"
                      dangerouslySetInnerHTML={{
                        __html: details?.job_discription,
                      }}
                    ></div>
                  </div>
                </div>
                {/* <hr /> */}
              </div>
              {/* <div class="row m-1 mt-2">
                <div class="col-12 border">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="mt-2">Job role</h5>
                     
                      <div class="row mt-2">
                        <div class="col-sm-6">
                          
                          <img
                            src="/img/jobicon/office-building.png"
                            alt=""
                            width={20}
                          />
                          <span class="">
                            &nbsp;&nbsp;<b>Work location</b>
                            <br />
                            {details?.workinglocationtype}
                          </span>
                        </div>
                        <div class="col-sm-6">
                          <img
                            src="/img/jobicon/categorization.png"
                            alt=""
                            width={20}
                          />
                          
                          <span class="">
                            &nbsp;&nbsp;<b>Role / Category</b>
                            <br />
                            {details?.job_category}
                          </span>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-sm-6">
                          
                          <img src="/img/jobicon/clock.png" alt="" width={20} />
                          <span class="">
                            &nbsp;&nbsp;<b>Employment type</b>
                            <br />
                            {details?.job_type}
                          </span>
                        </div>
                        <div class="col-sm-6">
                           
                          <img src="/img/jobicon/shift.png" alt="" width={20} />
                          <span class="">
                            &nbsp;&nbsp;<b>Shift</b>
                            <br />
                            {details?.job_shift}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-12">
                      <h5 class="mt-2">Job requirements</h5>
                      
                      <div class="row mt-2">
                        <div class="col-sm-6">
                         
                          <img
                            src="/img/jobicon/portfolio.png"
                            alt=""
                            width={20}
                          />
                          <span class="">
                            &nbsp;&nbsp;<b>Experience</b>
                            <br />
                            {details?.job_experience}
                          </span>
                        </div>
                        <div class="col-sm-6">
                          
                          <img
                            src="/img/jobicon/graduation.png"
                            alt=""
                            width={20}
                          />
                          <span class="">
                            &nbsp;&nbsp;<b>Education</b>
                            <br />
                            {details?.degree_level}
                          </span>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-sm-6">
                          
                          <img
                            src="/img/jobicon/office-building.png"
                            alt=""
                            width={20}
                          />
                          <span class="">
                            &nbsp;&nbsp;<b>Skills</b>
                            <br />
                            {details?.anyskills}
                          </span>
                        </div>
                        <div class="col-sm-6">
                         
                          <img src="/img/jobicon/eng.png" alt="" width={20} />
                          <span class="">
                            &nbsp;&nbsp;<b>Language</b>
                            <br />
                            {details?.language}
                          </span>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-sm-6">
                          
                          <img src="/img/jobicon/app.png" alt="" width={20} />
                          <span class="">
                            &nbsp;&nbsp;<b>Gender</b>
                            <br />
                            {details?.gender}
                          </span>
                        </div>
                        
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-12">
                      <h5>About company</h5>
                      
                      <img
                        src="/img/jobicon/office-building.png"
                        alt=""
                        width={20}
                      />
                      <span class="">
                        &nbsp;&nbsp;<b>Name</b>
                        <br />
                        {details?.company_name}
                      </span>
                    </div>
                    <div class="col-12">
                     
                      <img
                        src="/img/jobicon/office-building.png"
                        alt=""
                        width={20}
                      />
                      <span class="">
                        &nbsp;&nbsp;<b>Address</b>
                        <br />
                        {details?.address}, {details?.city}, {details?.state}
                      </span>
                    </div>
                  </div>

                  <hr />

                  <div class="row">
                    <div class="col-12">
                      <h5 class="mt-2">Job Description</h5>
                      
                      <div
                        class="col-sm-6"
                        dangerouslySetInnerHTML={{
                          __html: details?.job_discription,
                        }}
                      ></div>
                    </div>
                  </div>

                  
                </div>
              </div> */}
            </div>

            <div class="col-md-4">
              <div class="row m-1">
                <div class="col-12 border">
                  <p class="mt-2">
                    <b>Similar jobs</b>
                  </p>
                  {grt_jobs_details_releted &&
                    grt_jobs_details_releted.map((data) => {
                      return (
                        <div class="similorJobBox">
                          <div class="row">
                            <div class="col-2">
                              {/* <img
                                src={URL?.API_BASE_URL + data?.company_logo}
                                class="img-fluid"
                                width="100"
                              /> */}
                              <i
                                class="fa fa-hospital-o"
                                style={{ fontSize: "30px" }}
                              ></i>
                            </div>
                            <div class="col-8">
                              <p>
                                <b>{data?.job_title}</b>
                                <br />
                                {data?.job_category}
                              </p>
                            </div>
                            <div class="col-2">
                              {/* <button type="button" class="btn btn-lg">></button> */}
                            </div>
                          </div>
                          <img
                            src="/img/jobicon/location.png"
                            alt=""
                            width={20}
                          />
                          {/* <i class="fa fa-map">
                     
                      </i> */}
                          &nbsp;&nbsp;{details?.city}
                          <br />
                          {!data?.hideSalary && (
                            <i class="fa fa-rupee ml-1">
                              &nbsp;&nbsp;₹{data?.salary_from} - ₹
                              {data?.salary_to} monthly*
                            </i>
                          )}
                          <br />
                          <div class="row">
                            <div class="col-12 mt-2">
                              <button
                                type="button"
                                class="btn p-0 mx-2 btn-light"
                              >
                                <img
                                  src="/img/jobicon/portfolio.png"
                                  alt=""
                                  width={20}
                                />
                                {/* <i class="fa fa-rupee text-muted">
                        </i> */}
                                &nbsp;&nbsp;{data?.job_experience}
                              </button>
                              <button
                                type="button"
                                class="btn p-0 mx-2 btn-light"
                              >
                                <img
                                  src="/img/jobicon/letter-f.png"
                                  alt=""
                                  width={20}
                                />
                                {/* <i class="fa fa-rupee text-muted">
                        </i> */}
                                &nbsp;&nbsp;{data?.job_shift}
                              </button>
                              <button
                                type="button"
                                class="btn p-0 mx-2 btn-light"
                              >
                                <img
                                  src="/img/jobicon/office-building.png"
                                  alt=""
                                  width={20}
                                />{" "}
                                &nbsp;&nbsp;{data?.job_shift}
                              </button>
                            </div>
                          </div>
                          {/* <hr /> */}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div class="col-2"></div>
          </div>
          {/* <section class="padd-top-80 padd-bot-60">
            <div class="container">
        
              <div class="row">
                <div class="col-md-8">
                  <div class="detail-wrapper">
                    <div class="detail-wrapper-body">
                      <div class="row">
                        <div class="col-md-4 text-center user_profile_img">
                          {" "}
                          <img
                            src="assets/img/company_logo_1.png"
                            class="width-100"
                            alt=""
                          />
                          <h4 class="meg-0">{details?.job_title}</h4>
                          <span>
                            {details?.address}, {details?.district}
                          </span>
                          <div class="text-center">
                            {tokenvendor ? (
                              <></>
                            ) : (
                              <button
                                type="button"
                                class="btn but btn-outline-success"
                                onClick={() => {
                                  if (tokenuser) {
                                    navigate("/job-apply/" + id);
                                  } else {
                                    navigate("/login");
                                  }
                                }}
                              >
                                APPLY NOW
                              </button>
                            )}
                            
                          </div>
                        </div>
                        <div class="col-md-8 user_job_detail">
                          <div class="col-sm-12 mrg-bot-10">
                            {" "}
                            <i class="ti-credit-card padd-r-10"></i>
                            {details?.salary_from} To {details?.salary_to}{" "}
                          </div>
                          <div class="col-sm-12 mrg-bot-10">
                            {" "}
                            <i class="ti-mobile padd-r-10"></i>
                            {details?.vendor_id?.phone}{" "}
                          </div>
                          <div class="col-sm-12 mrg-bot-10">
                            {" "}
                            <i class="ti-email padd-r-10"></i>
                            {details?.vendor_id?.email}{" "}
                          </div>
                          <div class="col-sm-12 mrg-bot-10">
                            {" "}
                            <i class="ti-calendar padd-r-10"></i>
                            <span class="full-type">
                              {details?.job_type}
                            </span>{" "}
                          </div>
                          <div class="col-sm-12 mrg-bot-10">
                            {" "}
                            <i class="ti-user padd-r-10"></i>
                            
                          </div>
                          <div class="col-sm-12 mrg-bot-10">
                            {" "}
                            <i class="ti-shield padd-r-10"></i>
                            {details?.job_experience}{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="detail-wrapper">
                    <div class="detail-wrapper-header">
                      <h4>Job Description</h4>
                    </div>
                    <div class="detail-wrapper-body">
                      <p>
                        "On the other hand, we denounce with righteous
                        indignation and dislike men who are so beguiled and
                        demoralized by the charms of pleasure of the moment, so
                        blinded by desire, that they cannot foresee the pain and
                        trouble that are bound to ensue.
                      </p>
                      <p>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupti quos dolores et quas molestias excepturi
                        sint occaecati cupiditate non provident, similique sunt
                        in culpa qui officia deserunt mollitia animi, id est
                        laborum et dolorum fuga.
                      </p>
                    </div>
                  </div>
                  <div class="detail-wrapper">
                    <div class="detail-wrapper-header">
                      <h4>Job Skill</h4>
                    </div>
                    <div class="detail-wrapper-body">
                      <ul class="detail-list">
                        <li>
                          Contrary to popular belief, Lorem Ipsum is not simply
                          random text{" "}
                        </li>
                        <li>
                          Latin professor at Hampden-Sydney College in Virginia{" "}
                        </li>
                        <li>
                          looked up one of the more obscure Latin words,
                          consectetur, from a Lorem Ipsum passage ideas{" "}
                        </li>
                        <li>
                          The standard chunk of Lorem Ipsum used since the 1500s
                          is reproduced{" "}
                        </li>
                        <li>
                          accompanied by English versions from the 1914
                          translation by H. Rackham{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  
                </div>

              
                <div class="col-md-4 col-sm-5">
                  <div class="sidebar">
               
                    <div class="widget-boxed">
                      <div class="widget-boxed-header">
                        <h4>
                            <b>{details?.company_name}</b>
                        </h4>
                      </div>
                      <div class="widget-boxed-body">
                        <div class="side-list no-border">
                          <ul>
                            <li>
                              <i class="ti-credit-card padd-r-10"></i>Package:
                              {details?.salary_from} To {details?.salary_to}{" "}
                            </li>
                           
                            <li>
                              <i class="ti-mobile padd-r-10"></i>
                              {details?.vendor_id?.phone}
                            </li>
                            <li>
                              <i class="ti-email padd-r-10"></i>
                              {details?.vendor_id?.email}
                            </li>
                            <li>
                              <i class="ti-pencil-alt padd-r-10"></i>
                              {details?.vendor_id?.phone}
                            </li>
                            <li>
                              <i class="ti-shield padd-r-10"></i>
                              {details?.job_experience}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  
                    <div class="widget-boxed">
                      <div class="widget-boxed-header">
                        <h4>
                          <i class="ti-time padd-r-10"></i>Opening Hours
                        </h4>
                      </div>
                      <div class="widget-boxed-body">
                        <div class="side-list">
                          <ul>
                            <li>
                              Monday <span>9 AM - 5 PM</span>
                            </li>
                            <li>
                              Tuesday <span>9 AM - 5 PM</span>
                            </li>
                            <li>
                              Wednesday <span>9 AM - 5 PM</span>
                            </li>
                            <li>
                              Thursday <span>9 AM - 5 PM</span>
                            </li>
                            <li>
                              Friday <span>9 AM - 5 PM</span>
                            </li>
                            <li>
                              Saturday <span>9 AM - 3 PM</span>
                            </li>
                            <li>
                              Sunday <span>Closed</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
 
                  </div>
                </div>
              </div>
            
            </div>
          </section> */}
        </div>
      </div>
    </div>
  );
}

export default JobViewDetails2;
