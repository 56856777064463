import Plus from "./../assets/svg/plus.svg";
import Visibility from "./../assets/svg/visibility.svg";
import VisibilityOff from "./../assets/svg/visibility-off.svg";
import Pencil from "./../assets/svg/pencil.svg";
import ArrowDown from "./../assets/svg/arrow-down.svg";
import CurveLineDown from "./../assets/svg/curve-line-down.svg";
import CurveLineUp from "./../assets/svg/curve-line-up.svg";
import Filter from "./../assets/svg/filter.svg";
import FilterWhite from "./../assets/svg/filter_white.svg";
import LeftArrow from "./../assets/svg/left-arrow.svg";
import Logout from "./../assets/svg/logout.svg";
import Pdf from "./../assets/svg/pdf.svg";
import Notification from "./../assets/svg/notification.svg";
import Search from "./../assets/svg/search.svg";
import User from "./../assets/svg/user.svg";
import Add from "./../assets/svg/add.svg";
import FAQIcon from "./../assets/svg/faq-icon.svg";
import LoginVisual from "./../assets/svg/login-visual.svg";
import Edit from "./../assets/svg/edit.svg";
import HrIcon from "./../assets/svg/hricon.svg";
import PermissionIcon from "./../assets/svg/permission.svg";
import seoManagementIcon from "./../assets/svg/seoManagement.svg";
import seoIcon from "./../assets/svg/SEO.svg";
import contacts from "./../assets/svg/contacts.svg";
import deletebtn from "./../assets/svg/deletebtn.svg";

const SvgIcon = {
  User,
  VisibilityOff,
  Plus,
  Visibility,
  Pencil,
  ArrowDown,
  CurveLineDown,
  CurveLineUp,
  Filter,
  FilterWhite,
  LeftArrow,
  Logout,
  Pdf,
  Notification,
  Search,
  Add,
  FAQIcon,
  LoginVisual,
  Edit,
  HrIcon,
  PermissionIcon,
  seoManagementIcon,
  seoIcon,
  contacts,
  deletebtn,
};
export default SvgIcon;
