import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import Deshboard1 from "../Seller/Deshboard/Deshnoard1";
import TopBar from "../Layout3/TopBar";
import Sidebar from "../Layout3/Sidebar";
// import Sidebar from "../../../Component/Layout3/Sidebar";
// import TopBar from "../../../Component/Layout3/TopBar";
// import Deshboard1 from "../../../Component/Seller/Deshboard/Deshnoard1";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;
function Account() {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  console.log("screens------------------------------", !screens.md);


  useEffect(() => {
    setCollapsed(!screens.md);
    return () => {};
  }, [screens]);
  return (
    <div>
      <Layout className="layout">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header className="site-layout-background1" style={{ padding: 0 }}>
            <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <Content className="site-layout-background p-10">
            <Deshboard1 />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default Account;
