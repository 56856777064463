// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import TruncateText from "../TruncateText/TruncateText";
import CoustomImg from "../CoustomImg/CoustomImg";
import SellerHeader from "../Layout/SellerHeader";
// import CoustomImg from "../../../../Component/CoustomImg/CoustomImg";
// import SellerHeader from "../../../../Component/Layout/SellerHeader";
// import TruncateText from "../../../../Component/TruncateText/TruncateText";

function VendorListing({ post }) {
  const dispatch = useDispatch();
  const router = useNavigate();
  const { id } = useParams();

  const locationv = useLocation();
  const searchParams = new URLSearchParams(locationv.search);
  const search = searchParams.get("search");
  const location = searchParams.get("location");
  const page = searchParams.get("page");
  const get_product_list = useSelector((state) =>
    state?.allapi?.user_product_search ? state?.allapi?.user_product_search : {}
  );
  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );

  console.log(get_category_Filter);

  const pageno = page ? page : 1;

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];
  const listdataListing = get_product_list?.listingList?.docs
    ? get_product_list?.listingList?.docs
    : [];
  // get_product_list
  const items = get_product_list?.totalDocs;
  const itemsPerPage = 18;
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(items && items / itemsPerPage));
  }, [itemOffset, itemsPerPage, get_product_list, items]);
  useEffect(() => {
    // searchProduct

    if (search) {
      dispatch(
        allapiAction.userSearchListingsUser({
          page: pageno,
          id: search,
          sellerid: id,
        })
      );
    } else {
      dispatch(
        allapiAction.userSearchListingsUser({ page: pageno, sellerid: id })
      );
    }
    // dispatch(allapiAction.getfiltercategory({}));
    return () => {};
  }, [locationv.search]);

  const henldetofilterbyCate = (e) => {
    if (search) {
      dispatch(
        allapiAction.userSearchListings({ page: pageno, id: search, cateId: e })
      );
    }
  };
  const [childcateee, setchildcateee] = useState("");
  const hendletoviewchlidcate = (e) => {};

  const handlePageClick = (event) => {
    const data = event?.selected + 1;
    // const newOffset = (event.selected * itemsPerPage) % items;
    // setItemOffset(newOffset);
    // setPage(data);
    // /vendor-store/listing/669dedace0a1580f384f7a03

    const srch = search ? search : "";
    router("/vendor-store/listing/" + id + "?search=" + srch + "&page=" + data);
    window.scrollTo(50, 0);
    // router("/listings?search=" + search + "&page=" + data);
    // dispatch(allapiAction.lostfoundlistpublic({ page: data }));
  };

  return (
    <div>
      <SellerHeader vendorDetails={post} />

      <div className="container-fluid">
        <div className="ps-deal-of-day shewBox">
          <div className="ps-container">
            <div class="ps-layout--shop mt-30">
              <div class="">
                <div class="ps-block--shop-features">
                  <div class="ps-block__header">
                    <h3>Listing</h3>
                    {/* <div class="ps-block__navigation">
                      <a class="ps-carousel__prev" href="#recommended1">
                        <i class="icon-chevron-left"></i>
                      </a>
                      <a class="ps-carousel__next" href="#recommended1">
                        <i class="icon-chevron-right"></i>
                      </a>
                    </div> */}
                  </div>
                  {listdata?.length == 0 && (
                    <div className="notfound">
                      <h2>Not Found</h2>
                    </div>
                  )}
                  <div className="row ps-section__content mt-10">
                    {" "}
                    {listdata &&
                      listdata?.map((data, i) => {
                        return (
                          <div className="col-6 col-sm-2" key={i}>
                            <div className="recentlostfound">
                              <div className="ps-product">
                                <div className="ps-product__thumbnail  ps-product__thumbnailms">
                                  <Link to={"/listing/" + data?.pro_sulg}>
                                    {/* <img
                          src={URL.API_BASE_URL + data?.featureImage}
                          alt=""
                        /> */}
                                    {/* 
                        <Image
                          className="default-img"
                          src={URL.API_BASE_URL + data?.featureImage}
                          alt={data?.title}
                          width={300}
                          height={250}
                        /> */}

                                    <CoustomImg
                                      url={
                                        URL.API_BASE_URL + data?.featureImage
                                      }
                                      altkey={data?.title}
                                      w1={165}
                                      w2={300}
                                      h1={150}
                                      h2={250}
                                    />
                                  </Link>
                                  {/* <div className="ps-product__badge">-16%</div>
              <ul className="ps-product__actions">
                <li>
                  <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add To Cart"
                  >
                    <i className="icon-bag2"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    data-placement="top"
                    title="Quick View"
                    data-toggle="modal"
                    data-target="#product-quickview"
                  >
                    <i className="icon-eye"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add to Whishlist"
                  >
                    <i className="icon-heart"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Compare"
                  >
                    <i className="icon-chart-bars"></i>
                  </a>
                </li>
              </ul> */}
                                </div>
                                <div className="ps-product__container">
                                  <div className="ps-product__content">
                                    <Link
                                      className="ps-product__title"
                                      to={"/listing/" + data?.pro_sulg}
                                    >
                                      <TruncateText
                                        text={data?.title}
                                        maxLength={23}
                                      />
                                      {/* {data?.title} */}
                                    </Link>
                                    {/* 
                                  <p className="ps-product__price sale">
                                    ₹{data?.afterdiscountprice}{" "}
                                    <del>₹{data?.price} </del>
                                  </p> */}
                                    <Link
                                      className="ps-product__vendor"
                                      to={
                                        "/vendor-store/" + data?.sellerId?._id
                                      }
                                    >
                                      {data?.sellerId?.displayName}
                                    </Link>

                                    {/* <p>
                              <span>
                                <i>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="18"
                                    height="18"
                                  >
                                    <path
                                      d="M18.364 17.364L12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364ZM12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"
                                      fill="rgba(240,187,64,1)"
                                    ></path>
                                  </svg>
                                </i>
                              </span>
                              {data?.sellerId?.district},{data?.sellerId?.state}
                            </p> */}
                                  </div>
                                  {/* <div className="ps-product__content hover">
                        <a
                          className="ps-product__title"
                          href="product-default.html"
                        >
                          {data?.title}
                        </a>
                        <p className="ps-product__price sale">
                          ₹{data?.afterdiscountprice}{" "}
                          <del>₹{data?.price}</del>
                        </p>
                      </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-20">
          <nav
            className="d-flex justify-content-between"
            aria-label="Page navigation"
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              className="paging_simple_numbersc pagination"
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
            />
          </nav>
        </div>
      </div>
    </div>
  );
}

export default VendorListing;
