// import { allapiAction } from "@/Redux/common/action";
// import Link from "next/link";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeSlider from "../../Component/Home/HomeSlider";
import Video2 from "../../Component/Home/Video2";
import OurWork from "../../Component/OurWork/OurWork";
import Slider from "react-slick";
import { allapiAction } from "../../Redux/common/action";
import { Link } from "react-router-dom";

function Student() {
  const dispatch = useDispatch();
  const get_All_edu_List = useSelector((state) =>
    state?.allapi?.get_All_edu_List ? state?.allapi?.get_All_edu_List : []
  );

  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );
  // console.log(get_marques_List);
  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];

  useEffect(() => {
    dispatch(allapiAction.getLimiteducatListpub({ size: 10 }));
    dispatch(allapiAction.studentVideoPub());
    dispatch(allapiAction.getsliderList({ page: "student" }));
    dispatch(allapiAction.getMarqueesList2({ page: "student" }));
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, []);

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_All_edu_List?.length == 1
        ? 1
        : get_All_edu_List?.length > 6
          ? 6
          : get_All_edu_List?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_All_edu_List?.length == 1
              ? 1
              : get_All_edu_List?.length > 3
                ? 3
                : get_All_edu_List?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_All_edu_List?.length == 1
              ? 1
              : get_All_edu_List?.length > 2
                ? 2
                : get_All_edu_List?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_All_edu_List?.length == 1
              ? 1
              : get_All_edu_List?.length > 2
                ? 2
                : get_All_edu_List?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="p-5 hometest">
      <center>
        <div className="container-fluid p-2">
          <div className="row">
            <div className="col-md-3">
              <div className="row mt-2">
                <div className="col-6 pt-3">
                  <Link to={"/education"}>
                    <button className="btn-11">
                      <span>All Exam</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row mt-2 pr-3">
                <div className="col-12 pr-5"></div>
              </div>
            </div>
          </div>
        </div>
      </center>
      <HomeSlider />
      <div className="container-fluid">
        <div className="mark_bg mt-2">
          <div className="headertext">About</div>
          <div className="news">
            <marquee>
              {news &&
                news?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div>
        </div>
      </div>
      <div className="ps-product-list ps-clothings my-5 px-3">
        <div className="ps-section__header mb-2 border border-primary">
          <h3>Latest Exam</h3>
        </div>
        <Slider {...settings}>
          {get_All_edu_List &&
            get_All_edu_List?.map((data, i) => {
              return (
                <div key={data._id}>
                  <Link to={"/education-details/" + data?.slug}>
                    <div className="card h-100 w-100 shadow-lg rounded">
                      <div className="card-body exam">
                        <h4 className="p-4">{data?.keyword}</h4>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </Slider>
      </div>
      <Video2 />
      <div className="ps-product-list ps-clothings mt-5 px-3">
        <div className="ps-section__header my-5 border border-primary">
          <h3>Latest Notification</h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="mark_bg mt-2">
          <div className="headertext">Latest Ad</div>
          <div className="news">
            <marquee>
              {admq &&
                admq?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-20">
        <div className="mark_bg mt-2">
          <div className="headertext">Latest News</div>

          <div className="news">
            <marquee>
              {about &&
                about?.map((data, i) => {
                  return <span key={data._id}>{data?.title}</span>;
                })}
            </marquee>
          </div>
        </div>
      </div>
      <OurWork />
    </div>
  );
}

export default Student;
