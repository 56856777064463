// import { allapiAction } from "@/Redux/common/action";
// import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HomeSlider from "../Home/HomeSlider";
import OurWork from "../OurWork/OurWork";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
// import { URL } from "@/Redux/common/url";
function ConservatismComp() {
  const [value, setValue] = useState("");
  const [valuevendor, setValuevendor] = useState("");
  const dispatch = useDispatch();
  const get_marques_List = useSelector((state) =>
    state?.allapi?.get_marques_List ? state?.allapi?.get_marques_List : {}
  );
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const image = slider_list?.image ? slider_list?.image : [];
  // console.log(get_marques_List);
  const news = get_marques_List?.news ? get_marques_List?.news : [];
  const admq = get_marques_List?.ads ? get_marques_List?.ads : [];
  const about = get_marques_List?.about ? get_marques_List?.about : [];
  useEffect(() => {
    if (typeof window !== "undefined") {
      setValue(localStorage.getItem("access_token"));
      setValuevendor(localStorage.getItem("access_token_vendor"));
    } else {
      setValue("");
      setValuevendor("");
    }
  }, []);

  useEffect(() => {
    dispatch(allapiAction.getMarqueesList2({ page: "conservatism" }));
    dispatch(allapiAction.getsliderList({ page: "conservatism" }));
  }, []);
  const activedata = value || valuevendor ? true : false;
  return (
    <div className="hometest">
      <div className="marquee">
        <marquee behavior="scroll" direction="left" scrollamount="05">
          {news &&
            news?.map((data, i) => {
              return <>{data?.title}</>;
            })}
        </marquee>
      </div>

      <HomeSlider />

      <div className="container-fluid mt-3">
        <div className="row">
          {image &&
            image?.map((data, i) => {
              return (
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 mb-2">
                  <div className="card">
                    <div className="p-4">
                      <img
                        src={URL?.API_BASE_URL + data?.image}
                        className="card-img-top"
                        alt="..."
                      />
                    </div>
                    <div className="card-body p-0">
                      {/* <p className="card-title text-center social"> Name</p> */}
                      <h4 className="btn btn-primary d-flex justify-content-center m-0 py-2 text-white">
                        <Link
                          to={activedata ? "/generate-certificate" : "/login"}
                        >
                          Submit
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <OurWork />
    </div>
  );
}

export default ConservatismComp;
